import {MENU_ITEM_CONSTANTS, MenuItemActions} from "../constants/menu_item.constants";

export function ditsMenuItem(state = {}, action: {
    type: MenuItemActions,
    param: object[]
}) {
    switch (action.type) {
        case MENU_ITEM_CONSTANTS.RETRIEVE_MENU_ITEM_COOKING_INGREDIENTS:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
}
