import * as React from 'react'
import Screen from '../screen'
import {CircularProgress} from "@material-ui/core";
import './index.scss'

interface ILoader{
    logo: string | null,
    message:string | null,
    image: string | null
}

const LoaderGif = (props:ILoader) => {

    return (
            <Screen className="screen-loder">
                <div className="screen-container-loader">
                    <div className="container">
                            <p className="message" dangerouslySetInnerHTML={{__html: props.message ?? ""}} />
                            <div className="container-img-loader">
                                <div className="bkg-img"></div>
                                <img className="loader-img"src={props.image ?? ""}/>
                            </div>
                    </div>
                </div>
            </Screen>
    );
}

const LoaderSpinner = () => {

    return (
        <Screen className="screen-loder overlay">
            <div className="screen-container-loader">
                <CircularProgress variant="indeterminate" className="spinner"/>
            </div>
        </Screen>
    );
}


export {LoaderGif, LoaderSpinner};