import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import { formatPrice } from '../../helpers/formatHelper';
import { LocalizerHelper } from '../../helpers/localizerHelper';
import { AddOn, Option } from '../../models/menu';
import {Remove as IconRemove} from "@material-ui/icons"

import './index.scss';

interface OptionComponentProps {
    option: Option;
    checkAddOnInsert: any;
    addOption: any;
    removeOption: any;
    quantity: number;
}

const OptionComponent: React.FC<OptionComponentProps> = ({option, checkAddOnInsert, addOption, removeOption, quantity}) => {

    //const [quantity, setQuantity] = React.useState<number>(0);

    const _plus = () => {
        const max: number = option.max_size ?? 1;
        switch(true){
            case (max <= 0):
            case (max > 0 && quantity < max):
                if(checkAddOnInsert()){
                    //setQuantity(quantity + 1);
                    addOption(option);
                }else{
                    //do nothing, max size options already reached
                }
                break;
            case (max > 0 && quantity >= max):
                //do nothing
                break;
        }
    }

    const _minus = (event:React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if(quantity > 0){
            //setQuantity(quantity - 1);
            removeOption(option);
        }
    }

    const _buildHeader = () => {
        const name: string|undefined|null = LocalizerHelper.localized(option, 'name')?.trim() ?? "";
        const title: string|undefined|null = LocalizerHelper.localized(option, 'title')?.trim() ?? "";
        if(quantity > 0){
            if(!!title && title.length > 0){
                return <span style={{color:"#FE3436"}}>{title}</span>
            }else{
                return <span style={{color:"#FE3436"}}>{name}</span>
            }
            /*if(!!name && name.length > 0){
                return <span style={{color:"#FE3436"}}>{name}</span>
            }else{
                return <span style={{color:"#FE3436"}}>{title}</span>
            }*/
        }
        if(!!title && title.length > 0){
            return title;
        }else{
            return name;
        }
        /*if(!!name && name.length > 0){
            return name;
        }else{
            return title;
        }*/
    }

    const _buildSubtitle = () => {
        let subtitle = ` ${LocalizerHelper.localized('max')} (${option.max_size ?? 1})`
        return subtitle;
    }

    const _buildPrice = () => {
        switch(true){
            case (quantity <= 0):
                return formatPrice(option.price)
            case (quantity == 1):
                return <span style={{color:"#FE3436"}}>{formatPrice(option.price)}</span>
            case (quantity > 1):
                return <span style={{color:"#FE3436"}}>{quantity} x {formatPrice(option.price)}</span>
        }
    }

    return (
        <div className="add-on-option">
            <div className="row" onClick={_plus}>
                <div className="col-7">
                    <Typography variant="h5" gutterBottom className="option-header" align="left">{_buildHeader()}</Typography>
                    <Typography variant="subtitle1" gutterBottom className="option-sub-header" align="left">{_buildSubtitle()}</Typography>
                </div>
                
                <div className="col-1 vertically-center" style={{padding:"0"}}>
                { (quantity > 0) ?
                    <Button 
                        className={`qty-action-button`}
                        disabled={ quantity<=0 }
                        onClick={(event) => {
                            _minus(event)
                        }}>
                            <IconRemove id="minus-button" className="qty-action-button-icon" htmlColor={"#ffffff"} />
                    </Button> : null
                }
                </div> 
                <div className="col-4 text-right vertically-center">
                    {_buildPrice()}
                </div>
                <hr />
            </div>
            <hr />
        </div>
    );
}

export default OptionComponent;