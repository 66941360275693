import {LocalizerHelper} from "../../helpers/localizerHelper";
import {Button} from "@material-ui/core";
import * as React from "react";
import firebase from "firebase";
import {Close as IconRemove} from "@material-ui/icons";
import {formatDitsMenuItemPrice} from "../../helpers/ditsMenuItemHelper";
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import { AddOn, Option } from '../../models/menu';


const MenuItemRow = ({groupItemKey, menuItem, qty, canDelete, onDeleteItem}: { groupItemKey: string, menuItem: DocumentSnapshot, qty: number, canDelete: boolean, onDeleteItem: (key: string) => any }) => {
    const hasAddOns = (menuItem?.data()?.add_ons ?? [])?.length > 0;
    const addOns: AddOn[] = (menuItem?.data()?.add_ons ?? []);
    console.log('menuItem?.data()?.add_ons', menuItem?.data()?.add_ons);
    const hasMinus = (menuItem?.data()?.minus ?? [])?.length > 0;
    const hasNotes = !!menuItem?.data()?.note;

    const toShowAddOns = hasAddOns ? (menuItem?.data()?.add_ons ?? []).map((item: any) => LocalizerHelper.localized(item, 'title') ?? LocalizerHelper.localized(item, 'name')).join(',') : null;
    const toShowMinus = hasMinus ? (menuItem?.data()?.minus ?? []).map((item: any) => LocalizerHelper.localized(item, 'title') ?? LocalizerHelper.localized(item, 'name')).join(',') : null;
    const toShowNotes = hasNotes ? menuItem?.data()?.note ?? '' : '';
    return (<div key={`menu-item-${menuItem.id}`}>
        <div className="row menu-item-row">
            <div className="col-2 text-left">
                <span className="menu-item-qty-label">x {qty}</span>
            </div>
            <div className="col-5 text-left">
                <span className="menu-item-name-label">{LocalizerHelper.localized(menuItem?.data(), 'name')}</span>
                {addOns.map((addOn: AddOn, index: number) => {
                    console.log('addOn', addOn);
                    return addOn.options.map((option: Option, ind: number) => {
                        console.log('option', option);
                        let label: string = '';
                        const name: string = LocalizerHelper.localized(option, 'name')?.trim() ?? "";
                        console.log('MenuItemRow name ', name);
                        const title: string= LocalizerHelper.localized(option, 'title')?.trim() ?? "";
                        console.log('MenuItemRow title ', title);
                        if(!!title && title.length > 0){
                            label = title;
                        }else{
                            label = name;
                        }
                        return (<span className="menu-item-addons-label">+ {label} </span>);
                    })
                })}
                {hasMinus ?
                    <span className="menu-item-minus-label">- {toShowMinus}</span> : null
                }
                {hasNotes ?
                    <span className="menu-item-notes-label">{toShowNotes}</span> : null
                }
            </div>
            <div className="col-3 text-right">
                <span className="menu-item-price-label">{formatDitsMenuItemPrice(menuItem, qty)}</span>
            </div>   
            <div className="col-2 text-left">
                {canDelete ?
                    <Button className='delete-action-button'
                            onClick={() => onDeleteItem(groupItemKey)}>
                        <IconRemove fontSize="small" htmlColor={"#ffffff"}/>
                    </Button> : null
                }
            </div>
        </div>
    </div>);
};


export default MenuItemRow;
