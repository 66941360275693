import {PAYMENT_CONSTANTS, PaymentActions} from "../constants/payment.constants";

export function payment(state = {}, action: {
    type: PaymentActions,
    params: object[]
}) {
    switch (action.type) {
        case PAYMENT_CONSTANTS.PAY_STRIPE:
            return {
                ...state,
                ...action,
                error: null
            }
        case PAYMENT_CONSTANTS.PAY_STRIPE_ERROR:
            return {
                ...state,
                ...action,
            }
        default:
            return state;
    }
}
