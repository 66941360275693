import {BILL_CONSTANTS, BillActions} from "../constants/bill.constants";

export function bill(state = {}, action: {
    type: BillActions,
    params: object[]
}) {
    switch (action.type) {
        case BILL_CONSTANTS.REQUEST_BILL:
        case BILL_CONSTANTS.UPDATE_BILL:
        case BILL_CONSTANTS.GET_BILL:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
}
