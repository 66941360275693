import * as React from 'react';
import firebase from "./configurations/firebase";
import {PropsWithChildren, useEffect} from "react";
import {connect,useSelector} from "react-redux";
import {Route, Router, Switch, Redirect, RouteProps} from "react-router";
import Restaurant from "./components/restaurant/Restaurant";
import history from "./helpers/history";
import {confirmOrder, getLastSession} from "./actions/session.actions";
import {getUserProfile, showLogin} from "./actions/auth.actions";
import Menu from "./components/menu/Menu";
import MenuQuid from "./components/menuquid/MenuQuid";
import DeliveryDetails from "./components/info_details/InfoDetails";
import Signin from "./components/signin/Signin";
import OrderRecap from "./components/recap/OrderRecap";
import Payment from "./components/payment/Payment";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import LocationServiceAvailable from "./components/location_service_available/LocationServiceAvailable";
import MenuChooserContainer from "./components/menu_chooser/MenuChooser";
import ThanksPage from "./components/thanks_page/ThanksPage";
import { BottomSheet } from './components/bottom_sheet/BottomSheet';
import Spinner from "./components/spinner/Spinner";
import WaitingOrderApprovement from "./components/waiting_order_approvement/WaitingOrderApprovement";
import './app.scss';
import {LocalizerHelper} from "./helpers/localizerHelper";
import {LoaderGif} from './components/loader'
import NotificationOffline from './components/offline'
import QuidScreen from './components/quid';
import NexiPaymentScreen from './components/payment/PaymentLoader';
import StripePaymentScreen from './components/payment/StripePaymentScreen';
import { TimeSlot } from './models';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

let unsubscribeAuthStateChange: firebase.Unsubscribe | null = null;


interface IProvateRouteProps extends RouteProps{
  restaurant:any  
} 

const  PrivateRoute: React.FC<IProvateRouteProps> = (props) => {

    const { component: Component, children, ...rest } = props;

    console.log("@@@@@@@@@@@@@@@@@@@@@@@@"); 
    console.log("@@@@ PrivateRoute @@@@");
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@"); 
    console.log(props);
    console.log(props.restaurant);

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    
    return (<Route  path={props.path}  exact={props.exact} component={props.component} render={props.render} />)
    
    /*
    return  props.restaurant ? 
                (<Route  path={props.path}  exact={props.exact} component={props.component} render={props.render} />) : 
                (<Redirect  to={`/menu/${lastLinkAlias}`}  />);
    */          
};



interface IChuzeatWebAppProps extends PropsWithChildren<any> {
    restaurantID:string,
    ditsID:string,
    restaurant: any,
    dits: DocumentSnapshot,
    openLogin:boolean
    confirmSessionOrder: (restaurantID: string,menuId:string, sessionID: string,paymentMethod:string,slotSelected:TimeSlot | undefined | null) => any,
    retrieveUserProfile: (user: firebase.User | null) => any
    retrieveLastSession: (userID: string) => any,
    showLogin: (show: boolean, restaurantID?: string, sessionID?: string) => any
}


const _ChuzeatWebApp = ({
    historyPass,
    restaurantID,
    ditsID,
    restaurant,
    openLogin,
    retrieveUserProfile,
    showLogin
}: IChuzeatWebAppProps) => {
    const [loading, setLoading] = React.useState(false);
    const [isSignedIn, setIsSignedIn] = React.useState(false);

    const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY ?? '');
    let signinTimeout:any = null;

    const _startListenForAuthState = () => {
        signinTimeout = setTimeout(() => {
            if (!isSignedIn) {
                _signInAnonymously();
            }
        }, 5000);
        unsubscribeAuthStateChange = firebase.auth().onAuthStateChanged(async (user) => {
            console.log("@@@@@@@@@");
            console.log("firebase.auth().onAuthStateChanged");
            console.log("@@@@@@@@@");
            console.log(user);
            const _isSignedIn = !!user;
            console.log("_isSignedIn ==> "+_isSignedIn);
            setIsSignedIn(_isSignedIn);
            if (_isSignedIn) {
                if (!!signinTimeout) {
                    clearTimeout(signinTimeout);
                }
                //await retrieveLastSession(user.uid);
                console.log("===> retrieveUserProfile");
                await retrieveUserProfile(user ?? null);
            }
        }, (error) => {
            console.log('@@@@@error on auth state change');
            console.log(error);
        });
    }

    const _signInAnonymously = async () => {
        try {
            console.log("@@@@ _signInAnonymously");
            setLoading(true);
            await firebase.auth().signInAnonymously();
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if(firebase.auth().currentUser){
            unsubscribeAuthStateChange = firebase.auth().onAuthStateChanged(async (user) => {
                console.log("@@@@@@@@@");
                console.log("firebase.auth().onAuthStateChanged");
                console.log("@@@@@@@@@");
                console.log(user);
                const _isSignedIn = !!user;
                console.log("_isSignedIn ==> "+_isSignedIn);
                setIsSignedIn(_isSignedIn);
                if (_isSignedIn) {
                    if (!!signinTimeout) {
                        clearTimeout(signinTimeout);
                    }
                    //await retrieveLastSession(user.uid);
                    console.log("===> retrieveUserProfile");
                    await retrieveUserProfile(user ?? null);
                }
            }, (error) => {
                console.log('@@@@@error on auth state change');
                console.log(error);
            });
        }else{
            _startListenForAuthState();
        }
        return () => {
            console.log("unmount");
            if (!!unsubscribeAuthStateChange)
                unsubscribeAuthStateChange();
        }
    }, []);
    /*

                            <Route path="*" component={() => { 
                                    window.location.href = 'https://www.chuzeat.com/chuzeat-app/'; 
                                    return null;
                            }}/>
                            */

    return (
        <div className="app">
            <div style={{height: '100%'}}>
                <Elements stripe={stripePromise}>
                    <Router history={history}>
                        <Switch>
                            <Route  path={'/payment/nexi'} exact render={() => <NexiPaymentScreen/>} />
                            <Route  path={'/payment/stripe'} exact render={() => <StripePaymentScreen/>} />
                            <PrivateRoute exact path='/quid/:quid' restaurant={restaurant} render={props => <QuidScreen {...props}/>}/>
                            <PrivateRoute exact path='/quid/:quid/:restaurant/menu/:menu' restaurant={restaurant} render={props => <MenuQuid {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/thanks' restaurant={restaurant} render={props => <ThanksPage {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/payment/:restaurant/:dits/:bill' restaurant={restaurant} render={props => <Payment {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/waiting-order-approval/:restaurant/:dits/:requestId' restaurant={restaurant} render={props => <WaitingOrderApprovement {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/confirm-order/:restaurant/:menu/:dits' restaurant={restaurant} render={props => <OrderRecap {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/signin/:restaurant/:dits' restaurant={restaurant} render={props => <Signin {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/order-recap/:restaurant/:menu/:dits' restaurant={restaurant} render={props => <OrderRecap {...props} isRecap={true}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/info-details/:mode' restaurant={restaurant} render={props => <DeliveryDetails {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/menu/:restaurant/:menu' restaurant={restaurant} render={props => <MenuQuid {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/menu-chooser' restaurant={restaurant} render={props => <MenuChooserContainer {...props}/>}/>
                            <PrivateRoute exact path='/menu/:link_alias/location-service-available/:restaurant/:dits' restaurant={restaurant} render={props => <LocationServiceAvailable {...props}/>}/>
                            <Route exact path='/menu/:link_alias' render={props => <Restaurant {...props}/>}/> 
                            <Route path='*' component={() => {
                                window.location.href = 'https://app.chuzeat.com/';
                                return null;
                            }}/>
                        </Switch>
                    </Router>
                    {openLogin ?
                        <BottomSheet  open={openLogin} showClose={true}  radius={30} onClose={() => showLogin(false)}>
                             <Signin routeHistory={history} restaurantID={restaurantID} ditsID={ditsID}/>
                        </BottomSheet> : null
                    }
                </Elements>
                {loading || !isSignedIn ? <LoaderGif  logo={null} image="/assets/loading_restaurant.gif" message={LocalizerHelper.localized('loading_msg_retrieve_services')} /> : null }
                <NotificationOffline/>
          </div>
    </div>);
}

//<PrivateRoute restaurant={null} />

const mapStateToProps = (state: any) => {
    return {
        restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
        dits: state.session.session ?? null,
        openLogin: state.auth.showLogin ?? false,
        restaurantID: state.auth.restaurantID ?? false,
        ditsID: state.auth.ditsID ?? false,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    retrieveLastSession: async (userID: string) => dispatch(await getLastSession(userID)),
    retrieveUserProfile: async (user: firebase.User) => dispatch(await getUserProfile(user)),
    confirmSessionOrder: async (restaurantID: string,menuId:string, sessionID: string,paymentMethod:string,slotSelected:TimeSlot | undefined | null) => dispatch(await confirmOrder(restaurantID,menuId, sessionID,paymentMethod,slotSelected)),
    showLogin: (show: boolean, restaurantID?: string, sessionID?: string) => dispatch(showLogin(show, restaurantID, sessionID))
});

const ChuzeatWebApp = connect(mapStateToProps, mapDispatchToProps)(_ChuzeatWebApp);

export default ChuzeatWebApp;
