import * as React from 'react';
import {connect,Provider} from "react-redux";
import { createStore } from 'redux';
import {getMenus} from "../../actions/menu.actions";
import firebase from "firebase";
import {getRestaurant} from "../../actions/restaurant.actions";
import {PropsWithChildren} from "react";
import {setLink, validateLink} from "../../actions/link.actions";
import {setMenu} from "../../actions/menu.actions";
import {LocalizationData, LocalizerHelper} from "../../helpers/localizerHelper";
import {Card, CardContent, Typography, Button} from "@material-ui/core";
import {useHistory} from "react-router";
import {createNewSession} from "../../actions/session.actions";
import rootReducers from '../../reducers';
import {DELIVERY_MODES} from "../../constants/data";
import RestaurantHeader from "./RestaurantHeader";
import Spinner from "../spinner/Spinner";
import ChuzeatFooter from "../footer/ChuzeatFooter";
import {LoaderGif} from '../loader';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {evaluateDitsMenuItemPrice} from "../../helpers/ditsMenuItemHelper";
import Screen from '../screen';
import './index.scss';
import {useIsMounted} from '../../hooks'

const useEffect = React.useEffect;


interface IRestaurantProps extends PropsWithChildren<any> {
    user: any | null,
    restaurant: any | null,
    menus?: DocumentSnapshot[],
    selectedMenu?: DocumentSnapshot | null,
    allMenuItems?: DocumentSnapshot[],
    retrieveRestaurant: (id: string) => any,
    retrieveMenus: (id: string) => any,
    setLink: (link: any) => any
}

const LinkChooser = ({links, onLinkSelected}: {
    links: any[],
    onLinkSelected: (link: any) => any
}) => (
    <div className="container">
        {links.map((item: any) => (<div className="row" key={item.id} >
            <div className="col-md-12">
                <Button className="accent-button full-width button" onClick={() => onLinkSelected(item)}>
                    {LocalizerHelper.localized(item, 'name')}
                </Button>
            </div>
        </div>))}
    </div>
);

const _Restaurant = ({
    user,
    match,
    links,
    linkSelected,
    restaurant,
    dits,
    menus = [],
    createNewDits,
    validateLink,
    retrieveMenus,
    hasFetchedMenus,
    linkAlias,
    linksFetched,
    setLink
}: IRestaurantProps) => {

    const history = useHistory();
    const [noMenusAvailables, setNoMenusAvailables] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const isMounted = useIsMounted()
    


    useEffect(()=>{
        setLink(null);
    },[]);

    useEffect(() => {
        console.log(`useEffect user [${user}]`);
        console.log(user);
        if(user == null || typeof user === 'undefined'){
            console.log("@@ nouser");
            return;
        }
    }, [user]);

    useEffect(() => {
        const linkAliasParams = match?.params?.link_alias;
        if (!!linkAliasParams) {
            //save alias on localstorage
            if(window.localStorage){
                window.localStorage.setItem('chuzeat_last_alias_link',linkAliasParams);
            }
            console.log("@@@@@start validate link");
            _validateLink(linkAliasParams);
        }
    },[]);

    useEffect(() => {
        LocalizationData.getInstance().defaultLanguage = restaurant?.data?.defaultLanguage || "en";
    },[restaurant]);

    useEffect(() => {
        console.log(`useEffect links`);
        console.log(links);
        console.log(linksFetched);
        if (!!links && links.length > 0) {
            setInitialLoading(false);
        }
        setInitialLoading(false);
    }, [links]);
    

    const _validateLink = (linkAlias: string) => {
        if (!!validateLink) {
            validateLink(linkAlias);
        }
    }

    const _createNewDitsIfNeeded = (link: any) => {
        console.log("@@@ _createNewDitsIfNeeded");
        setLoading(true);
        setLink(link);
        if (!!dits){
            console.log("dits exists");
            console.log(dits);
            console.log(link);
            var menus = link.menus || [];
            console.log(menus);
            if(!!menus && menus.length!==0){
                const isDelivery = (link.service_mode === DELIVERY_MODES.delivery);
                if(isDelivery){
                    setLoading(false);
                    const alias = linkAlias ?? lastLinkAlias;
                    const url = `/menu/${alias}/location-service-available/${restaurant.id}/${dits.id}`
                    history.push(url);
                }else{
                    if(menus.length == 1){
                        console.log("@@@@@ _goToMenu");
                        setMenu(menus[0]);
                        _goToMenu(menus[0]);
                    }else{
                        console.log("@@@@@ _goToMenuChooser");
                        _goToMenuChooser();
                    }
                }
            } 
        }else{
            console.log("dits not exists");
            const linkId = link.id;
            const restaurantId = restaurant.id;
            console.log("@@@ createNewDits");
            console.log(linkId);
            console.log(restaurantId);
            createNewDits(restaurantId,linkId);
        }
    }

    useEffect(() => {
        console.log("useeffect [dits]");
        console.log(dits);
        console.log(links);
        console.log(linkSelected);
        if (!!dits && !!links && !!linkSelected ) {
            _createNewDitsIfNeeded(linkSelected);
        }
    }, [dits]);
    //const _isDeliveryDits = () => (!!dits && dits.exists && dits.data()?.delivery_mode === DELIVERY_MODES.delivery);

    const _hasSession = () => !!dits;

    const _hasLinks = () => !!links && links.length > 0;

    const _hasMenus = () => !!menus && menus.length > 0;

    const _hasSingleMenus = () => _hasMenus() && menus.length === 1;

    const _goToLocationServiceAvailable = () => {
        setLoading(false);
        const alias = linkAlias ?? lastLinkAlias;
        const url = `/menu/${alias}/location-service-available/${restaurant.id}/${dits.id}`
        history.push(url);
    }

    const _goToMenuChooser = () => {
        setLoading(false);
        const alias = linkAlias ?? lastLinkAlias;
        console.log(alias);
        const url = `/menu/${alias}/menu-chooser`
        console.log(url);
        history.push(url);
    }

    const _goToMenu = (menu: any) => {
        setLoading(false);
        const alias = linkAlias ?? lastLinkAlias;
        const url = `/menu/${alias}/menu/${restaurant.id}/${menu.docId}`;
        console.log(url);
        history.push(url);
    }

    //<LoaderGif  logo={null} image="/assets/loading_services.gif" message={LocalizerHelper.localized('loading_msg_retrieve_services')} />
    
    const paintLoadingScreen = () => {
        return (
            <LoaderGif  logo={null} image="/assets/loading_menus.gif" message={LocalizerHelper.localized('loading_msg_retrieve_menus')} />
        );
    }

    const paintRestaurantScreen = () => {
        return (
            <div>
            <Screen className="screen restaurant-screen">
                <RestaurantHeader restaurant={restaurant} className='full-width' showBack={false} showCart={false}/>
                <div className="content_container with_footer">
                    {_hasSession() ? 
                        <div className='container'>
                            {noMenusAvailables ? <p>{LocalizerHelper.localized('no_dining_tables_availables')}</p> : null}
                        </div> :
                        _hasLinks() ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p className="user_questions">
                                            {LocalizerHelper.localized('link_chooser_header')}
                                        </p>
                                    </div>
                                </div>
                                <div className="container-buttons-action">
                                    <LinkChooser links={links} onLinkSelected={(link) => _createNewDitsIfNeeded(link)}/>
                                </div>    
                            </div> :
                            <div className='container'>
                                {linksFetched && (!links || links.length === 0) ?
                                    <p className="user_questions">{LocalizerHelper.localized('no_dining_tables_availables')}</p> : null
                                }
                            </div>
                    }
                </div>
                <ChuzeatFooter/>
            </Screen>
            {initialLoading ?  <LoaderGif  logo={null} image="/assets/loading_restaurant.gif" message={LocalizerHelper.localized('loading_msg_retrieve_services')} /> : null}
            {loading?
                paintLoadingScreen() : null
            }
            </div>
        );
    }

    return (
         paintRestaurantScreen()
    );
};

const mapStateToProps = (state: any) => ({
    user: state.auth.user ?? null,
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    links: state.link.links ?? [],
    linkAlias:state.link.linkAlias ?? null,
    linksFetched: state.link.linksFetched ?? false,
    linkSelected: state.link.selectedLink ?? null,
    menus: state.menu.menus ?? [],
    dits: state.session.session ?? null,
    hasFetchedMenus: state.menu.hasFetchedMenus ?? false
});

const mapDispatchToProps = (dispatch: (param: any) => any) => ({
    validateLink: async (linkRef: string) => dispatch(await validateLink(linkRef)),
    createNewDits: async (restaurantID: string, link: string) => dispatch(await createNewSession(restaurantID, link)),
    retrieveRestaurant: async (id: string) => dispatch(await getRestaurant(id)),
    retrieveMenus: async (id: string) => dispatch(await getMenus(id)),
    setLink: (link: any) => dispatch(setLink(link)),
    setMenu: (menu: any) => dispatch(setMenu(menu))
});


const Restaurant = connect(mapStateToProps, mapDispatchToProps)(_Restaurant);

export default Restaurant;
