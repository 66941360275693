import firebase from "firebase";
import {FirestoreHelper} from "../helpers/firestoreHelper";
import {DateTime} from "luxon";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {AVAILABILITY_CONSTANTS} from "../constants/availability.constants";
import * as cron from "cron-parser";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Timestamp = firebase.firestore.Timestamp;
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import DocumentData = firebase.firestore.DocumentData;

const _mapAvailability = (
    availability: Date,
    interval: number = 30,
    timezoneOffset: number,
    snapshot: QuerySnapshot<DocumentData>,
    maxRestaurantTakeAway: number,
    maxRestaurantDelivery: number
) => {
    const timeStart: Timestamp = Timestamp.fromDate(availability)
    const slots = snapshot.docs.find((item) => item.data()?.time_start?.toMillis() === timeStart?.toMillis());
    const currentTakeAwayCount = slots?.data()?.take_away_count ?? 0;
    const currentDeliveryCount = slots?.data()?.delivery_count ?? 0;
    const remainingTakeAwaySlots = maxRestaurantTakeAway - currentTakeAwayCount;
    const remainingDeliverySlots = maxRestaurantDelivery - currentDeliveryCount;
    const takeAwayPercentage = (currentTakeAwayCount * 100) / maxRestaurantTakeAway;
    const deliveryPercentage = (currentDeliveryCount * 100) / maxRestaurantDelivery;
    const mappedAvailability = {
        timeStart: slots?.data()?.time_start?.toDate() ?? availability,
        timeEnd: slots?.data()?.time_end?.toDate() ?? DateTime.fromJSDate(availability)
            .plus({
                minutes: interval,
            }).toJSDate(),
        offset: timezoneOffset,
        remainingTakeAwaySlots,
        remainingDeliverySlots,
        takeAwayPercentage,
        deliveryPercentage
    };
    return mappedAvailability;
}


const listenForAvailabilities = (dispatch: any) => (restaurant: any, menu: DocumentSnapshot) => {

    //console.log("@@@@@ listenAvailabilities");
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    const defaultMinOffset = restaurant?.data?.take_away_delivery_interval ?? 30;
    const daysToShowSlots = restaurant?.data?.days_show_slots_takeaway_delivery ?? 2;
    //console.log("@@@@ daysToShowSlots");
    //console.log(restaurant);
    //console.log(daysToShowSlots);
    const dateTimeStart = DateTime.local()
        .set({
            second: 0
        })
        .plus({
            //hour: timezoneOffset,
            minutes: defaultMinOffset
    }).toJSDate();
    const dateTimeEnd = DateTime.local()
        .set({
            second: 0
        })
        .plus({
            //hour: timezoneOffset,
            days: daysToShowSlots
    }).endOf('day').toJSDate();

    const timeStart = Timestamp.fromDate(dateTimeStart);
    const timeEnd = Timestamp.fromDate(dateTimeEnd);
    //console.log("@@@ timeStart");
    //console.log(timeStart);
    const takeAwaySlotsQuery = FirestoreHelper.getTakeAwaySlots(restaurant.id, timeStart, timeEnd);
    //console.log("@@@@@@@@ takeAwaySlotsQuery");
    //console.log(takeAwaySlotsQuery);
    return takeAwaySlotsQuery.onSnapshot(snapshot => {
        //console.log("@@@@@@@@ takeAwaySlotsQuery query response");
        //console.log(snapshot);
        if (!!snapshot) {
            //console.log("menu");
            //console.log(menu);
            //console.log("menu data");
            //console.log(menu?.data());
            const availabilities = menu?.data()?.take_away_delivery_availability;
            //console.log("@@@@@@@@ availabilities");
            //console.log(availabilities);
            const takeAwayInterval = restaurant?.data?.take_away_delivery_interval ?? menu?.data()?.take_away_delivery_interval ?? 60;
            //console.log("@@@@@@@@ takeAwayInterval");
            //console.log(takeAwayInterval);
            const evaluatedAvailabilities: any[] = [];
            const actualTime = new Date(Date.now() - 1000 * takeAwayInterval);
            //console.log("@@@@ actualTime");
            //console.log(actualTime);
            const options = {
                currentDate: actualTime,
                tz: 'Europe/Rome'
            };
            let interval = null;
            for (let availability of availabilities) {
                interval = cron.parseExpression(availability,options);
                //console.log("========================");
                //console.log(availability);
                //console.log("@@@@@@@@ interval");
                //console.log(interval);
                var fields = JSON.parse(JSON.stringify(interval.fields)); // Fields is immutable
                var dayOfWeek = fields.dayOfWeek ?? [];
                var index = dayOfWeek.indexOf(7);
                if(index > -1){
                    dayOfWeek.splice(index, 1);
                    if(dayOfWeek.indexOf(0) === -1){
                        dayOfWeek.unshift(0);
                    }
                }
                
                var modifiedInterval = cron.fieldsToExpression(fields);
                var cronString = modifiedInterval.stringify();
                interval = cron.parseExpression(cronString,options);
                //console.log("========================");
                //console.log("MODIFIY");
                //console.log(interval);
                let canForward = true;
                while (interval.hasNext() && canForward) {
                    //console.log("-----------------");
                    const dateTimeInterval = interval.next()?.toDate();
                    //console.log(dateTimeInterval);
                    canForward = dateTimeInterval.getTime() <= dateTimeEnd.getTime();
                    if (canForward && dateTimeInterval.getTime() > dateTimeStart.getTime() &&dateTimeInterval.getTime() >= actualTime.getTime()) {
                        //console.log("@@@ OK");
                        //console.log(dateTimeInterval);
                        //console.log(dateTimeStart);
                        evaluatedAvailabilities.push(dateTimeInterval);
                    }
                }
            }
            const maxRestaurantTakeAwayDeliverySlots = restaurant?.data?.max_take_away_delivery_slots;
            //console.log("@@@@@@@@ maxRestaurantTakeAwayDeliverySlots");
            //console.log(maxRestaurantTakeAwayDeliverySlots);
            const maxRestaurantTakeAway = maxRestaurantTakeAwayDeliverySlots?.max_take_away;
            //console.log("@@@@@@@@ maxRestaurantTakeAway");
            //console.log(maxRestaurantTakeAway);
            const maxRestaurantDelivery = maxRestaurantTakeAwayDeliverySlots?.max_delivery;
            //console.log("@@@@@@@@ maxRestaurantDelivery");
            //console.log(maxRestaurantDelivery);
            const sortedAvailabilities = Array.from(new Set(evaluatedAvailabilities)).sort((a, b) => {
                const result = a.getTime() - b.getTime();
                return result === 0 ? result : (result > 0) ? 1 : - 1
            });
            //console.log("@@@@@@@@ sortedAvailabilities");
            //console.log(sortedAvailabilities);
            const toReturnAvailabilities = sortedAvailabilities.map((availability) => _mapAvailability(availability, takeAwayInterval, timezoneOffset, snapshot, maxRestaurantTakeAway, maxRestaurantDelivery));
            //console.log("@@@@ toReturnAvailabilities");
            //console.log(toReturnAvailabilities);

            dispatch(buildActionResult(AVAILABILITY_CONSTANTS.UPDATE_AVAILABILITY_SLOTS, {
                availabilities: toReturnAvailabilities
            }));
        }
    });
}

export {
    listenForAvailabilities
}
