import * as React from "react";
import { Component, isValidElement, Children, createElement, useState, useEffect } from "react";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import './index.scss';

const inBrowser = typeof navigator !== "undefined";

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = (url:string, timeout:number) => {
  return new Promise(resolve => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);
    const xhr = new XMLHttpRequest();
    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open("HEAD", url);
    xhr.timeout = timeout;
    xhr.send();
  });
};

const defaultProps = {
  polling: true,
  wrapperType: "span"
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: "https://google.com/",
  timeout: 1000,
  interval: 5000,
  maxRetrieve: 3,
};

const NotificationOffline = () =>{

  const [offline,setOffline] = useState(false);
  let pingError = 0;
  let hasPingExecuting = false;
  let timerIdWait:any = null;
  

  useEffect(()=>{
      if (!defaultPollingConfig.enabled && navigator.onLine) {
        window.addEventListener("online", goWindowOnline);
        window.addEventListener("offline", goWindowOffline);
      }
      startPolling();
  },[]);

  const detectPing = () => {
    if(hasPingExecuting) return;
    hasPingExecuting = true;
    const { url, timeout } = defaultPollingConfig;
    ping(url, timeout ).then(online => {
      console.log(`ping online [${online}]`);
      hasPingExecuting = false;
      const checkOnLine = online || navigator.onLine;
      if(checkOnLine){
        console.log('online');
        pingError = 0;
        if(navigator.onLine){
          goWindowOnline();
        }else{
          goOnline();
        }
      }else{
        checkMaxPinError();
      }
    });
  }

  const startPolling = () => {
      stopPolling();
      timerIdWait = setTimeout(detectPing, defaultPollingConfig.interval);
  }

  const stopPolling = (reset = false) => {
    console.log(`stop pooling [${timerIdWait}]`);
    if(timerIdWait){
      clearTimeout(timerIdWait)
    }
    if(reset) pingError = 0;
  }


  const goWindowOnline = () => {
      setOffline(false);
      stopPolling(true);
  }

  const goWindowOffline = () => {
    console.log("goWindowOffliner");
    checkMaxPinError();
  }

  const goOnline = () => {
    setOffline(false);
    if(window.navigator.onLine){
      console.log("using browser navigator");
      stopPolling(true);
    }else{
      startPolling();
    }
  }

  const checkMaxPinError = () => {
    pingError = (pingError + 1);
    console.log(`ping Error [${pingError}]`)
    if(pingError >= defaultPollingConfig.maxRetrieve){
      pingError = 0;
      setOffline(true);
    }
    startPolling();
  }

  return (
      <div className={'popupOffline ' + (offline ? 'show' : '')}>
          <div className="container-message">
           <span className="message">{LocalizerHelper.localized('alert_msg_offline')}</span>   
          </div> 
      </div>
  )
}


export default NotificationOffline;

