import * as React from "react";
import {connect} from "react-redux";
import {
    addTimeSlotToSession,
    confirmOrder,
    deleteItemsFromSession,
    listenForSessionMenuItems,
    listenForSession,
    getSession,
    addPaymentMethodToSession
} from "../../actions/session.actions";
import {CircularProgress} from "@material-ui/core";
import {PropsWithChildren, useEffect} from "react";
import {checkIfNotEmpty, checkIfNotExists} from "../../helpers/validatorHelper";
import firebase from "firebase";
import {Typography} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import BottomSheetOrderRecap from "../bottom_order_recap/BottomOrderRecap";
import MenuItemRow from "../menu_item/MenuItemRow";
import {listenForBillLogs} from "../../actions/bill.actions";
import {useHistory} from "react-router";
import {evaluateDitsMenuItemPrice, groupSameItems} from "../../helpers/ditsMenuItemHelper";
import {IAvailability, TimeSlot} from "../../models";
import AvailabilitySelectableBox from "../availability_box/AvailabilitySelectableBox";
import {getSlot, isTimeSlotSelected} from "../../helpers/timeSlotHelper";
import {LoaderGif} from "../loader";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {DEFAULT_ALLOWED_PAYMENT_METHODS, DELIVERY_MODES, PAYMENT_METHODS, PAYMENT_TYPES, RESPONSE_CODES} from "../../constants/data";
import {listenForAvailabilities} from "../../actions/availability.actions";
import AskDeleteItems from "../ask_delete_items/AskDeleteItems";
import {showLogin} from "../../actions/auth.actions";
import {formatPrice} from "../../helpers/formatHelper";
import ErrorDialog from "../dialogs/ErrorDialog";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import {getRestaurant} from "../../actions/restaurant.actions";
import {getMenu} from "../../actions/menu.actions";
import Screen from '../screen';
import { confirmSessionOrder, requestBill, resetSessionOrder } from "../../helpers/apiHelper";
import { FirestoreHelper } from "../../helpers/firestoreHelper";

import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;
import User = firebase.User;

interface IConfirmOrderProps extends PropsWithChildren<any> {
    user: User,
    restaurant: any,
    state:any,
    firestoreUser: DocumentSnapshot,
    menu: DocumentSnapshot,
    dits: DocumentSnapshot,
    isRecap: boolean,
    linkAlias:string | null,
    paymethod: string | null,
    slotSelected: TimeSlot | undefined | null,
    addTimeSlotToDits: (ditsRef: DocumentReference,slotSelected:TimeSlot | undefined | null,saved:Boolean) => any,
    deleteItemsFromDits: (items: DocumentSnapshot[]) => any,
    confirmOrder: (restaurantID: string,menuId:string, sessionID: string, paymentMethod: string,slotSelected:TimeSlot | undefined | null) => any,
    listenAvailabilities: (restaurant: any, menu: DocumentSnapshot) => any,
    listenForDits: (ditsRef: DocumentReference) => any,
    listenForDitsMenuItems: (ditsRef: DocumentReference) => any,
    showLogin: (show: boolean, restaurantID: string, ditsID: string) => boolean,
    retrieveRestaurant: (restaurantID: string) => any,
    retrieveMenu: (restarauntID: string, menuID: string) => any,
    retrieveSession: (restaurantID: string, ditsID: string) => any,
    addPaymentMethodToSession:(ditsRef: DocumentReference,paymethod: string | null,saved:Boolean) => any,
}

let unsubscribeFromListenAvailabilities: firebase.Unsubscribe | null = null;
let unsubscribeFromListenForDits: firebase.Unsubscribe | null = null;
let unsubscribeFromListenForDitsMenuItems: firebase.Unsubscribe | null = null;
let unsubscribeFromListenForBillLog: firebase.Unsubscribe | null = null;

interface IDeliveryCostsRowProps{
    price:string
}

const DeliveryCostsRow = ({price}:IDeliveryCostsRowProps) => {

    return (
    <div>
        <div className="row recap-item-row">
            <div className="col-2"/>
            <div className="col-5 text-left">
                <span className="recap-item-name-label">
                    {LocalizerHelper.localized('delivery_cost')}
                </span>
            </div>
            <div className="col-3 text-right">
                <span className="recap-item-price-label">{price}</span>
            </div>
            <div className="col-2 text-left"></div>
        </div>
    </div>)
};

const _OrderRecap = ({
    state,
    match,
    restaurant,
    menu,
    dits,
    ditsMenuItems,
    user,
    firestoreUser,
    isRecap = false,
    availabilities,
    linkAlias,
    paymethod,
    slotSelected,
    addTimeSlotToDits,
    deleteItemsFromDits,
    listenAvailabilities,
    listenForDits,
    listenForDitsMenuItems,
    listenForDitsBillLog,
    showLogin,
    retrieveRestaurant,
    retrieveMenu,
    retrieveSession,
    addPaymentMethodToSession
}: IConfirmOrderProps) => {

    const history = useHistory();

    // ERRORS AND LOADING
    const [isInError, setIsInError] = React.useState(false);
    const [error, setError] = React.useState(null);

    const [loading, setLoading] = React.useState(false);
    const [waitForBill, setWaitForBill] = React.useState(false);

    const [isRejected, setIsRejected] = React.useState(false);
    //const [resetDone, setResetDone] = React.useState(false);
    const [reject, setReject] = React.useState(null);

    const [toDeleteItem, setToDeleteItem] = React.useState<any>(null);
    const [showAskDelete, setShowAskDelete] = React.useState(false);
    const [askConfirm, setAskConfirm] = React.useState(false);

    const [orderNeedApproval, setOrderNeedApproval] = React.useState(false);
    const [mustPayFirst, setMustPayFirst] = React.useState(false);

    // DATA
    const [groupedItems, setGroupedItems] = React.useState<any>([]);
    const [orderApprovementRequest, setOrderApprovementRequest] = React.useState<DocumentSnapshot|null>(null);
    const [bill, setBill] = React.useState<any|null>(null);

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const  _requestUserData  = (dits?.data()?.service_type?.request_user_data ?? false);
    const [paymentMethodSelected, setPaymentMethodSelected] = React.useState<string>(dits?.data()?.payment_method ??  paymethod ?? "stripe");
    const [currentSlotSelected, setCurrentSlotSelected] = React.useState<TimeSlot | undefined | null>(getSlot(dits?.data()?.time_slot) ?? slotSelected ?? null);

    let _minimumOrderAmount = 0;
    let _isMajorThanMinimumPrice = true;
    
    const _load = () => {
        if (!restaurant || !menu || !dits) {
            const rID = match?.params?.restaurant;
            const mID = match?.params?.menu;
            const dID = match?.params?.dits;
            retrieveRestaurant(rID);
            retrieveMenu(rID, mID);
            retrieveSession(rID, dID);
        }
    }

    const _reject = async () => {
        const rID = match?.params?.restaurant;
        const mID = match?.params?.menu;
        const dID = match?.params?.dits;
        const res = await resetSessionOrder(rID, mID, dID, paymentMethodSelected);
        console.log("reject_response: ", res);
        setReject(res);
        /*console.log("start: ", !isRecap && !resetDone);
        console.log("!isRecap: ", !isRecap);
        console.log("!resetDone: ", !resetDone);
        if(!isRecap && !resetDone){
            const rID = match?.params?.restaurant;
            const mID = match?.params?.menu;
            const dID = match?.params?.dits;
            const res = await resetSessionOrder(rID, mID, dID, paymentMethodSelected);
            console.log("reject_response: ", res);
            setReject(res);
            setResetDone(true);
        }*/
    }

    useEffect(() => {
        _load();
        return () => {
            if (!!unsubscribeFromListenForDits) {
                unsubscribeFromListenForDits();
                unsubscribeFromListenForDits = null;
            }
            if (!!unsubscribeFromListenForDitsMenuItems) {
                unsubscribeFromListenForDitsMenuItems();
                unsubscribeFromListenForDitsMenuItems = null;
            }
            if (!!unsubscribeFromListenAvailabilities) {
                unsubscribeFromListenAvailabilities();
                unsubscribeFromListenAvailabilities = null;
            }
            if (!!unsubscribeFromListenForBillLog) {
                unsubscribeFromListenForBillLog();
                unsubscribeFromListenForBillLog = null;
            }
        }
    }, []);

    useEffect(() => {
        if (!!restaurant && !!menu) {
            if (!!unsubscribeFromListenAvailabilities) {
                unsubscribeFromListenAvailabilities();
            }
            unsubscribeFromListenAvailabilities = listenAvailabilities(restaurant, menu);
        }
    }, [restaurant, menu]);

    
    useEffect(() => {
        if (!!dits) {
            if (!checkIfNotExists(unsubscribeFromListenForDits)) {
                unsubscribeFromListenForDits = listenForDits(dits?.ref);
            }
            if (!!unsubscribeFromListenForDitsMenuItems) {
                unsubscribeFromListenForDitsMenuItems();
            }
            unsubscribeFromListenForDitsMenuItems = listenForDitsMenuItems(dits?.ref);
            const hasTimeSlot = !!currentSlotSelected;
            if (hasTimeSlotSelection && !hasTimeSlot && !!availabilities && availabilities.length > 0) {
                _selectDefaultTimeSlot();
            }
        }
    }, [dits]);

    useEffect(() => {
        if (!!reject) {
            console.log("reject: ", reject);
            const code = reject?.["code"];
            console.log(code);
            if(code !== "ok"){
                setIsRejected(true);
            }
        }
    }, [reject]);

    useEffect(() => {
        //time_slot
        if (!!dits && !!availabilities && hasTimeSlotSelection) {
            const hasTimeSlot = !!currentSlotSelected;
            if (hasTimeSlot && !!availabilities && availabilities.length > 0) {
                const timeStart = currentSlotSelected?.start ?? null;
                if(timeStart){
                    const id = `availability-box-${timeStart.toDate().getTime()}`;
                    const elemenToScroll = document.getElementById(id);
                    if(elemenToScroll){
                        elemenToScroll.scrollIntoView({ behavior: 'auto',
                            block: 'center',
                            inline: 'center' 
                        });
                    }
                }
            }
        }
    }, [availabilities]);

    useEffect(() => {
        if (!!ditsMenuItems) {
            const _groupedItems = groupSameItems(ditsMenuItems);
            setGroupedItems(_groupedItems);
        }
    }, [ditsMenuItems]);

    useEffect(() => {
        if (!!bill) {
            if (orderNeedApproval) {
                const alias = linkAlias ?? lastLinkAlias;
                const url = `/menu/${alias}/waiting-order-approval/${restaurant.id}/${dits.id}/${orderApprovementRequest?.id}`;
                _push(url, {});
            } else {
                if (!!bill && waitForBill) {
                    setLoading(false);
                    setWaitForBill(false);
                    if (!!mustPayFirst) {
                        const alias = linkAlias ?? lastLinkAlias;
                        console.log("bill: ", bill);
                        console.log("bill.id: ", bill.billLogId);
                        const url = `/menu/${alias}/payment/${restaurant.id}/${dits.id}/${bill.billLogId}`;
                        _push(url, {});
                    } else {
                        const alias = linkAlias ?? lastLinkAlias;
                        const url = `/menu/${alias}/thanks`;
                        _push(url, {});
                    }
                }
            }
        }
    }, [bill]);

    const _push = (url: string, state: any) => {
        //setResetDone(false);
        setLoading(false);
        history.push(url, state);
    }

    const _closeErrorDialog = () => {
        setIsInError(false);
        setError(null);
    }

    const _closeErrorAndGoHomepage = () => {
        setIsRejected(false);
        const alias = linkAlias ?? lastLinkAlias;
        history.replace(`/menu/${alias}`,{});
        history.go(0);
    }

    const _showErrorMessage = (error: any) => {
        setLoading(false);
        setIsInError(true);
        setError(error);
    }

   
    const _selectDefaultTimeSlot = () => {
        const deliveryMode = dits?.data()?.delivery_mode;
        for (const availability of availabilities) {
            const toShowPercentage = deliveryMode === DELIVERY_MODES.inPlace ? availability.takeAwayPercentage : (availability.takeAwayPercentage > availability.deliveryPercentage ? availability.takeAwayPercentage : availability.deliveryPercentage);
            const notAvailable = ditsMenuItemsWeight > availability.remainingTakeAwaySlots || toShowPercentage >= 100;
            if (!notAvailable) {
                _selectTimeSlot(availability);
                break;
            }
        }
    }

    const _askDeleteItems = (groupItem: any) => {
        setShowAskDelete(true);
        setToDeleteItem(groupItem);
    }

    const _onAllowDeleteItems = () => {
        const item = !!toDeleteItem && !!toDeleteItem.items && toDeleteItem.items.length > 0 ? toDeleteItem.items[toDeleteItem.items.length - 1] : null;
        if (!!item) {
            deleteItemsFromDits([item]);
        }
        setShowAskDelete(false);
        setToDeleteItem(null);
    }

    const _onDenyDeleteItems = () => {
        setShowAskDelete(false);
        setToDeleteItem(null);
    }


    const _selectTimeSlot = (availability: IAvailability) => {
        if(!!availability){
            
            const slot:TimeSlot = {
                start: Timestamp.fromDate(availability.timeStart),
                end: Timestamp.fromDate(availability.timeEnd),
                offset:availability.offset
            }
            console.log("@@@ _selectTimeSlot");
            console.log(slot);
            console.log("isRecap");
            console.log(isRecap);
            addTimeSlotToDits(dits?.ref,slot,isRecap);
            setCurrentSlotSelected(slot);
        }else{
            addTimeSlotToDits(dits?.ref,null,isRecap);
            setCurrentSlotSelected(null);
        }

        /*
        if (!!dits && hasTimeSlotSelection) {
            addTimeSlotToDits(dits?.ref, availability.timeStart, availability.timeEnd, availability.offset);
        }*/
    }

    const isUserComplete = () => (!!user && !user.isAnonymous && !!firestoreUser && firestoreUser.exists && checkIfNotEmpty(firestoreUser?.data()?.email) && checkIfNotEmpty(firestoreUser?.data()?.nickname));
    const isDelivery = dits?.data()?.delivery_mode === DELIVERY_MODES.delivery;
    
    const _proceedWithOrderFromRecap = () => {
        if (!isUserComplete()) {
            showLogin(true, restaurant.id, dits.id);
        } else if (isDelivery) {
            if(_requestUserData){
                const alias = linkAlias ?? lastLinkAlias;
                const url = `/menu/${alias}/info-details/delivery`;
                history.push(url);
            }else{
                _proceedWithConfirmOrder(false);
            }
        } else {
            if(_requestUserData){
                const alias = linkAlias ?? lastLinkAlias;
                const url = `/menu/${alias}/info-details/takeaway`;
                history.push(url);
            }else{
                _proceedWithConfirmOrder(false);
            }
        }
    };

    const _confirmDialog = () =>{
        setAskConfirm(false);
        _proceedWithConfirmOrder(true);
    }
    
    const _closeConfirmDialog = () =>{
        setAskConfirm(false);
        setLoading(false);
    }

    const _proceedWithConfirmOrder = async(needReject:Boolean) => {
        console.log("_proceedWithConfirmOrder");
        setLoading(true);
        try{
            if(needReject){
                await _reject();
            }
            const response = await confirmSessionOrder(restaurant.id, menu.id, dits.id, paymentMethodSelected, slotSelected);
            console.log("confirmSessionOrder response: ", response);
            setMustPayFirst(response.mustPayFirst);
            setOrderNeedApproval(response.orderNeedApproval ?? false);
            const orderNeedApproval = response.orderNeedApproval ?? false;
            const orderApprovementRequestPath = response.orderApprovementRequest ?? null;
            console.log("orderApprovementRequestPath: ", orderApprovementRequestPath);
            if (!!restaurant && !!dits) {
                if (orderNeedApproval) {
                    if (checkIfNotEmpty(orderApprovementRequestPath)) {
                        const req = await FirestoreHelper.getSnapshot(orderApprovementRequestPath);
                        setOrderApprovementRequest(req);
                        const alias = linkAlias ?? lastLinkAlias;
                        const url = `/menu/${alias}/waiting-order-approval/${restaurant.id}/${dits.id}/${req?.id}`;
                        _push(url, {});
                        setLoading(false);
                    }
                } else {
                    if (!!unsubscribeFromListenForBillLog) {
                        unsubscribeFromListenForBillLog();
                    }
                    unsubscribeFromListenForBillLog = listenForDitsBillLog(dits?.ref);
                    setWaitForBill(true);
                    const res = await requestBill(restaurant.id, dits.id, PAYMENT_TYPES.all);
                    setBill(res);
                }
            }
        }catch(ex){
            const code = ex.code;
            console.log(code);
            switch (code){
                case RESPONSE_CODES.aborted:
                    setAskConfirm(true);
                    break;
                case RESPONSE_CODES.denied:
                    setIsRejected(true);
                    break;
                default:
                    _showErrorMessage(ex);
                    break;

            }
        }
    }

    const _proceedWithOrder = () => {
        if (isRecap) {
            console.log("@@@ push to _proceedWithOrderFromRecap");
            _proceedWithOrderFromRecap();
        } else {
            console.log("@@@ push to _proceedWithConfirmOrder");
            _proceedWithConfirmOrder(false);
        }
    };

    const _changePaymentMethod = (pMethod: string) => {
        //addPaymentMethodToSession(dits?.ref,paymentMethod);
        if(paymentMethodSelected !== pMethod){ 
            console.log("@@@@ addPaymentMethodToSession");
            console.log(pMethod);
            console.log("isRecap");
            console.log(isRecap);
            addPaymentMethodToSession(dits?.ref,pMethod,isRecap);
            setPaymentMethodSelected(pMethod);
        }
    }

    const _retrievePaymentMethodsButtons = () => {
        const sortedPaymentMethods = _retrievePaymentMethodsEnable();
        if(sortedPaymentMethods!=null && sortedPaymentMethods.length > -1){
            const paymentMethodButtons = sortedPaymentMethods.map(
                (item: any) => (<div className={`payment-method-button ${item.value === paymentMethodSelected ? 'selected' : ''}`} onClick={() => _changePaymentMethod(item.value)}>
                                {LocalizerHelper.localized(item.label)}</div>)
            );
            if(paymentMethodButtons.length === 1){
                _changePaymentMethod(sortedPaymentMethods[0].value);
                //addPaymentMethodToSession(dits?.ref,paymentMethodSelected);
            }        
            return paymentMethodButtons;
        }
        return null;    
    }

    const _retrievePaymentMethodsEnable = () => {
        const filteredPaymentMethods: string[] = Object.keys(PAYMENT_METHODS).filter((key: string) => ((dits?.data()?.allowed_payment_methods ?? DEFAULT_ALLOWED_PAYMENT_METHODS).includes(key)));
        const sortedPaymentMethods: any[] = [];
        for (const paymentMethodKey of filteredPaymentMethods) {
            sortedPaymentMethods.push(PAYMENT_METHODS[paymentMethodKey]);
        }
        sortedPaymentMethods.sort((a: any, b: any) => {
            const aOrder = a['order'] ?? 0;
            const bOrder = b['order'] ?? 0;
            return aOrder === bOrder ? 0 : aOrder > bOrder ? 1 : -1;
        });
        if(sortedPaymentMethods !=null && sortedPaymentMethods.length === 1 && sortedPaymentMethods[0].value){
            if(paymentMethodSelected == null || paymentMethodSelected !== sortedPaymentMethods[0].value){
                _changePaymentMethod(sortedPaymentMethods[0].value);
                //addPaymentMethodToSession(dits?.ref,paymentMethodSelected);
            }
        }
        return sortedPaymentMethods;    
    }

    const totalPrice = ditsMenuItems?.map((item: any) => evaluateDitsMenuItemPrice(item)).reduce((acc: number, price: number) => {
        return acc += (price * 1.0)
    }, 0) ?? 0;
    
    const deliveryCost = restaurant?.data?.delivery_cost ?? 0;
    const free_delivery_minimum_amount = (restaurant?.data?.free_delivery_minimum_amount ?? 0);
    const mustApplyDeliveryCost = (deliveryCost > 0) ? ((free_delivery_minimum_amount > 0) ? (totalPrice < free_delivery_minimum_amount):true) : true;
    const isDeliveryMode = (dits?.data()?.delivery_mode === DELIVERY_MODES.delivery);

    const ditsMenuItemsWeight = ditsMenuItems?.map((item: DocumentSnapshot) => (item?.data()?.prepare_weight ?? 1)).reduce((acc: number, item: number) => acc += item, 0);
    const availabilityBoxes = availabilities?.map((availability: IAvailability) =>
        <AvailabilitySelectableBox availability={availability} ditsMenuItemsWeight={ditsMenuItemsWeight ?? 0}
                                   deliveryMode={dits?.data()?.delivery_mode}
                                   selectTimeSlot={_selectTimeSlot}
                                   selected={isTimeSlotSelected(currentSlotSelected, availability)}
                                   key={`availability-box-${availability.timeStart.getTime()}`}/>);

    const ditsMenuItemRows = (groupedItems ?? [])?.map((groupItem: any) => <MenuItemRow groupItemKey={groupItem.key}
        menuItem={groupItem.item}
        qty={groupItem.qty ?? 1}
        canDelete={true}
        onDeleteItem={() => _askDeleteItems(groupItem)}
        key={`menu-item-row-${groupItem.item.id}`}/>);

    const paymentMethodButtons = _retrievePaymentMethodsButtons();
    
    const hasDeliveryDetails = !isRecap && !!dits?.data()?.delivery_details;
    const deliveryDetails = hasDeliveryDetails ? dits?.data()?.delivery_details : {};
    const hasTimeSlot = !!currentSlotSelected;
    const hasTimeSlotSelection =  (dits?.data()?.service_type?.has_time_slots ?? true);

    console.log("@@@@ hasDeliveryDetails");
    console.log(hasDeliveryDetails);
   
    const _canProceedWithPriceOrder = () => {
        console.log("@@@ _canProceedWithPriceOrder");
        if(restaurant && restaurant.data){
            _minimumOrderAmount = (restaurant.data?.minimum_external_order_amount ?? 0) * 1.0;
            _isMajorThanMinimumPrice = totalPrice >= _minimumOrderAmount;
            console.log(`_minimumOrderAmount => ${_minimumOrderAmount}`);    
            console.log(`_isMajorThanMinimumPrice => ${_isMajorThanMinimumPrice}`);    
            console.log(`totalPrice => ${totalPrice}`);    
            return (totalPrice > -1 && _isMajorThanMinimumPrice);
        }
        return false;
    }

    const _canProceedWithOrder = () => {
        const proceed = _canProceedWithPriceOrder() ? (isRecap ? ((hasTimeSlotSelection ? !hasTimeSlot : false)) : false) : true;
        console.log("@@@ _canProceedWithOrder");
        console.log(proceed);
        return proceed;
    }


    const _buildTimeSlots = () => {
        if (!hasTimeSlotSelection) return null;
        return (
         <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left">
                        <Typography variant="h4" gutterBottom align="left" className="recap-header">
                            {LocalizerHelper.localized('time_slots')}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="container availabilities-container">
                    {!!availabilities && availabilities.length > 0 ? 
                        <div className="row">
                            {availabilityBoxes} 
                        </div> 
                        : <CircularProgress variant="indeterminate" className="spinner"/>
                    }
            </div> 

        </div>    );
    }

    const _renderDetailDelivery = () => {
        if(isDeliveryMode){
            const previewDeliveryCost: number = dits?.data()?.delivery_details?.preview_delivery_cost ?? 0;
            if(!mustApplyDeliveryCost){
                return <DeliveryCostsRow price={formatPrice(0)}/>
            }
            return  <DeliveryCostsRow price={formatPrice(previewDeliveryCost)}/>
        }
        return null
    }

    return <Screen className="screen order_recap_screen">
        <RestaurantHeader className="block" restaurant={restaurant}/>
        {_buildTimeSlots()}
        {paymentMethodButtons && paymentMethodButtons.length > 1 ?
            <div className="container">
                <div style={{marginBottom: 20}}>
                    <div className="row">
                        <div className="col-md-12 text-left">
                            <Typography variant="h4" gutterBottom align="left" className="recap-header">
                                {LocalizerHelper.localized('choose_payment_method')}
                            </Typography>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 20}}>
                        <div className="col-md-12 text-center">
                            <div className="payment_buttons_container">
                                {paymentMethodButtons}
                            </div>
                        </div>
                    </div>
                </div>
              </div>  
              : null
        }
        {hasDeliveryDetails ?
            <div className="container" style={{marginBottom: 20}}>
                <div className="row">
                    <div className="col-md-12 text-left">
                        <Typography variant="h4" gutterBottom align="left" className="recap-header">
                            {LocalizerHelper.localized('delivery_details')}
                        </Typography>
                    </div>
                </div>
                <div className="row">
                    {deliveryDetails.address &&
                    <div className="col-md-12 text-left">
                        <span
                            className="delivery-details-label">{LocalizerHelper.localized('address')}</span> {deliveryDetails.address}
                    </div>}
                    {deliveryDetails.doorbell_name &&
                    <div className="col-md-12 text-left">
                        <span
                            className="delivery-details-label">{LocalizerHelper.localized('doorbell_name')}</span> {deliveryDetails.doorbell_name}
                    </div> }
                    {deliveryDetails.phone_number &&
                    <div className="col-md-12 text-left">
                        <span
                            className="delivery-details-label">{LocalizerHelper.localized('phone_number')}</span> {deliveryDetails.phone_number}
                    </div>}
                    {deliveryDetails.notes &&
                    <div className="col-md-12 text-left">
                        <span
                            className="delivery-details-label">{LocalizerHelper.localized('notes')}</span> {deliveryDetails.notes}
                    </div>
                    }
                </div>
            </div> : null
        }
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-left">
                    <Typography variant="h4" gutterBottom align="left" className="recap-header">
                        {LocalizerHelper.localized('your_order_recap_header')}
                    </Typography>
                </div>
            </div>
        </div>
        {ditsMenuItemRows}
        {_renderDetailDelivery()}
        {showAskDelete ?
            <AskDeleteItems allowCallback={_onAllowDeleteItems} denyCallback={_onDenyDeleteItems}/> : null
        }

        <BottomSheetOrderRecap goToNextLabel={LocalizerHelper.localized('proceed_with_order')}
                               goToNext={_proceedWithOrder} 
                               disableAction={ _canProceedWithOrder()}
                               addDeliveryCosts = {true}
                               />
        {
            loading ? <LoaderGif  logo={null} image={paymentMethodSelected === 'cash' ? "/assets/loading_before_payment_cash.gif": "/assets/loading_before_payment_card.gif"}
                 message={LocalizerHelper.localized((paymentMethodSelected === 'cash') ? 'loading_msg_before_payment_cash' : 'loading_msg_before_payment_card')} /> : null
        }
        <ConfirmDialog open={askConfirm} body={LocalizerHelper.localized('confirm_message_order')} handleClose={_closeConfirmDialog} handleConfirm={_confirmDialog}/>
        <ErrorDialog open={isInError} error={error} handleClose={_closeErrorDialog}/>
        <ErrorDialog open={isRejected} error={LocalizerHelper.localized('order_rejected_or_completed')} handleClose={_closeErrorAndGoHomepage}/>
    </Screen>
}

//        

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    state:state,
    menu: state.menu.menu,
    dits: state.session.session,
    user: state.auth.user,
    availabilities: state.availability.availabilities ?? [],
    ditsMenuItems: state.session?.ditsMenuItems,
    firestoreUser: state.auth.user?.isAnonymous ? null : state.auth.firestoreUser,
    linkAlias:state.link.linkAlias ?? null,
    paymethod: state.session.paymethod,
    slotSelected: state.session.slotSelected
});

const mapDispatchToProps = (dispatch: any) => ({
    retrieveRestaurant: async (restaurantID: string) => dispatch(await getRestaurant(restaurantID)),
    retrieveMenu: async (restaurantID: string, menuID: string) => dispatch(await getMenu(restaurantID, menuID)),
    retrieveSession: async (restaurantID: string, ditsID: string) => dispatch(await getSession(restaurantID, ditsID)),
    listenAvailabilities: listenForAvailabilities(dispatch),
    listenForDits: listenForSession(dispatch),
    listenForDitsMenuItems: listenForSessionMenuItems(dispatch),
    listenForDitsBillLog: listenForBillLogs(dispatch),
    addTimeSlotToDits: async (ditsRef: DocumentReference,slotSelected:TimeSlot | undefined | null,saved:Boolean = false) => dispatch(await addTimeSlotToSession(ditsRef,slotSelected,saved)),
    deleteItemsFromDits: async (items: DocumentSnapshot[]) => dispatch(await deleteItemsFromSession(items)),
    confirmOrder: async (restaurantID: string, menuId:string, sessionID: string, paymentMethod: string,slotSelected:TimeSlot | undefined | null) => dispatch(await confirmOrder(restaurantID,menuId, sessionID, paymentMethod, slotSelected)),
    showLogin: (show: boolean, restaurantID: string, ditsID: string) => dispatch(showLogin(show, restaurantID, ditsID)),
    addPaymentMethodToSession:async(ditsRef: DocumentReference,paymethod: string | null,saved:Boolean = false) => dispatch(await addPaymentMethodToSession(ditsRef,paymethod,saved)),
});

const OrderRecap = connect(mapStateToProps, mapDispatchToProps)(_OrderRecap);

export default OrderRecap;
