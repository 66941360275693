import firebase from "firebase";
import {FirestoreHelper} from "../helpers/firestoreHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {MENU_CONSTANTS} from "../constants/menu.constants";
import * as cron from "cron-parser";
import DateTimeHelper from "../helpers/dateTimeHelper";
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

const _selectNextAvailableMenu = (menus: DocumentSnapshot[]) => {
    const menuNextAvailable: any = {}
    for (const menu of menus) {
        if (!menu.data()?.availability) {
            continue;
        }
        for (const availability of menu.data()?.availability) {
            const now = new Date();
            now.setSeconds(0);
            now.setMilliseconds(0);
            const interval = cron.parseExpression(availability);
            let nextDate = interval.hasNext() ? interval.next()?.toDate() : null;
            while (!!nextDate && DateTimeHelper.isGreaterOrEqual(now, nextDate)) {
                if (DateTimeHelper.isEqual(now, nextDate)) {
                    return menu;
                }
                nextDate = interval.hasNext() ? interval.next()?.toDate() : null;
            }
            if (!!nextDate) {
                if (!!menuNextAvailable[menu.id]) {
                    const dateDiffTime = DateTimeHelper.getDateTimeDiff(now, nextDate);
                    menuNextAvailable[menu.id] = dateDiffTime > menuNextAvailable[menu.id] ? menuNextAvailable[menu.id] : dateDiffTime;
                }
            }
        }
    }
    const nextAvailableArray: any = Array.from(menuNextAvailable).sort((a: any, b: any) => (a[1] - b[1]));
    return menus.find(nextAvailableArray[0][0])
}

const _selectAllAvailableMenus = (menus: DocumentSnapshot[]) => {
    const allMenus = [];
    for (const menu of menus) {
        let isAvailable = false;
        if (!menu.data()?.availability) {
            continue;
        }
        for (const availability of menu?.data()?.availability) {
            if (isAvailable) {
                break;
            }
            const now = new Date();
            now.setSeconds(0);
            now.setMilliseconds(0);
            now.setMinutes(now.getMinutes() + 1);
            const interval = cron.parseExpression(availability);
            let nextDate = interval.hasNext() ? interval.next()?.toDate() : null;
            while (!!nextDate && DateTimeHelper.isGreaterOrEqual(now, nextDate)) {
                console.log('@@@@@@nextDate', nextDate);
                if (DateTimeHelper.isEqual(now, nextDate)) {
                    allMenus.push(menu);
                    isAvailable = true;
                    break;
                }
                nextDate = interval.hasNext() ? interval.next()?.toDate() : null;
            }
        }
    }
    return allMenus;
}

const getMenus = async (restaurantID: string) => {
    console.log('@@@@@@get menus with id ' + restaurantID);
    try {
        // retrieve all menu active and take_or_delivery = true oppure state = 'external'
        const menus = (await FirestoreHelper.menus(restaurantID)).docs;
        const availableMenus = _selectAllAvailableMenus(menus);
        const allMenuItems:DocumentSnapshot[] = []
        const allMenuItemsRef:string[] = []
        // cicling all availableMenus and retrieve all menu:_items inside layout
        // sub_sections
        //const allMenusItemsFromLayout = (await FirestoreHelper.allMenusItemsFromLayout(restaurantID)).docs;
        for(const menu of availableMenus){
            const data  = menu.data();
            if(data && data.layout && Array.isArray(data.layout)){
                const layout = data.layout ?? []; //cycling on layout to retrieve sub_sections
                for(const lyt of layout){
                    const subSections = lyt.sub_sections ?? [];
                    for(const subSection of subSections){
                        if(subSection && subSection.menu_items){
                            const menuItems = subSection.menu_items ?? null;
                            if (Array.isArray(menuItems) && menuItems.length) {
                                for(const documentRef of menuItems){
                                    if (allMenuItemsRef.findIndex(item => item === documentRef?.path) === -1) {
                                        allMenuItemsRef.push(documentRef.path);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const promises:Promise<DocumentSnapshot>[] = []   // collect promises here
        allMenuItemsRef.forEach(path => {
            promises.push(firebase.firestore().doc(path).get())
        })
        Promise.all(promises).then(results => {
            results.forEach(doc => {
                allMenuItems.push(doc);
            })
          }
        );
        
        return buildActionResult(
            MENU_CONSTANTS.RETRIEVE_MENUS, {
                hasFetchedMenus: true,
                menus: availableMenus,
                //selectedMenu,
                allMenuItems
            }
        );
    } catch (error) {
        return buildActionResult(
            MENU_CONSTANTS.RETRIEVE_MENUS_ERROR, {
                hasFetchedMenus: false,
                menus: [],
                error,
            }
        );
    }
}

const getMenusOLD = async (restaurantID: string) => {
    console.log('@@@@@@get menus with id ' + restaurantID);
    try {
        const menus = (await FirestoreHelper.menus(restaurantID)).docs;
        const allMenus = (await FirestoreHelper.allMenus(restaurantID)).docs;
        const availableMenus = _selectAllAvailableMenus(menus);
        const allMenuItems = [];
        for (const menu of allMenus) {
            const menuItems = (await FirestoreHelper.menuItems(restaurantID, menu.id)).docs;
            for (const menuItem of menuItems) {
                if (allMenuItems.findIndex(item => item?.ref?.path === menuItem?.ref?.path) === -1) {
                    allMenuItems.push(menuItem);
                }
            }
        }
        return buildActionResult(
            MENU_CONSTANTS.RETRIEVE_MENUS, {
                hasFetchedMenus: true,
                menus: availableMenus,
                //selectedMenu,
                allMenuItems
            }
        );
    } catch (error) {
        return buildActionResult(
            MENU_CONSTANTS.RETRIEVE_MENUS_ERROR, {
                hasFetchedMenus: false,
                menus: [],
                error,
            }
        );
    }
}

const getMenu = async (restaurantID: string, menuID: string) => {
    console.log(`getMenu [${restaurantID}] [${menuID}]`);
    const menu = await FirestoreHelper.menu(restaurantID, menuID);
    console.log(menu);
    return buildActionResult(MENU_CONSTANTS.RETRIEVE_MENU, {
        menu
    });
}

const getMenuItems = async (restaurantID: string, menuID: string) => {
    const menuItems = (await FirestoreHelper.menuItems(restaurantID, menuID)).docs;
    return buildActionResult(
        MENU_CONSTANTS.RETRIEVE_MENU_ITEMS, {
            menuItems
        }
    )
}

const getMenuItem = async (restaurantID: string, menuID: string, menuItemID: string) => {
    const menuItem = await FirestoreHelper.menuItem(restaurantID, menuID, menuItemID);
    console.log('@@@@@@menuItem');
    console.log(menuItem);
    return buildActionResult(
        MENU_CONSTANTS.RETRIEVE_MENU_ITEM, {
            menuItem
        }
    )
}

const getMenuItemsByMenu = async(menu:any) => {

    console.log("@@@@ getMenuItemsByMenu");
    console.log("@@@@ getMenuItemsByMenu");
    console.log("@@@@ getMenuItemsByMenu");
    console.log(menu);
    const allMenuItems:DocumentSnapshot[] = []
    const allMenuItemsRef:string[] = []
    const layout = menu?.data().layout ?? []; //cycling on layout to retrieve sub_sections
    console.log(layout);
    for(const lyt of layout){
        const subSections = lyt.sub_sections ?? [];
        for(const subSection of subSections){
            if(subSection && subSection.menu_items){
                const menuItems = subSection.menu_items ?? [];
                if (menuItems && menuItems.length > 0) {
                    for(const documentRef of menuItems){
                        if (allMenuItemsRef.findIndex(item => item === documentRef?.path) === -1) {
                            allMenuItemsRef.push(documentRef.path);
                        }
                    }
                }
            }
        }
    }
    console.log(allMenuItemsRef);
    const promises:Promise<DocumentSnapshot>[] = []   // collect promises here
    allMenuItemsRef.forEach(path => {
        promises.push(firebase.firestore().doc(path).get())
    })
    await Promise.all(promises).then(results => {
        results.forEach(doc => {
            allMenuItems.push(doc);
        })
      }
    );
    console.log(allMenuItems);
    const data = buildActionResult(
        MENU_CONSTANTS.RETRIEVE_MENUS, {
            hasFetchedMenusItems: true,
            allMenuItems
        }
    );
        
    return data;

}

const setMenu = (menu: any) => {
    
    console.log("@@@@@@ setMenu");
    console.log("@@@@@@ setMenu");
    console.log("@@@@@@ setMenu");
    const data =  buildActionResult(MENU_CONSTANTS.RETRIEVE_MENU, {
        selectedMenu:menu
    });
    console.log(data);
    return data;
    
}


export {
    getMenus,
    getMenu,
    getMenuItems,
    getMenuItem,
    setMenu,
    getMenuItemsByMenu
}
