import * as React from 'react';

import {connect} from 'react-redux';

import firebase from "firebase";
import {getMenu, getMenuItems} from "../../actions/menu.actions";
import {Card, CardContent, Typography} from "@material-ui/core"
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {useHistory} from "react-router";
import {PropsWithChildren} from "react";
import BottomSheetOrderRecap from "../bottom_order_recap/BottomOrderRecap";
import {BottomSheet} from "../bottom_sheet/BottomSheet";
import MenuItemDetail from "./MenuQuidItemDetail";
import {addItemsToSession, listenForSessionMenuItems} from "../../actions/session.actions";
import {DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS} from "../../constants/data";
import {formatMenuItemPrice} from "../../helpers/menuItemHelper";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {evaluateDitsMenuItemPrice} from "../../helpers/ditsMenuItemHelper";
import {formatPrice} from "../../helpers/formatHelper";
import {makeStyles} from "@material-ui/core/styles";
import Screen from '../screen';
import {useLocation} from 'react-router-dom';
import MenuQuidItem from './MenuQuidItem';
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;


const useEffect = React.useEffect;

interface IMenuItemProps {
    menuItem: DocumentSnapshot,
    openDetail: (menuItem: DocumentSnapshot) => void
}

interface IMenuItemLayoutProps {
    layoutIndex: number,
    layout: any,
    allMenuItems: DocumentSnapshot[],
    openDetail: (menuItem: DocumentSnapshot) => void
}

interface IMenuProps extends PropsWithChildren<any> {
    dits: DocumentSnapshot,
    user: DocumentSnapshot,
    menu: DocumentSnapshot,
    bill: DocumentSnapshot,
    allMenuItems: DocumentSnapshot[],
    loading: boolean,
    linkAlias:string | null,
    addItemsToDits: (ditsRef: DocumentReference, userRef: DocumentReference, menuItem: DocumentSnapshot, addOns: any[], minus: any[], notes?: string, qty?: number) => any,
    listenForDitsMenuItems: (ditsRef: DocumentReference) => any,
}

const useCardStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)'
    }
}));

const MenuItem = ({menuItem, openDetail}: IMenuItemProps) => {
    const isNotAvailable = menuItem?.data()?.status === DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS.na;
    const headerClassName = isNotAvailable ? 'menu-item-header not-available' : 'menu-item-header';

    const cardClasses = useCardStyles();

    return (<div key={`menu-item-${menuItem.id}`} className="menu-item">
        <Card onClick={() => openDetail(menuItem)} className={cardClasses.root}>
            <CardContent>
                <div className="row">
                    <div className="col-8">
                        <Typography color="textSecondary" gutterBottom align="left" className={headerClassName}>
                            {LocalizerHelper.localized(menuItem?.data(), 'name')}
                        </Typography>
                    </div>
                    <div className="col-4 text-right">
                        <Typography color="textSecondary" gutterBottom align="right" className={headerClassName}>
                            {formatMenuItemPrice(menuItem)}
                        </Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-left">
                        {LocalizerHelper.localized(menuItem?.data(), 'description')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-left">
                        {menuItem?.data()?.allergens?.map((item: string) => <img
                            src={`/assets/icons/allergens/icon_allergen_${item.toLowerCase()}.png`}
                            className={'allergen-icon'} key={`allergen-icon-${item}`}/>)}
                    </div>
                </div>
            </CardContent>
        </Card>
    </div>);
}

const MenuLayout = ({layoutIndex, layout, allMenuItems, openDetail}: IMenuItemLayoutProps) => {

    return (<div>
            {layout.sub_sections.map((section: any, index: number) => {
                const title = LocalizerHelper.localized(section, 'title');
                const description = LocalizerHelper.localized(section, 'description');
                return (<div className="row subsection-row" style={{marginTop: 22}}
                            key={`layout-${layoutIndex}-subsection-${index}`}>
                    <div className="col-md-12 text-left title_menu" style={{paddingLeft: 20, paddingRight: 20}}>
                        {!!title && title.trim().length > 0 ?
                            <p>{LocalizerHelper.localized(section, 'title')}</p> : null
                        }
                        {!!description && description.trim().length > 0 ?
                            <p>{LocalizerHelper.localized(section, 'description')}</p> : null
                        }
                    </div>
                    {(section?.menu_items ?? [])?.map((item: any) => {
                        return item ?
                            <div className="col-md-12 menu-item-card" key={`menu-item-${item.path}`}>
                                <MenuQuidItem menuItemRef={(item.path ?? "")} openDetail={openDetail}/>
                            </div> : null
                        
                    })}
            </div>);
        })}
            </div>)
        }

let unsubscribeFromListenForDitsMenuItems: firebase.Unsubscribe | null = null;
let refTabContainer: HTMLElement | null = null;
const refTabs: any[] = [];

const useIsMounted = () => {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {isMounted.current = false};
  }, []);
  return isMounted;
};

const _Menu = ({
    match,
    loading,
    restaurant,
    tableName,
    dits,
    bill,
    firestoreUser,
    menu,
    linkAlias,
    allMenuItems,
    ditsMenuItems,
    retrieveMenu,
    retrieveMenuItems,
    addItemsToDits,
    listenForDitsMenuItems
}: IMenuProps) => {

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [openDetail, setOpenDetail] = React.useState(false);
    const [canAdd, setCanAdd] = React.useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState<DocumentSnapshot | null>(null);
    const [selectedMenu, setSelectedMenu] = React.useState<any | null>(menu);
    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const [hideOnScroll, setHideOnScroll] = React.useState(true)
    const history = useHistory();
    const { state } = useLocation();
    const elementLayoutRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const elementRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const restaurantID = match?.params?.restaurant;
    const menuID = match?.params?.menu;
    const quid = match?.params?.quid;
    const link_alias = match?.params?.link_alias;
    const canBeOrdered = (link_alias && link_alias != '');
    const isMounted = useIsMounted();

    let isTicking:any;
    let _lastScroll:number = 0;
    let _lastSelectedIndexLayout:number = 0;
    let _stopScrolling:boolean = false;
    let _boundScroll:any = null;
    let _offsetYScroll:number = 0;

    const [headerStyle, setHeaderStyle] = React.useState({
        transition: 'all 200ms ease-in'
    })

    /*
        if (isTicking) return;
        requestAnimationFrame(() => {
          callback(evt);
          isTicking = false;
        });
        isTicking = true;
        */

    const debounce = (callback:any, evt:any) => {

        if(!!_boundScroll) clearTimeout(_boundScroll);
        _boundScroll = setTimeout(()=> callback(evt),100);          
    };

    const elementInViewport = (el:any,xV:number,yV:number,widthV:number,heightV:number) =>{
        console.log(el);
        if(!!el){
            let top = el.offsetTop;
            let left = el.offsetLeft;
            let width = el.offsetWidth;
            let height = el.offsetHeight;

             while(el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }
            /*console.log('top', top);
            console.log('yV', yV);
            console.log('heightV', heightV);
            console.log('top < (yV + heightV)', top < (yV + heightV));

            console.log('left', left);
            console.log('xV', xV);
            console.log('widthV', widthV);
            console.log('left < (xV + widthV)', left < (xV + widthV));

            console.log('top', top);
            console.log('height', height);
            console.log('yV', yV);
            console.log('(top + height) > yV', (top + height) > yV);

            console.log('left', left);
            console.log('width', width);
            console.log('xV', xV);
            console.log('(left + width) > xV', (left + width) > xV);*/
            return (
                top < (yV + heightV) &&
                //left < (xV + widthV) &&
                (top + height) > yV //&&
                //(left + width) > xV
            );
        }
        return false;
    } 

    const getOffset = (el:any) => {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    const handleScroll = (evt:any) => {
        const target = evt.target;
        console.log(target);
        if(!!target){
            const offesetY = _offsetYScroll;
            const preScrollY = _lastScroll;
            let scrollY = target.scrollTop + offesetY;
            const up = (preScrollY < scrollY);
            const scrollX = 0;
            const width = target.offsetWidth;
            const height = target.offsetHeight;
            /*console.log('offesetY', offesetY);
            console.log('preScrollY', preScrollY);
            console.log('scrollY', scrollY);
            console.log('up', up);
            console.log('scrollX', scrollX);
            console.log('width', width);
            console.log('height', height);*/
            let elements = Array.from(document.querySelectorAll('div.section-list-items')?? []);
            let containerHeader = document.getElementById('container_header_id');
            let containerRow = document.getElementById('container_row_id');
            if(!!containerHeader){
                scrollY += containerHeader.offsetHeight;
            }
            if(!!containerRow){
                scrollY += containerRow.offsetHeight;
            }
            //console.log('scrollY',scrollY);
            let elementsVisible = elements.filter((item:any) => elementInViewport(item,scrollX,scrollY,width,height));
            if(!!elementsVisible && elementsVisible.length > 0){
                //console.log(elementsVisible[0]);
                const indexStr = elementsVisible[0].getAttribute('data-index') ?? null;
                if(!!indexStr && !Number.isNaN(Number(indexStr))){
                    //console.log(indexStr);
                    _handleSelectedIndexLayout(parseInt(indexStr),false);
                }
            }
            if(scrollY && scrollY > 100){
                addBodyClass('scroll');
            }else{
                removeBodyClass('scroll');
            }
            _lastScroll = scrollY;
        }
    }
    
    useEffect(() => {
        const menuData = state;
        console.log("@@@@@ _Menu useEffect ");
        console.log('menu');
        console.log(menuData);
        console.log('restaurant');
        console.log(restaurant);
        if(!!restaurant){
            _attachScroll();
            if(!!menuData){
                console.log("setSelectedMenu");
                console.log(menuData);
                setSelectedMenu(menuData);
            }else{
                console.log("retrieve menu");
                retrieveMenu(restaurantID, menuID);
            }
            return () => {
                if (!!unsubscribeFromListenForDitsMenuItems) {
                    unsubscribeFromListenForDitsMenuItems();
                }
            }
        }else{
            console.log("@@@ reload");
            if(link_alias && link_alias != ''){
                history.replace(`/menu/${link_alias}`);
            }else{
                history.replace(`/quid/${quid}`);
            }
            /*
            console.log(menuID);
            console.log(restaurantID);
            console.log(quid);
            console.log(link_alias);
            console.log(history.location);
            console.log(match);
            */
        }
    }, [isMounted]);

    useEffect(() => {
        if(!!menu && Object.entries(menu).length > 0 && menu.data()){
            console.log("useEffect [menu]");
            console.log(menu.data());
            setSelectedMenu(menu.data());
        }
    }, [isMounted,menu]);


    useEffect(() => {
        if(canBeOrdered){
            if (!!dits) {
                if (!!unsubscribeFromListenForDitsMenuItems) {
                    unsubscribeFromListenForDitsMenuItems();
                }
                unsubscribeFromListenForDitsMenuItems = listenForDitsMenuItems(dits?.ref);
            }else{
                const alias = linkAlias ?? lastLinkAlias;
                history.replace(`/menu/${alias}`,{});
                history.go(0);
            }
        }
    }, [dits]);

    useEffect(() => {
        if(canBeOrdered){
            setCanAdd(!bill);
        }
    }, [bill]);


    const _debounceScroll = (evt:any) => {
        if(!!_boundScroll) clearTimeout(_boundScroll);
        debounce(handleScroll, evt);
    }

    const addBodyClass = (className:string) => document.body.classList.add(className);
    const removeBodyClass = (className:string) => document.body.classList.remove(className);


    const _attachScroll = () => {
        if(elementRef && elementRef.current){
            console.log("@@@ _attachScroll");
            _offsetYScroll = getOffset(elementRef.current).top;
            elementRef.current.addEventListener('scroll',handleScroll);
        }
    }
    const _detachScroll = () => {
        if(elementRef && elementRef.current){
            console.log("@@@ _detachScroll");
            elementRef.current.addEventListener('scroll',handleScroll);
        }
    }
    

    

    const _openMenuItemDetail = (menuItem: DocumentSnapshot) => {
        setSelectedMenuItem(menuItem);
        setOpenDetail(true);
    }

    const _closeMenuItemDetail = () => {
        setOpenDetail(false);
        setSelectedMenuItem(null);
    }

    const _addItemToDits = (menuItem: any, quantity: number, addOns: any[] = [], minus: any[] = [], notes: string = '') => {
        if (!!dits && !!firestoreUser && !!menuItem) {
            addItemsToDits(dits?.ref, firestoreUser?.ref, menuItem, addOns, minus, notes, quantity);
        }
        _closeMenuItemDetail();
    }

    const ditsMenuItemsCount = (ditsMenuItems ?? [])?.length;
    const _canShowBottomSheet = ditsMenuItemsCount > 0;
    const totalPrice = ditsMenuItems?.map((item: DocumentSnapshot) => evaluateDitsMenuItemPrice(item)).reduce((acc: number, price: number) => {
        return acc += (price * 1.0)
    }, 0) ?? 0;
    const _minimumOrderAmount = (restaurant?.data?.minimum_external_order_amount ?? 0) * 1.0;
    const _isMajorThanMinimumPrice = totalPrice >= _minimumOrderAmount;
    const _canProceedWithOrder = !loading && ditsMenuItemsCount > 0 && _isMajorThanMinimumPrice;

   const _handleTopBarIndex = (index:number)  => {
       if(_lastSelectedIndexLayout == index) return;
       const lastIdx = _lastSelectedIndexLayout;
        _lastSelectedIndexLayout = index;
        const els = Array.from(document.querySelectorAll(`.top_bar_item`)) ?? [];
        console.log(index);
        console.log(els);
        els.map((el: any, index: number) => {
            if(!!el) el.classList.remove('selected');
        });
        const el2 = document.getElementById(`top_bar_item_${index}`);
        if(!!el2){
            el2.classList.add('selected');
            el2.scrollIntoView({
                block: 'center',
                inline: 'center',
            });
            /*
            el2.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
            });
            */
        }
   }

   const _handleSelectedIndexLayout = (index:number,needScroll:boolean = false)  => {
       _handleTopBarIndex(index);
   }

   const _paintTopBarMenu = () => {

    const layoutSections = selectedMenu.layout ?? [];
    console.log(layoutSections);
    const sections= (layoutSections && layoutSections.length>0) ? layoutSections.filter((item:any) => item.active) : [];
    return  (<div className="topbar scrollmenu" ref={elementLayoutRef}>
                    {sections.map((layout: any, index: number) => {
                        const selectedClass = (index == _lastSelectedIndexLayout) ? 'selected' : '';    
                        return (<div key={`top_bar_item_${index}`} id={`top_bar_item_${index}`}  className={`top_bar_item scrollmenu-item ${selectedClass}`}>
                                <a key={`top_bar_item_${index}`} href={`#section_menulist_${index}`} onClick={()=>_handleSelectedIndexLayout(index,true)}> {LocalizerHelper.localized(layout, 'title')}</a>
                            </div>)
                    })}
            </div>);
   } 

   const _proceedWithOrder = () => {
       if(canBeOrdered){
            const alias = linkAlias ?? lastLinkAlias;
            const orderRecapPath = `/menu/${alias}/order-recap/${restaurant.id}/${menu.id}/${dits.id}`;
            history.push(orderRecapPath);
       }
   }



   const _paintHeader = (logo:string | null,title:string, subtitle:string) => {

        
        return  <div className="container_header">
            <div className={`header container`}>
                <div className='row'>
                    <div className="col-3 text-left">
                    </div>
                    <div className={`title col-6`}>
                        {!!logo ? <img className="logo" src={logo}/> : <div className="header_title"> {title} </div>}
                        {!!subtitle ?  <div className="header_subtitle">{subtitle}</div> : null}
                    </div>
                    <div className="col-3 text-left">
                    </div>
                </div>
            </div>
            <div className="container container_topbar">
                <div className="row row-scroll">
                    {_paintTopBarMenu()}
                </div>    
            </div>
        </div>
        

   }


   const _paintMenuItemList = () => {

    console.log("@@@@ _paintMenuItemList");
    console.log("@@@@ _paintMenuItemList");
    console.log("@@@@ _paintMenuItemList");
    const layoutSections = selectedMenu.layout ?? [];
    console.log(layoutSections);
    const sections= (layoutSections && layoutSections.length>0) ? layoutSections.filter((item:any) => item.active) : [];
    return  (<div className="menulist">
                {sections.map((layout: any, index: number) =>(
                        <div id={`section_menulist_${index}`} data-index={`${index}`} className="section-list-items">
                                    {!!layout.title && layout.title.trim().length > 0 ?
                                        <div className="section-title">{LocalizerHelper.localized(layout, 'title')}</div> : null
                                    }
                                    {!!layout.description && layout.description.trim().length > 0 ?
                                        <p className="section-description" >{LocalizerHelper.localized(layout, 'description')}</p> : null
                                    }
                                    {layout.sub_sections.map((section: any, indexSub: number) => {
                                            const menu_items = section?.menu_items ?? [];
                                            //if(!!menu_items && menu_items.length > 0){
                                                const hasMenuItems = (!!menu_items && menu_items.length > 0);
                                                const tTranslate = LocalizerHelper.localized(section, 'title');
                                                const title = (!!tTranslate && tTranslate.trim() !== '') ? tTranslate + ((hasMenuItems) ? ` (${menu_items.length})` : '') :  null;
                                                const description = LocalizerHelper.localized(section, 'description');
                                                return (<div className="row subsection-row" style={{marginTop: 22}}>
                                                    <div className="col-md-12 text-left title_menu" style={{paddingLeft: 8, paddingRight: 8}}>
                                                        {!!title && title.trim().length > 0 ?
                                                            <div className="section-submenu-title">{title}</div> : null
                                                        }
                                                        {!!description && description.trim().length > 0 ?
                                                            <p className="section-description" >{description}</p> : null
                                                        }
                                                        {(section?.menu_items ?? [])?.map((item: any,indexItem: number) => {
                                                        return (item) ?
                                                            <div className="col-md-12 menu-item-card" >
                                                                <MenuQuidItem key={item.path} menuItemRef={item.path} openDetail={_openMenuItemDetail}/>
                                                            </div> : <div></div>
                                                        
                                                        })}
                                                    </div>
                                                    
                                                </div>);
                                            // }else{
                                            //     return null;
                                            // }
                                        })
                                    }
                        </div>
                    ))}
            </div>);
    
   }


   const _paintLoadingMenu = () => {
        return null;
   }

   const _paintMenuScreen = () => {

    console.log("@@@@ _paintMenuScreen");
    console.log("@@@@ _paintMenuScreen");
    console.log("@@@@ _paintMenuScreen");
    console.log(selectedMenu);

    if(!!selectedMenu){
        //{_paintHeader(logo,restaurantName,tableN)}
        const logo = restaurant?.logo?.url ?? null;
        const restaurantName = restaurant?.name ?? "";
        const tableN = tableName ?? "";
        const layoutSections = selectedMenu.layout ?? [];
        console.log("restaurant :", restaurant);
        return (<Screen className="screen menu-quid-screen">
                    <RestaurantHeader className='full-width block' restaurant={restaurant} menuName={LocalizerHelper.localized(selectedMenu, 'name') ?? ''} showCart={true} onClickCart={_proceedWithOrder}/>
                    <div className="container container_topbar">
                        <div className="row row-scroll"  id="container_row_id">
                            {_paintTopBarMenu()}
                        </div>    
                    </div>
                    <div className="menu-main-container" ref={elementRef}>
                        {_paintMenuItemList()}
                    </div>
                    {canBeOrdered ? null
                         :
                    <div className="sticky-footer row">
                        <p className='col-12'>{LocalizerHelper.localized('generic_actions')}</p>
                        <div className="btn btn-continue-web col-5 center">
                            <a target="_blank" href={`https://app.chuzeat.com?quid=${quid}`}>
                                {LocalizerHelper.localized('continue_web')}
                            </a>
                        </div>
                        <div className="btn btn-download-app col-6 center">
                            <a target="_blank" href={`https://chuze.at/t/${quid}?redirectToStore=true`}>
                                <img  src="/assets/icon_download.svg" alt="download"/>
                                {LocalizerHelper.localized('download_app')}
                            </a>
                        </div>
                    </div>  }
                    {openDetail ?
                        <BottomSheet className="bottom-sheet-detail" open={openDetail} showClose={true} onClose={_closeMenuItemDetail} radius={0}>
                            <MenuItemDetail menuItem={selectedMenuItem}
                                            canAdd={canAdd}
                                            readonly = {!canBeOrdered}
                                            addItem={(menuItem, quantity, addOns, minus, notes) => _addItemToDits(menuItem, quantity, addOns, minus, notes)}
                                            quid={quid}
                                            canBeOrdered={canBeOrdered}
                                            />
                        </BottomSheet> : null
                    }
                    { canBeOrdered && _canShowBottomSheet ?
                        <BottomSheetOrderRecap goToNextLabel={LocalizerHelper.localized('proceed_with_order')}
                                            goToNext={_proceedWithOrder} 
                                            disableAction={!_canProceedWithOrder}
                                            disableLabel={_isMajorThanMinimumPrice ? null : `${LocalizerHelper.localized('minimum_order_amount')} ${formatPrice(_minimumOrderAmount)}`}
                                            addDeliveryCosts = {false}
                                            />
                        : null
                    }
                    
            </Screen>)
    }

    return _paintLoadingMenu();

    
   }


   return (_paintMenuScreen())
};

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    tableName: state.link.tableName ?? state.tableName ?? null,
    menu: state.menu.menu ?? {},
    menuItems: state.menu.menuItems ?? [],
    allMenuItems: state.menu.allMenuItems ?? [],
    dits: state.session.session ?? null,
    firestoreUser: state.auth.firestoreUser ?? null,
    ditsMenuItems: state.session?.ditsMenuItems,
    loading: state.session.loading,
    bill: state.bill?.bill ?? null,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: (parameter: any) => any) => ({
    listenForDitsMenuItems: listenForSessionMenuItems(dispatch),
    addItemsToDits: addItemsToSession(dispatch),
    retrieveMenu: async (restaurantID: string, menuID: string) => dispatch(await getMenu(restaurantID, menuID)),
    retrieveMenuItems: async (restaurantID: string, menuID: string) => dispatch(await getMenuItems(restaurantID, menuID)),
});

const Menu = connect(mapStateToProps, mapDispatchToProps)(_Menu);

export default Menu;
