import {combineReducers} from "redux";
import {restaurant} from "./restaurant.reducer";
import {menu} from "./menu.reducer";
import {session} from "./session.reducer";
import {auth} from "./auth.reducer";
import {link} from "./link.reducer";
import {availability} from "./availability.reducer";
import {ditsMenuItem} from "./menu_item.reducer";
import {bill} from "./bill.reducer";
import {payment} from "./payment.reducer";

const rootReducer = combineReducers({
    restaurant,
    menu,
    menuItem: ditsMenuItem,
    session,
    auth,
    link,
    availability,
    bill,
    payment
});

export default rootReducer;

