import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';

console.log("IMPORT FIREBASE CONFIG");

//authDomain: "login.chuzeat.app",

const firebaseConfig = {
    apiKey: "AIzaSyBxm1o3BSTPYP-uK0yYN2elrqKeoKQUnD8",
    authDomain: "chuzeat.app",
    databaseURL: "https://chuzeat-c-dev.firebaseio.com",
    projectId: "chuzeat-c-dev",
    storageBucket: "chuzeat-c-dev.appspot.com",
    messagingSenderId: "973255347351",
    appId: "1:973255347351:web:ffea17dfa98ebffb2d2dd2",
    measurementId: "G-VMSN22M8TM"
  };
  // Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
firebase.remoteConfig().fetchAndActivate();

export default firebase;