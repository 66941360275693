import {IAvailability} from "../../models";
import {LinearProgress, Typography} from "@material-ui/core";
import DateTimeHelper from "../../helpers/dateTimeHelper";
import * as React from "react";
import {useAvailabilityStyles} from "../../helpers/styleHelper";

import './index.scss';
import {DELIVERY_MODES} from "../../constants/data";
import {LocalizerHelper} from "../../helpers/localizerHelper";


const AvailabilitySelectableBox = ({availability, ditsMenuItemsWeight, deliveryMode, selectTimeSlot, selected}: { availability: IAvailability, ditsMenuItemsWeight: number, deliveryMode: string,  selectTimeSlot: ((availability: IAvailability) => any) | null, selected: boolean }) => {

    const styles = useAvailabilityStyles();
    const toShowPercentage = deliveryMode === DELIVERY_MODES.inPlace ? availability.takeAwayPercentage : (availability.takeAwayPercentage > availability.deliveryPercentage ? availability.takeAwayPercentage : availability.deliveryPercentage);
    const notAvailable = ditsMenuItemsWeight > availability.remainingTakeAwaySlots || toShowPercentage >= 100;
    return (
        <div
            id={`availability-box-${availability.timeStart.getTime()}`} 
            className={`col-6 col-md-3 availability-box ${selected ? 'selected' : ''} ${notAvailable ? 'not-available' : ''}`}
             onClick={() => notAvailable ? () => {} : !!selectTimeSlot ? selectTimeSlot(availability) : null}>
            <div className="row" >
                <div className="col-12 text-left">
                    <Typography variant="subtitle1" gutterBottom align="left" className="availability-day">
                        {DateTimeHelper.formatToRelativeCalendar(availability.timeStart)}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Typography variant="subtitle2" gutterBottom align="left" className="availability-hours">
                        {DateTimeHelper.formatTime(availability.timeStart)} - {DateTimeHelper.formatTime(availability.timeEnd)}
                    </Typography>
                </div>
            </div>
            {!notAvailable ?
                <div className="row">
                    <div className="col-12">
                        <LinearProgress variant="determinate" value={toShowPercentage}
                                        classes={{colorPrimary: styles.colorPrimary, bar: styles.bar}} color="primary"/>
                    </div>
                </div> :
                <div className="not-available-overlay">
                    <p>{LocalizerHelper.localized('not_available')}</p>
                </div>
            }
        </div>);
};

export default AvailabilitySelectableBox;
