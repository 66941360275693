import firebase from "firebase";
import {PAYMENT_TYPES} from "../constants/data";
import {requestBill} from "../helpers/apiHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {BILL_CONSTANTS} from "../constants/bill.constants";
import {FirestoreHelper} from "../helpers/firestoreHelper";
import DocumentReference = firebase.firestore.DocumentReference;

const requestSessionBill = async (restaurantID: string, sessionID: string) => {
    const type = PAYMENT_TYPES.all;
    try {
        const response = await requestBill(restaurantID, sessionID, type);
        return buildActionResult(BILL_CONSTANTS.REQUEST_BILL, {});
    } catch (error) {
        return buildActionResult(BILL_CONSTANTS.REQUEST_BILL_ERROR, {
            error
        });
    }
}

const getBill = async (restaurantID: string, billID: string) => {
    try {
        const bill = await FirestoreHelper.billByID(restaurantID, billID);
        console.log('bill', bill);
        return buildActionResult(BILL_CONSTANTS.GET_BILL, {
            bill,
        });
    } catch (error) {
        console.log('@@@@@error retrieving bill');
        console.log(error);
        return buildActionResult(BILL_CONSTANTS.GET_BILL_ERROR, {
            error
        });
    }
}

const listenForBillLogs = (dispatch: any) => (ditsRef: DocumentReference) => {
    FirestoreHelper.listenForDitsBillLog(ditsRef)?.onSnapshot(snapshot => {
       if (!!snapshot && !snapshot.empty) {
           const firstBill = snapshot.docs[0];
           dispatch(buildActionResult(BILL_CONSTANTS.UPDATE_BILL, {
               bill: firstBill
           }));
       }
    });
}

export {
    requestSessionBill,
    listenForBillLogs,
    getBill
}
