import * as React from 'react';
import {connect} from "react-redux";
import {PropsWithChildren} from "react";
import {validateQuid} from "../../actions/link.actions";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import ChuzeatFooter from "../footer/ChuzeatFooter";
import {LoaderGif} from '../loader';
import Screen from '../screen';
import './index.scss';

const useEffect = React.useEffect;

interface IQuidScreenProps extends PropsWithChildren<any> {
    restaurant: any | null,
    menus:any[],
    validateLink: (token: string) => any,
}

const MenuChooser = ({menus, onMenuSelected}: {
    menus: any[],
    onMenuSelected: (menu: any) => any
}) => (
    <div className="container">
        {menus.map((item: any) => (<div className="row" key={item.ref} >
            <div className="col-md-12">
                <Button className="accent-button full-width button" onClick={() => onMenuSelected(item)}>
                    {LocalizerHelper.localized(item, 'name')}
                </Button>
            </div>
        </div>))}
    </div>
);

const _QuidScreen = ({
    match,
    restaurant,
    quidFetched,
    menus = [],
    url,
    validateQuid,
}: IQuidScreenProps) => {

    const history = useHistory();
    const [initialLoading, setInitialLoading] = React.useState(true);
    const _hasMultipleMenus = () => !!menus && menus.length > 1;
    const quid = match?.params?.quid;
    
    useEffect(() => {
        const quid = match?.params?.quid;
        console.log(`@@@@@ quid [${quid}]`);
        if (!!quid) {
            //save alias on localstorage
            if(window.localStorage){
                window.localStorage.setItem('chuzeat_last_quid',quid);
            }
            console.log("@@@@@start validateQuid");
            validateQuid(quid);
        }
    }, []);
    
    useEffect(() => {
        if(!!quidFetched){
            console.log(`useEffect quidFetched [${quid}] [${quidFetched}], menus [${menus}]`);
            console.log(menus);
            setInitialLoading(false);
            if(menus && menus.length == 1){
                _goToMenu(menus[0]);
            }
        }

    }, [quidFetched]);

    useEffect(() => {
        console.log("&&&&");
        console.log(`${JSON.stringify(url)}`);
        console.log("&&&&");
        if(!!url && url.length > 0){
            window.location.replace(url);
        }
    }, [url]);

    const _goToMenu = (menu:any) => {
        const url = `/quid/${quid}/${restaurant.id}/menu/${menu.id}`;
        console.log("@@@@@@ _goToMenu");
        console.log(menu);
        console.log(url);
        history.push({pathname:url,state:menu});
    }

    const paintRestaurantScreen = () => {
        return (
            <div>
            <Screen className="screen restaurant-screen">
                <RestaurantHeader restaurant={restaurant} className='full-width' showBack={false} showCart={false}/>
                <div className="content_container with_footer">
                    {_hasMultipleMenus() ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p className="user_questions">
                                            {LocalizerHelper.localized('link_chooser_header')}
                                        </p>
                                    </div>
                                </div>
                                <div className="container-buttons-action">
                                    <MenuChooser menus={menus} onMenuSelected={(menu) => _goToMenu(menu)}/>
                                </div>
                            </div> :
                            <div className='container'>
                                {(quidFetched && (menus == null || menus.length === 0) && !(!!url && url.length > 0)) ? 
                                    <p className="user_questions">{LocalizerHelper.localized('no_dining_tables_availables')}</p> : null
                                }
                            </div>
                    }
                </div>
                <ChuzeatFooter/>
            </Screen>
            {initialLoading ?  <LoaderGif  logo={null} image="/assets/loading_restaurant.gif" message={LocalizerHelper.localized('loading_msg_retrieve_services')} /> : null}
            </div>
        );
    }

    return (
         paintRestaurantScreen()
    );
};

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    quidFetched: state.link.quidFetched ?? false,
    menus: state.link.menus ?? [],
    url: state.link.url,
});

const mapDispatchToProps = (dispatch: (param: any) => any) => ({
    validateQuid: async (token: string) => dispatch(await validateQuid(token)),
});

const QuidScreen = connect(mapStateToProps, mapDispatchToProps)(_QuidScreen);

export default QuidScreen;
