import {MENU_CONSTANTS, MenuActions} from "../constants/menu.constants";

export function menu(state = {}, action  : {
    type: MenuActions,
    params: object[]
}) {
    switch (action.type) {
        case MENU_CONSTANTS.RETRIEVE_MENUS:
        case MENU_CONSTANTS.RETRIEVE_MENU:
        case MENU_CONSTANTS.RETRIEVE_MENU_ITEMS:
        case MENU_CONSTANTS.RETRIEVE_MENU_ITEM:
            return {
                ...state,
                ...action,
                error: null
            };
        case MENU_CONSTANTS.RETRIEVE_MENUS_ERROR:
            return {
                ...state,
                ...action
            }
        default:
            return state;
    }
}
