const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const checkIfNotExists = (value: any) => (value !== null && value !== undefined);
const checkIfNotEmpty = (value: any) => (checkIfNotExists(value) && value.trim().length > 0);
const checkValidEmail = (value: any) => checkIfNotEmpty(value) && emailPattern.test(value);

export {
    checkIfNotExists,
    checkIfNotEmpty,
    checkValidEmail
}
