import firebase from "firebase";
import {MD5} from "crypto-js"
import {formatPrice} from "./formatHelper";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

const evaluateDitsMenuItemPrice = (ditsMenuItem: DocumentSnapshot, qty: number = 1) => {
    const defaultPrice = ditsMenuItem?.data()?.price;
    const addOnsPrice = evaluateAddOnsPrice(ditsMenuItem?.data()?.add_ons ?? [])
    return (defaultPrice + addOnsPrice) * qty;
}

const evaluateAddOnsPrice = (addOns: any[]) => {
    return (addOns ?? []).reduce((acc: number, addOn: any) => {
        const addOnPrice = (addOn?.options ?? []).reduce((price: number, option: any) => price += option.price ?? 0, 0)
        return acc += addOnPrice;
    }, 0);
}

const formatDitsMenuItemPrice = (ditsMenuItem: DocumentSnapshot|null, qty: number = 1) => {
    if (!!ditsMenuItem) {
        return formatPrice(evaluateDitsMenuItemPrice(ditsMenuItem, qty));
    }
    return formatPrice(0);
}


const groupSameItems = (ditsMenuItems: DocumentSnapshot[]) => {
    const mapGroupedItems: any = {};
    for (const index in ditsMenuItems) {
        const item = ditsMenuItems[index];
        if (!!item && item.exists) {
            const itemData = item.data() ?? {};
            const addOnsResult = (itemData?.add_ons ?? []).map((e: any) => (e?.options ?? []).map((item: any) => item?.id?.path ?? '').join(',')).join(',');
            const minusResult = (itemData?.minus ?? []).map((e:any) => e?.id?.path).join(',');
            let key = itemData.name
                + (itemData.salesModel != null ? itemData.salesModel : '')
                + itemData.saleComment
                + itemData.price;
                key += (itemData?.menuItem?.path() ?? '')
                    + itemData?.course
                    + addOnsResult
                    + minusResult
                    + (itemData.note ?? '');
            const hashedKey = MD5(key).toString();
            if (!!mapGroupedItems && !!mapGroupedItems[hashedKey]) {
                mapGroupedItems[hashedKey]['qty'] += 1;
                mapGroupedItems[hashedKey]['items'].push(item)
            } else {
                mapGroupedItems[hashedKey] = {
                    item,
                    key: hashedKey,
                    items: [item],
                    'qty': 1,
                    'order': index
                };
            }
        }
    }
    const groupedItems = Object.values(mapGroupedItems).sort((a: any, b: any) => a['order'] - b['order']);
    return groupedItems;
}

const onDeleteItems = (groupedItems: any[], key: string, deleteFunction: (items: DocumentSnapshot[]) => any) => {
    const groupItem = groupedItems.find((item: any) => item.key === key);
    if (!!groupItem && !!groupItem.items && groupItem.items.length > 0) {
        if (!!deleteFunction) {
            deleteFunction([groupItem.items[groupItem.items.length - 1]]);
        }
    }
}

export {
    evaluateDitsMenuItemPrice,
    evaluateAddOnsPrice,
    formatDitsMenuItemPrice,
    groupSameItems,
    onDeleteItems
}
