import * as React from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import './index.scss';

const ConfirmDialog = ({open, body, handleClose, handleConfirm}: {open: boolean, body: string, handleClose: () => any, handleConfirm: () => any}) => (
    <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        classes={{
            container: 'error-dialog'
        }}
        aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <span className="dialog-title">{LocalizerHelper.localized('confirm')}</span>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {body}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button className="close-button" onClick={handleClose} color="primary">
                {LocalizerHelper.localized('cancel')}
            </Button>
            <Button className="close-button" onClick={handleConfirm} color="primary">
                {LocalizerHelper.localized('confirm')}
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmDialog;
