import {AVAILABILITY_CONSTANTS, AvailabilityActions} from "../constants/availability.constants";

export function availability(state = {}, action: {
    type: AvailabilityActions,
    params: object[]
}) {
    switch (action.type) {
        case AVAILABILITY_CONSTANTS.UPDATE_AVAILABILITY_SLOTS:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
}
