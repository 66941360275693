import * as React from "react";
import {connect} from "react-redux";
import {Button, InputProps, TextField, Typography} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {checkIfNotEmpty, checkIfNotExists} from "../../helpers/validatorHelper";
import {useHistory} from "react-router";
import firebase from "firebase";
import {addInfoDetailsToSession, getSession, listenForSession} from "../../actions/session.actions";
import {getMenus} from "../../actions/menu.actions";
import {useEffect} from "react";
import {formatPrice} from "../../helpers/formatHelper";
import PlacesAutocomplete, {geocodeByAddress, Suggestion} from "react-places-autocomplete";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import Screen from '../screen';
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentReference = firebase.firestore.DocumentReference;
import { NormalModuleReplacementPlugin } from "webpack";
import { PropsWithChildren } from "react";
import { getRestaurant } from "../../actions/restaurant.actions";

interface Coords {
    lat: number,
    lng: number
}

enum DeliveryMeasureType {
    KM= 'km',
    DEFAULT= 'fixed',
}

interface ILocationServiceAvailebleProps extends PropsWithChildren<any> {
    restaurant: any,
    menus: DocumentSnapshot[],
    dits: DocumentSnapshot,
    linkAlias:string | null,
    retrieveRestaurant: (restaurantID: string) => any,
    retrieveSession: (restaurantID: string, ditsID: string) => any,
    listenForDits: (ditsRef: DocumentReference) => any,
    addDeliveryDetailsToDits: (dits: DocumentReference, data: any) => any,
    retrieveMenus: (restaurantID: string) => any
}

let unsubscribeFromListenForDits: firebase.Unsubscribe | null = null;

const _LocationServiceAvailable = ({
    match,
    restaurant,
    menus,
    dits,
    linkAlias,
    retrieveRestaurant,
    retrieveSession,
    listenForDits,
    addDeliveryDetailsToDits,
    retrieveMenus
}: ILocationServiceAvailebleProps) => {

    const history = useHistory();

    const [address, setAddress] = React.useState('');

    const [deliveryCost, setDeliveryCost] = React.useState<number>(0);
    const [distance, setDistance] = React.useState<number>(0);
    const [type, setType] = React.useState<DeliveryMeasureType>(DeliveryMeasureType.DEFAULT);

    const [coords1, setCoords1] = React.useState<Coords>({lat:0, lng:0});
    const [coords2, setCoords2] = React.useState<Coords>({lat:0, lng:0});

    const [canDeliver, setCanDeliver] = React.useState(false);

    const [firstAttempt, setFirstAttempt] = React.useState(true);

    const [error, setError] = React.useState({
        address: !checkIfNotEmpty(address),
    });

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';

    useEffect(() => {
        _reload();
        return () => {
            if (!!unsubscribeFromListenForDits) {
                unsubscribeFromListenForDits();
                unsubscribeFromListenForDits = null;
            }
        }
    }, []);

    const _reload = (rID = match?.params?.restaurant, dID = match?.params?.dits) => {
        if (!restaurant || !dits) {
            retrieveRestaurant(rID);
            retrieveSession(rID, dID);
        }
    }

    useEffect(() => {
        if (!!restaurant) {
            retrieveMenus(restaurant.id);
        }
        setDeliveryCost(restaurant?.data?.delivery_cost ?? 0);
    }, [restaurant]);

    useEffect(() => {
        console.log(dits);
        if (dits && typeof dits.data == 'function') {
            const hasAddress = !!dits?.data()?.delivery_details?.address;
            const address = dits?.data()?.delivery_details?.address;

            if (hasAddress) {
                onSelectAddress(address);
            }
            if (!checkIfNotExists(unsubscribeFromListenForDits)) {
                unsubscribeFromListenForDits = listenForDits(dits?.ref);
            }
        }
    }, [dits]);

    const _onClickBack = () => {
        const alias = linkAlias ?? lastLinkAlias;
        history.replace(`/menu/${alias}`,{});
        history.go(0);
    }

    const setAddressValue = (value: string) => {
        const _error = {...error};
        setAddress(value);
        _error.address = !checkIfNotEmpty(value ?? address);
        setError(_error);
    }

    const onChangeAddress = async (address: string) => {
        setCanDeliver(false);
        setAddressValue(address);
    }

    const onSelectAddress = async(address: string, placeID?: string) => {
        console.log("placeID", placeID);
        /*if(placeID==null){
            return;
        }*/
        setCanDeliver(false);
        setAddressValue(address);
        const results = await geocodeByAddress(address);
        if (!!results && results.length > 0) {
            const geometry = results[0].geometry;
            const coords = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
            };
            const location1 = new google.maps.LatLng(coords.lat, coords.lng);
            setCoords1(coords);
            const location2 = new google.maps.LatLng(restaurant?.data?.coords.lat, restaurant?.data?.coords.lng);
            setCoords2({lat: restaurant?.data?.coords.lat, lng: restaurant?.data?.coords.lng});
            let directionsService = new google.maps.DirectionsService();
            //let directionsRenderer = new google.maps.DirectionsRenderer();
            console.log("location1", location1.lat(), location1.lng())
            console.log("location2", location2.lat(), location2.lng())
            directionsService.route({
                origin: location1,
                destination: location2,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            }, function (response: google.maps.DirectionsResult, status) {
                if (status !== 'OK') {
                    window.alert('Directions request failed due to ' + status);
                } else {
                    console.log('directionsDatas', response.routes);
                    var directionsData = response.routes[0].legs[0];
                    console.log('directionsData', directionsData);
                    const distance: number = directionsData?.distance.value / 1000 ?? 0;
                    console.log('distance', distance);
                    const unitDeliveryCost: number = restaurant?.data?.delivery_cost ?? 0;
                    console.log('unitDeliveryCost', unitDeliveryCost);
                    const res: boolean = (distance <= (restaurant?.data?.max_delivery_distance ?? 0));
                    console.log('CanDeliver', res);
                    const deliveryMeasureType: DeliveryMeasureType = restaurant?.data?.delivery_measure_type ?? DeliveryMeasureType.DEFAULT;
                    console.log('deliveryMeasureType', deliveryMeasureType);
                    const deliveryMeasureCost: number = restaurant?.data?.delivery_measure_cost ?? 0;
                    console.log('deliveryMeasureCost', deliveryMeasureCost);
                    const maxRangeDefault: number = restaurant?.data?.min_range_free ?? 0;
                    console.log('maxRangeDefault', maxRangeDefault);
                    let computedCost:number;

                    if(distance < maxRangeDefault){
                        computedCost = unitDeliveryCost;
                    }else{
                        switch(deliveryMeasureType){
                            case DeliveryMeasureType.KM:
                                computedCost = Math.ceil(distance - maxRangeDefault) * deliveryMeasureCost + unitDeliveryCost;
                                break;
                            case DeliveryMeasureType.DEFAULT:
                                computedCost = unitDeliveryCost;
                                break;
                        }
                    }
                    setType(deliveryMeasureType);
                    setDistance(distance);
                    setCanDeliver(res);
                    setFirstAttempt(false);
                    if(res){
                        setDeliveryCost(computedCost);
                    }
                }
            })
        }
    }

    const onAutoCompleteError = (error: string) => {
        console.log('@@@@@@error');
        console.log(error);
    }

    const _hasMenus = () => !!menus && menus.length > 0;

    const _hasSingleMenus = () => _hasMenus() && menus.length === 1;

    const _goToMenu = (menu: DocumentSnapshot) => {
        const alias = linkAlias ?? lastLinkAlias;
        history.push(`/menu/${alias}/menu/${restaurant.id}/${menu.id}`);
    }

    const _proceedWithOrder = () => {
        if (!!dits) {
            addDeliveryDetailsToDits(dits?.ref, {
                distance,
                delivery_measure_type: type,
                address,
                preview_delivery_cost: +deliveryCost.toFixed(2),
                location1: coords1,
                location2: coords2
            });
            if (_hasMenus()) {
                if (_hasSingleMenus()) {
                    _goToMenu(menus[0]);
                } else {
                    const alias = linkAlias ?? lastLinkAlias;
                    history.push(`/menu/${alias}/menu-chooser`);
                    //history.push('/menu-chooser');
                }
            }
        }
    }

    const _renderDetailDelivery = () => {
        console.log("@@@ _renderDetailDelivery");
        console.log(restaurant);
        if(restaurant == null || restaurant.data == null) return null;
        const data = restaurant.data;
        const address = data.address;
        const max_delivery_distance = data.max_delivery_distance;
        const price = data.delivery_cost ?? 0;
        const priceText =  formatPrice(price);
        const freeDeliveryMinimumAmount = data.free_delivery_minimum_amount ?? 0;
        const priceFreeDelivery = formatPrice(freeDeliveryMinimumAmount);
        const maxRangeDefault: number = restaurant?.data?.min_range_free ?? 0; 
        const unitDeliveryCost: number = restaurant?.data?.delivery_cost ?? 0;
        const deliveryMeasureType: DeliveryMeasureType = restaurant?.data?.delivery_measure_type ?? DeliveryMeasureType.DEFAULT;
        const deliveryMeasureCost: number = restaurant?.data?.delivery_measure_cost ?? 0;
        //const deliveryMessage = LocalizerHelper.localized(data, 'delivery_message') ?? data.delivery_message ?? null;
        let deliveryMessage: string = "";
        if(deliveryMeasureType != DeliveryMeasureType.DEFAULT){
            deliveryMessage = `${formatPrice(unitDeliveryCost)} + ${formatPrice(deliveryMeasureCost)} ${LocalizerHelper.localized('at')} ${deliveryMeasureType.toString()} ${LocalizerHelper.localized('to_deliver_over')} ${maxRangeDefault} km`
        }

        console.log(`@@@@ devliveryMessage: [${LocalizerHelper.localized(data, 'delivery_message') }]`);
        console.log(`@@@@ devliveryMessage: [${deliveryMessage}]`);
        
        return (<div className="container container-info-delivery">
                {max_delivery_distance > 0 &&
                <div className="row">
                    <div className="col-6 text-left label">{LocalizerHelper.localized('radius_delivery')}</div>
                    <div className="col-6 text-left">{max_delivery_distance} Km {LocalizerHelper.localized('address_from')} {address}</div>
                </div> }

                {(!!deliveryMessage && deliveryMessage.trim()!== "") &&
                    (<div className="row">
                      <div className="col-6 text-left label">{LocalizerHelper.localized('delivery_message')}</div>
                        <div className="col-6 text-left">{deliveryMessage} <i> {LocalizerHelper.localized('or_fractions')}</i></div>
                    </div>)
                }

                <div className="row">
                    <div className="col-6 text-left label">{LocalizerHelper.localized('delivery_cost')}</div>
                    <div className="col-6 text-left">{formatPrice(deliveryCost)}</div>
                </div>

                {price>0 && freeDeliveryMinimumAmount > 0 &&
                    <div className="row">
                        <div className="col-6 text-left label">{LocalizerHelper.localized('bottom_order_recap_free_delivery_minimum_amount')}</div>
                        <div className="col-6 text-left">{priceFreeDelivery}</div>
                    </div>
                }
            </div>);
    }

    const renderFunction =  ({ getInputProps, getSuggestionItemProps, suggestions }: any) => (
        <div className="screen_location_service">
            <RestaurantHeader restaurant={restaurant} className='block' onClickBack={_onClickBack}/>
            <div className="container">
                <div className="user_questions">{LocalizerHelper.localized('location_service_available_header')}</div>
                {_renderDetailDelivery()}
                <div className="row container-input-service_available">
                    <div className="container-suggestion-label-service_available">
                        {firstAttempt && 
                            <div className="col-md-12">
                                {LocalizerHelper.localized('select_address_from_suggestions')}
                            </div>
                        }
                        {!firstAttempt && !canDeliver && 
                            <div className="col-md-12" style={{color: "#FA453B", fontWeight: "bold"}}>
                                {LocalizerHelper.localized('restaurant_not_deliver_at_this_address')}
                            </div>
                        }
                    </div>
                    <div className="col-md-12">
                        <TextField
                            {...getInputProps()}
                            variant="outlined"
                            name="address"
                            error={error.address}
                            fullWidth={true}
                            helperText={LocalizerHelper.localized('field_required')}
                            label={LocalizerHelper.localized('address')}>

                        </TextField>
                    </div>
                    <div className="col-md-12">
                        <div className={`container-autocomplete-service_available ${(suggestions && suggestions.length > 0) ? 'open' : ''}`}>
                            <div className="autocomplete-dropdown">
                                {suggestions.map((suggestion: any, index: number) => (
                                    <div {...getSuggestionItemProps(suggestion)} key={`suggestion-${index}`} className="address-suggestion">
                                        {suggestion.description}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            <div className="row container-buttons-action">
                <div className="col-md-12">
                    <Button className='accent-button full-width button button' disabled={!canDeliver}
                            onClick={() => _proceedWithOrder()}>
                        {LocalizerHelper.localized('confirm')}
                    </Button>
                </div>
            </div>
        </div>
        </div>
    );

    return (<Screen className="screen">
        {window.google && <PlacesAutocomplete
            value={address}
            onSelect={onSelectAddress}
            onChange={onChangeAddress}
            onError={onAutoCompleteError}>
            {renderFunction}
        </PlacesAutocomplete>}
    </Screen>);
};

const mapStateToProps = (state: any)  => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    menus: state.menu.menus ?? [],
    dits: state.session.session ?? NormalModuleReplacementPlugin,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDits: listenForSession(dispatch),
    retrieveRestaurant: async (restaurantID: string) => dispatch(await getRestaurant(restaurantID)),
    retrieveSession: async (restaurantID: string, ditsID: string) => dispatch(await getSession(restaurantID, ditsID)),
    addDeliveryDetailsToDits: async (ditsRef: DocumentReference, deliveryDetails: any) => dispatch(await addInfoDetailsToSession(ditsRef, deliveryDetails)),
    retrieveMenus: async (id: string) => dispatch(await getMenus(id))
});

const LocationServiceAvailable = connect(mapStateToProps, mapDispatchToProps)(_LocationServiceAvailable);

export default LocationServiceAvailable;
