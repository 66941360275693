import {RESTAURANT_CONSTANTS, RestaurantActions} from "../constants/restaurant.constants";

export function restaurant(state = {}, action: {
    type: RestaurantActions,
    params: object[]
}) {
    switch (action.type) {
        case RESTAURANT_CONSTANTS.RETRIEVE_RESTAURANT:
            return {
            ...state,
            ...action
        };
        default:
            return state;
    }
}
