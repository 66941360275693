import firebase from "firebase";
import {BILL_STATUS, COLLECTIONS, DITS_STATUS, MENU_STATUS} from "../constants/data";
import { TimeSlot } from "../models";
import Query = firebase.firestore.Query;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentData = firebase.firestore.DocumentData;
import Timestamp = firebase.firestore.Timestamp;
import FieldValue = firebase.firestore.FieldValue;
import firestore = firebase.firestore;

interface ISortCondition {
    field: string,
    direction?: "asc" | "desc"
}

interface IQueryCondition {
    field: string,
    type: WhereFilterOp,
    value: any
}

export class FirestoreHelper {
    //region RESTAURANTS
    static get restaurants() {
        return firebase.firestore().collection(COLLECTIONS.restaurants);
    }

    static restaurantRef(id: string) {
        return this.getRef(this.restaurants.path, id);
    }

    static async restaurant(id: string) {
        return this.restaurantRef(id).get();
    }

    //endregion
    //region MENUS
    static async menus(restaurantID: string) {
        try {
            return await this.addSortConditionsToQuery(
                this.restaurantRef(restaurantID).collection(COLLECTIONS.menu),
                [{
                    field: 'name',
                }], [{
                    field: 'status',
                    type: 'in',
                    value: [MENU_STATUS.external, MENU_STATUS.live]
                }, {
                    field: 'used_for_take_away_or_delivery',
                    type: '==',
                    value: true
                }]).get();
        } catch (error) {
            throw error;
        }
    }

    
    static async allMenusItemsFromLayout(restaurantID: string) {
        
        try {
            return await this.addSortConditionsToQuery(
                this.restaurantRef(restaurantID).collection(COLLECTIONS.menu)
            ).get();
        } catch (error) {
            throw error;
        }
     }

    static async allMenus(restaurantID: string) {
       try {
           return await this.addSortConditionsToQuery(
               this.restaurantRef(restaurantID).collection(COLLECTIONS.menu)
           ).get();
       } catch (error) {
           throw error;
       }
    }

    static async menu(restaurantID: string, menuID: string) {
        try {
            return await this.restaurantRef(restaurantID).collection(COLLECTIONS.menu)
                .doc(menuID)
                .get();
        } catch (error) {
            throw error;
        }
    }

    static async menuItems(restaurantID: string, menuID: string) {
        try {
            return await this.restaurantRef(restaurantID).collection(COLLECTIONS.menu)
                .doc(menuID).collection(COLLECTIONS.menuItems)
                .get();
        } catch (error) {
            throw error;
        }
    }

    static async menuItem(restaurantID: string, menuID: string, menuItemID: string) {
        try {
            return await firebase.firestore().collection(COLLECTIONS.restaurants)
                .doc(restaurantID)
                .collection(COLLECTIONS.menu)
                .doc(menuID)
                .collection(COLLECTIONS.menuItems)
                .doc(menuItemID)
                .get();
        } catch (error) {
            throw error;
        }
    }

    static async cookingIngredientsForMenuItem(menuItemRef: DocumentReference) {
        try {
            const query = await menuItemRef.collection(COLLECTIONS.cookingIngredients).get();
            if (!!query && !query.empty) {
                return query.docs;
            }
            return [];
        } catch (error) {
            throw error;
        }
    }

    //endregion
    //region DITS
    static ditsMenuItemSnapshots(ditsRef: DocumentReference) {
        try {
            return ditsRef?.collection(COLLECTIONS.menuItems)
                .orderBy('chosen_at', 'asc');
        } catch (error) {
            throw error;
        }
    }
    static async addItemsToSession(ditsRef: DocumentReference, ditsMenuItem: DocumentData, qty: number = 1) {
        try {
            const batch = firestore().batch();
            for (let i = 0; i < qty; i++) {
                batch.set(ditsRef?.collection(COLLECTIONS.menuItems).doc(), ditsMenuItem);
            }
            await batch.commit();
        } catch (error) {
            throw error;
        }
    }
    static async addItemToSession(ditsRef: DocumentReference, ditsMenuItem: DocumentData) {
        try {
            await ditsRef?.collection(COLLECTIONS.menuItems)
                .add(ditsMenuItem);
        } catch (error) {
            throw error;
        }
    }

    static async deleteItemFromSession(ditsMenuItem: DocumentReference) {
        try {
            await ditsMenuItem?.delete();
        } catch (error) {
            throw error;
        }
    }

    static async addPaymentMethodToSession(ditsRef: DocumentReference, paymethod: string | null) {
        try {
            await ditsRef?.update({
                payment_method:paymethod
            });
        } catch (error) {
            throw error;
        }
    }

    static async addDeliveryDetailsToDits(ditsRef: DocumentReference, deliveryDetails: any) {
        try {
            const doc:DocumentData = await ditsRef.get();
            await ditsRef?.update({
                delivery_details: {...doc.data()?.delivery_details, ...deliveryDetails}
            });
        } catch (error) {
            throw error;
        }
    }

    static async addTimeSlotToDits(ditsRef: DocumentReference, slot:TimeSlot | undefined | null) {
        try {
            await ditsRef?.update({
                time_slot: slot
            });
        } catch (error) {
            throw error;
        }
    }

    static async sessionByID(restaurantID: string, sessionID: string) {
        try {
            const session = await this.restaurantRef(restaurantID)
                .collection(COLLECTIONS.dineInTableSitting)
                .doc(sessionID)
                .get();
            return session;
        } catch (error) {
            throw error;
        }
    }

    static async billByID(restaurantID: string, billID: string) {
        try {
            const bill = await this.restaurantRef(restaurantID)
                .collection(COLLECTIONS.bills)
                .doc(billID)
                .get();
            return bill;
        } catch (error) {
            throw error;
        }
    }
    
    static async lastActiveSessionByUser(userID: string) {
        try {
            const user = await firebase.firestore()
                .collection(COLLECTIONS.users)
                .doc(userID)
                .get();
            const activeDineInTableSittings = user?.data()?.active_dine_in_table_sitting ?? [];
            const lastActiveDineInTableSitting = !!activeDineInTableSittings && activeDineInTableSittings.length > 0 ? await activeDineInTableSittings[activeDineInTableSittings.length - 1].get() : null;
            if (!!lastActiveDineInTableSitting) {
                return lastActiveDineInTableSitting?.data().status !== DITS_STATUS.finished && lastActiveDineInTableSitting?.data()?.status !== DITS_STATUS.paid ? lastActiveDineInTableSitting : null;
            }
            return null;
        } catch (error) {
            throw error;
        }
    }

    //endregion
    //region AUTH
    static async userProfile(userID: string) {
        try {
            return await firebase.firestore().collection(COLLECTIONS.users).doc(userID).get();
        } catch (error) {
            console.log(error);
        }
    }

    static async setUserData(userID: string, data: any) {
        try {
            if (!!data) {
                return await firebase.firestore().collection(COLLECTIONS.users)
                    .doc(userID)
                    .set(data, {
                        merge: true
                    });
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static async setLanguageToUser(userID: string, language = 'en') {
        try {
            const userSettings = await firebase.firestore().collection(COLLECTIONS.usersSettings)
                .doc(userID)
                .get();
            if (!!userSettings && userSettings.exists) {
                await userSettings?.ref?.update({
                    language
                });
            } else {
                await firebase.firestore().collection(COLLECTIONS.usersSettings)
                    .doc(userID)
                    .set({
                        language
                    })
            }
        } catch (error) {
            console.log(error);
        }
    }

    //endregion
    //region SLOTS
    static getTakeAwaySlots(restaurantID: string, timeStart: Timestamp, timeEnd: Timestamp) {
        return this.addSortConditionsToQuery(
            this.restaurantRef(restaurantID).collection(COLLECTIONS.takeAwayDeliverySlots),
            [{
                field: 'time_start',
                direction: 'asc'
            }], [{
                field: 'time_start',
                type: ">=",
                value: timeStart
            }])
    }
    //endregion
    //region BILLS
    static listenForDitsBillLog(ditsRef: DocumentReference) {
        try {
            return ditsRef.collection(COLLECTIONS.billLog)
                .where('status', '==', BILL_STATUS.authorized);
        } catch (error) {
            console.log(error);
        }
    }
    //endregion
    static getRefsFromPath(paths: string[]) {
        const refs = [];
        try {
            for (const path of paths) {
                refs.push(firebase.firestore()
                    .doc(path));
            }
            return refs;
        } catch (error) {
            console.log('@@@@@error getting refs from path');
            console.log(error);
            throw error;
        }
    }

    static async getSnapshot(path: string) {
        try {
            const snapshot = await firebase.firestore().doc(path)?.get();
            return snapshot;
        } catch (error) {
            throw error;
        }
    }
    static getRef(basePath: string, id: string) {
        try {
            const documentPath = `${basePath}/${id}`;
            return firebase.firestore().doc(documentPath);
        } catch (error) {
            throw error;
        }
    }

    static addSortConditionsToQuery(query: Query, sort: ISortCondition[] = [], where: IQueryCondition[] = []) {
        for (const condition of where) {
            query = query.where(condition.field, condition.type, condition.value);
        }
        for (const condition of sort) {
            query = query.orderBy(condition.field, condition.direction ?? "asc");
        }
        return query;
    }

    static streamMenuItem = (menuItemRef:string, observer:any) =>{
        return firebase.firestore().doc(menuItemRef).onSnapshot(observer);
    }


}

