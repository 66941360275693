import {LocalizerHelper} from "../../helpers/localizerHelper";
import {Card, CardContent, CardActions, Button, Typography,CircularProgress } from "@material-ui/core";
import * as React from "react";
import firebase from "firebase";
import {Close as IconRemove} from "@material-ui/icons";
import {formatDitsMenuItemPrice} from "../../helpers/ditsMenuItemHelper";
import {formatMenuItemPrice} from "../../helpers/menuItemHelper";
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {menu} from "../../reducers/menu.reducer";
import {FirestoreHelper} from "../../helpers/FirestoreHelper";
import {makeStyles} from "@material-ui/core/styles";
import {DELIVERY_MODES, DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS} from "../../constants/data";

const useEffect = React.useEffect;

const useCardStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px'
    }
}));

interface IMenuItemProps {
    menuItemRef: (DocumentSnapshot | string),
    openDetail: (item: DocumentSnapshot) => void
}

const MenuQuidItem = (data:IMenuItemProps) => {


    const [menuItem, setMenuItem] = React.useState<DocumentSnapshot | null>(null);
    const [docPath, setDocPath] = React.useState<string | null>(null);
    const cardClasses = useCardStyles();
    let _unsubscribeFirebase:any = null;


    useEffect(() => {
        const menuItemRef = data.menuItemRef;
        if(!!menuItemRef){
            console.log("@@@ menuItemRef");
            if(menuItemRef as string){
                const path:string = `${menuItemRef}`;
                console.log("@@@@ setDocPath");
                setDocPath(path);
            }

            if(menuItemRef as DocumentSnapshot){
                const docRef:DocumentSnapshot = (menuItemRef as DocumentSnapshot);
                console.log("@@@@ setMenuItem");
                setMenuItem(docRef);
            }
        }
        return () => {
                if(_unsubscribeFirebase && typeof _unsubscribeFirebase === 'function'){
                    console.log("_unsubscribeFirebase");
                    _unsubscribeFirebase();
                }
        }
    }, []);


    useEffect(() => {
        if(!!docPath){
            console.log("@@@@ subscripbe to firebase");
            _unsubscribeFirebase = FirestoreHelper.streamMenuItem(docPath, {
                next: (querySnapshot:any) => {
                    const _menuItem:DocumentSnapshot | null = querySnapshot;
                    _updateMenuItem(_menuItem);
                },
                error: (ex:any) => {
                    console.log("retrieve error");
                    console.log(ex);
                }
            });
            return _unsubscribeFirebase;
        }
    }, [docPath,setDocPath]);

    const _updateMenuItem = (mItem:DocumentSnapshot | null) => {
        console.log("@@ _updateMenuItem");
        setMenuItem(mItem);
    }

    const _paintLoadingMenuItem = () => {
        return (<Card className={cardClasses.root}>
                <CardContent>
                    <CircularProgress />
                </CardContent> 
               </Card> );
    }

    const _paintMenuItem = () => {

        if(!!menuItem && menuItem.exists){
            const isNotAvailable = menuItem?.data()?.status === DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS.na;
            const headerClassName = isNotAvailable ? 'menu-item-header not-available' : 'menu-item-header';
            const headerImage = (menuItem?.data()?.media && menuItem?.data()?.media.length>0 && menuItem?.data()?.media[0]?.url && menuItem?.data()?.media[0]?.url != '') ? menuItem?.data()?.media[0].url : null;
            
            return (<div key={`menu-item-${menuItem.id}`} className="menu-item">
                <Card onClick={() => data.openDetail(menuItem)} className={cardClasses.root}>
                    {headerImage ?
                            <div className="header_img_menu_list">
                                <img src={headerImage}/>
                            </div> : null}
                    <CardContent>
                        <div className="row">
                             <div className="col-8">
                                <Typography color="textSecondary" gutterBottom align="left" className={headerClassName}>
                                    {LocalizerHelper.localized(menuItem?.data(), 'name')}
                                </Typography>
                            </div>
                            <div className="col-4 text-right">
                                <Typography color="textSecondary" gutterBottom align="right" className={`price ${headerClassName}`}>
                                    { isNotAvailable ? 'TERMINATO' : formatMenuItemPrice(menuItem)}
                                </Typography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-left" style={{whiteSpace:"pre-line"}}>
                                {LocalizerHelper.localized(menuItem?.data(), 'description')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                {menuItem?.data()?.allergens?.map((item: string) => <img
                                    src={`/assets/icons/allergens/icon_allergen_${item.toLowerCase()}.png`}
                                    className={'allergen-icon'} key={`allergen-icon-${item}`}/>)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>);
        }

        return _paintLoadingMenuItem();
    }

    return (_paintMenuItem());
};


export default MenuQuidItem;
