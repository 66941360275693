import * as React from "react";
import {connect} from "react-redux";
import firebase from "firebase";
import {StyledFirebaseAuth} from "react-firebaseui";
import * as firebaseui from "firebaseui";
import {AUTH_ERROR} from "../../constants/auth.constants";
import {useHistory} from "react-router";
import {getUserProfile, setLanguageToUser, setUserData, showLogin} from "../../actions/auth.actions";
import {getSession, switchSessionToUser} from "../../actions/session.actions";
import {PropsWithChildren, useEffect} from "react";
import {getRestaurant} from "../../actions/restaurant.actions";
import Typography from "@material-ui/core/Typography";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {Button, TextField} from "@material-ui/core";
import {checkIfNotEmpty, checkValidEmail} from "../../helpers/validatorHelper";
import {DEFAULT_PRIVACY_URL, DEFAULT_TERMS_AND_CONDITION_URL, DELIVERY_MODES} from "../../constants/data";
import backgroundOval from './background_oval.png';
import signinLogo from './signin_logo.png';
import './index.scss';

import User = firebase.User;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

interface ISigninProps extends PropsWithChildren<any> {
    routeHistory: any | null,
    user: firebase.User | null,
    firestoreUser: DocumentSnapshot | null,
    restaurant: any,
    menu: DocumentSnapshot,
    dits: DocumentSnapshot,
    linkAlias:string | null,
    retrieveRestaurant: (restaurantID: string) => any,
    retrieveSession: (restaurantID: string, ditsID: string) => any,
    retrieveUserProfile: (user: User | null) => any,
    switchDitsToUser: (restaurantID: string, ditsID: string) => any,
    showLogin: (show: boolean, restaurantID?: string, ditsID?: string) => boolean,
    setLanguageToUser: (user: User, lang: string) => any,
    setUserData: (user: User, data: any) => any
}

const _Signin = ({
    routeHistory,
    restaurantID,
    ditsID,
    match,
    user,
    firestoreUser,
    restaurant,
    menu,
    dits,
    linkAlias,
    retrieveRestaurant,
    retrieveSession,
    retrieveUserProfile,
    switchDitsToUser,
    showLogin,
    setLanguageToUser,
    setUserData
}: ISigninProps) => {

    const [anonymousUser, setAnonymousUser] = React.useState(firebase.auth().currentUser);
    const [isAnonymousUser, setIsAnonymousUser] = React.useState(true);
    const [showUpdateEmailOrNickname, setShowUpdateEmailOrNickname] = React.useState(false);
    const [email, setUserEmail] = React.useState('');
    const [nickname, setUserNickname] = React.useState('');
    const [disableAction, setDisableAction] = React.useState(false);
    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const [uiConfig, setUiConfig] = React.useState<firebaseui.auth.Config>({
        //
        autoUpgradeAnonymousUsers: true,
        signInFlow: 'redirect',
        //signInFlow: 'popup',
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
            signInSuccessWithAuthResult(authResult: any, redirectUrl?: string): boolean {
                console.log("@@@@ signInSuccessWithAuthResult")
                console.log(authResult);
                console.log(redirectUrl);
                try {
                    retrieveUserProfile(firebase.auth().currentUser);
                } catch (error) {
                    console.log(error);
                }
                return true;
            },
            signInFailure(error: firebaseui.auth.AuthUIError): Promise<void> {
                console.log("@@@@ signInFailure")
                return _onSignInFailure(error)
            }
        },
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false
            }
        ]
    });

    const [error, setError] = React.useState({
        email: !checkIfNotEmpty(email),
        nickname: !checkIfNotEmpty(nickname)
    });

    const history = routeHistory ?? useHistory();


    useEffect(() => {
        const rID = restaurantID ?? match?.params?.restaurant;
        const dID = ditsID ?? match?.params?.dits;
        retrieveRestaurant(rID);
        retrieveSession(rID, dID);
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(fbUser => {
            console.log("@@@ firebase.auth().onAuthStateChanged");
            console.log(fbUser);
            if(!!fbUser && fbUser.isAnonymous){
                setIsAnonymousUser(true);
            }
          });
        return () => unregisterAuthObserver();
    }, []);

    useEffect(() => {

        console.log("Signin useEffect [firestoreUser] @@@@");
        console.log(firestoreUser);
        console.log(restaurant);
        console.log(dits);

        if (!!firestoreUser && !!restaurant && !!dits) {
            console.log("@@@ user?.isAnonymous Signin")
            console.log(user?.isAnonymous);
            setIsAnonymousUser(user?.isAnonymous ?? true);
            if (!!user && !user.isAnonymous) {
                console.log('@@@@@trying change language to user');
                setLanguageToUser(user, LocalizerHelper.getLanguage());
                switchDitsToUser(restaurant.id, dits.id);
            }
            const _userEmail = firestoreUser?.data()?.email ?? user?.email ?? '';
            const _userNickname = firestoreUser?.data()?.nickname ?? user?.displayName ?? '';
            const hasUserEmail = checkValidEmail(firestoreUser?.data()?.email);
            const hasUserNickname = checkIfNotEmpty(firestoreUser?.data()?.nickname);
            setUserEmail(_userEmail);
            setUserNickname(_userNickname);
            if (!hasUserEmail || !hasUserNickname) {
                if (!!user) {
                    if (checkIfNotEmpty(user?.email)) {
                        setUserData(user, {
                            email: user?.email,
                            is_anonymous: false
                        });
                    }
                }
                setShowUpdateEmailOrNickname(true);
                _validateData({
                    currentEmail: _userEmail,
                    currentNickname: _userNickname
                });
            } else {
                _proceedWithOrder();
            }
        }
    }, [firestoreUser]);

    const _setFieldValue = (key: string, value: string) => {
        switch (key) {
            case 'email':
                setUserEmail(value);
                _validateData({
                    currentEmail: value
                })
                break;
            case 'nickname':
                setUserNickname(value);
                _validateData({
                    currentNickname: value
                })
                break;
        }
    }

    const _validateData = ({
        currentEmail,
        currentNickname
}: {
        currentEmail?: string,
        currentNickname?: string
    }) => {
        const _error = {...error};
        _error.email = !checkValidEmail(currentEmail ?? email);
        _error.nickname = !checkIfNotEmpty(currentNickname ?? nickname);
        setDisableAction(_error.email || _error.nickname);
        setError(_error);
    }

    const _storeUserDataAndProceedWithOrder = () => {
        if (!!user) {
            setUserData(user, {
                email,
                nickname,
                is_anonymous: false
            });
            _proceedWithOrder();
        }
    }

    const _proceedWithOrder = () => {
        showLogin(false);

        const menuId = menu?.id ?? match?.params?.menu;
        console.log("@@@@ Signin _proceedWithOrder");
        const  requestUserData  = (dits?.data()?.service_type?.request_user_data ?? false);
        console.log("@@@@ Signin requestUserData");
        console.log(requestUserData);
        const isDelivery = (dits?.data()?.delivery_mode === DELIVERY_MODES.delivery);
        console.log("@@@@ Signin isDelivery");
        console.log(isDelivery);
        const alias = linkAlias ?? lastLinkAlias;
        console.log("@@@@ Signin alias");
        console.log(alias);

        if (isDelivery) {
            console.log("@@@ push to ");
            if(requestUserData){
                const url = `/menu/${alias}/info-details/delivery`;
                history.push(url);
            }else{
                const confirmOrderPath = `/menu/${alias}/confirm-order/${restaurant.id}/${menuId}/${dits.id}`;;
                history.push(confirmOrderPath);
            }
        } else {
            if(requestUserData){
                const url = `/menu/${alias}/info-details/takeaway`;
                history.push(url);
            }else{
                const confirmOrderPath = `/menu/${alias}/confirm-order/${restaurant.id}/${menuId}/${dits.id}`;;
                history.push(confirmOrderPath);
            }
        }
    }

    const _onSignInFailure = async (error: firebase.auth.AuthError) => {
        console.log('@@@@@@onSigninFailure');
        console.log(error);
        if (error.code === AUTH_ERROR.MERGE_CONFLICT) {
            try {
                if (!!error.credential) {
                    await firebase.auth().signInWithCredential(error.credential);
                    if (anonymousUser?.uid !== firebase.auth().currentUser?.uid) {
                        retrieveUserProfile(firebase.auth().currentUser);
                    }
                }
            } catch (error) {
                console.log("@@@@@@@@error");
                console.log(error);
            }
        }
    }


    const appConfig: any = JSON.parse(firebase?.remoteConfig()?.getString('m_app_config') ?? {});

    const privacyPolicyUrl = appConfig.privacy_policy_url ?? DEFAULT_PRIVACY_URL;
    const termsAndConditionUrl = appConfig.terms_and_condition_url ?? DEFAULT_TERMS_AND_CONDITION_URL;

    return (<div className="container page-container signin-container">
        <div className="signin-container-background">
            <img className="signin-background" src={backgroundOval}/>
            <div className="row signin-logo-container">
                <div className="col-12 text-center">
                    <img className='signin-logo' src={signinLogo}/>
                </div>
            </div>
        </div>
        <div className="signin-inner-container">
            {!showUpdateEmailOrNickname ?
                <div className="row">
                    <div className="col-12" style={{marginTop: 40}}>
                        <Typography variant="h4" gutterBottom align="center" className="page-header">
                            {LocalizerHelper.localized('proceed_signin_needed')}
                        </Typography>
                    </div>
                </div> : null
            }
            {isAnonymousUser ?
                <div className="row">
                    <div className="col-md-12">
                        <StyledFirebaseAuth uiCallback={ui => ui?.disableAutoSignIn()} uiConfig={uiConfig}
                                            firebaseAuth={firebase.auth()}/>
                    </div>
                </div> : <div>
                    {showUpdateEmailOrNickname ?
                        <div className="profile-form-container">
                            <div className="row" style={{marginTop: 10}}>
                                <div className="col-md-12">
                                    <TextField
                                        variant="outlined"
                                        name="email"
                                        value={email}
                                        error={error.email}
                                        fullWidth={true}
                                        helperText={LocalizerHelper.localized('field_required')}
                                        label={LocalizerHelper.localized('email')}
                                        onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                                    </TextField>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: 10}}>
                                <div className="col-md-12">
                                    <TextField
                                        variant="outlined"
                                        name="nickname"
                                        value={nickname}
                                        error={error.nickname}
                                        helperText={LocalizerHelper.localized('field_required')}
                                        fullWidth={true}
                                        label={LocalizerHelper.localized('nickname')}
                                        onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                                    </TextField>
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
            }
            <div className="privacy-policy-container">
                <div className="row" style={{marginTop: 10}}>
                    <div className="col-md-12 privacy-policy-row">
                        {LocalizerHelper.localized('accept_privacy_policy')}<a target="_blank" href={privacyPolicyUrl}
                                                                               className="privacy-policy-link">{LocalizerHelper.localized('privacy_policy_info')}</a>
                    </div>
                </div>
                <div className="row" style={{marginTop: 10, marginBottom: 10}}>
                    <div className="col-md-12 privacy-policy-row">
                        {LocalizerHelper.localized('accept_terms_and_conditions')}<a target="_blank" href={termsAndConditionUrl}
                                                                                     className="privacy-policy-link"> {LocalizerHelper.localized('terms_and_conditions_info')}</a>
                    </div>
                </div>
            </div>
            {showUpdateEmailOrNickname ?
                <div className="profile-form-container bottom-fixed-row signin-bottom-fixed-row">
                    <div className="row">
                        <div className="col-md-12">
                            <Button className='accent-button full-width' disabled={disableAction}
                                    onClick={() => _storeUserDataAndProceedWithOrder()}>
                                {LocalizerHelper.localized('confirm')}
                            </Button>
                        </div>
                    </div>
                </div> : null
            }
        </div>
    </div>);
};

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    firestoreUser: state.auth.user?.isAnonymous ? null : state.auth.firestoreUser,
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    dits: state.session.session,
    menu: state.menu.menu,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    retrieveRestaurant: async (restaurantID: string) => dispatch(await getRestaurant(restaurantID)),
    retrieveSession: async (restaurantID: string, ditsID: string) => dispatch(await getSession(restaurantID, ditsID)),
    setLanguageToUser: async (user: User, lang: string) => dispatch(await setLanguageToUser(user, lang)),
    switchDitsToUser: async (restaurantID: string, ditsID: string) => dispatch(await switchSessionToUser(restaurantID, ditsID)),
    retrieveUserProfile: async (user: User) => dispatch(await getUserProfile(user)),
    setUserData: async (user: User, data: any) => dispatch(await setUserData(user, data)),
    showLogin: (show: boolean, restaurantID?: string, ditsID?: string) => dispatch(showLogin(show, restaurantID, ditsID))
});

const Signin = connect(mapStateToProps, mapDispatchToProps)(_Signin);

export default Signin;
