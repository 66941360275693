export type AuthActions =
    | 'RETRIEVE_USER_PROFILE'
    | 'SET_LANGUAGE_TO_USER'
    | 'RETRIEVE_USER_PROFILE_ERROR'
    | 'SET_LANGUAGE_TO_USER_ERROR';

export const AUTH_CONSTANTS = {
    RETRIEVE_USER_PROFILE: 'RETRIEVE_USER_PROFILE',
    SET_LANGUAGE_TO_USER: 'SET_LANGUAGE_TO_USER',
    RETRIEVE_USER_PROFILE_ERROR: 'RETRIEVE_USER_PROFILE_ERROR',
    SET_LANGUAGE_TO_USER_ERROR: 'SET_LANGUAGE_TO_USER_ERROR'
}


export const SHOW_LOGIN = {
    SHOW_LOGIN: 'SHOW_LOGIN'
}

export const AUTH_ERROR = {
    MERGE_CONFLICT: "firebaseui/anonymous-upgrade-merge-conflict"
}
