import {SESSION_CONSTANTS, SessionActions} from "../constants/session.constants";

export function session(state = {}, action: {
    type: SessionActions,
    params: object[]
}) {
    switch (action.type) {
        case SESSION_CONSTANTS.RETRIEVE_LAST_SESSION:
        case SESSION_CONSTANTS.CREATE_NEW_SESSION:
        case SESSION_CONSTANTS.UPDATE_SESSION:
        case SESSION_CONSTANTS.ADD_ITEMS_TO_SESSION:
        case SESSION_CONSTANTS.UPDATE_DITS_MENU_ITEMS:
        case SESSION_CONSTANTS.ADD_INFO_DETAILS_TO_DITS:
        case SESSION_CONSTANTS.ADD_TIME_SLOT_TO_DITS:
        case SESSION_CONSTANTS.SWITCH_DITS_TO_USER:
        case SESSION_CONSTANTS.CONFIRM_ORDER:
        case SESSION_CONSTANTS.RETRIEVE_SESSION:
        case SESSION_CONSTANTS.UPDATE_PAYMENT_METHOD:
        case SESSION_CONSTANTS.UPDATE_ORDER_APPROVEMENT_REQUEST:
            return {
                ...state,
                ...action,
                error: null
            };
        case SESSION_CONSTANTS.RETRIEVE_LAST_SESSION_ERROR:
        case SESSION_CONSTANTS.CREATE_NEW_SESSION_ERROR:
        case SESSION_CONSTANTS.UPDATE_SESSION_ERROR:
        case SESSION_CONSTANTS.ADD_ITEM_TO_SESSION_ERROR:
        case SESSION_CONSTANTS.CONFIRM_ORDER_ERROR:
        case SESSION_CONSTANTS.UPDATE_DITS_MENU_ITEMS_ERROR:
        case SESSION_CONSTANTS.ADD_INFO_DETAILS_TO_DITS_ERROR:
        case SESSION_CONSTANTS.ADD_TIME_SLOT_TO_DITS_ERROR:
        case SESSION_CONSTANTS.UPDATE_PAYMENT_ERROR:
        case SESSION_CONSTANTS.SWITCH_DITS_TO_USER_ERROR:
        case SESSION_CONSTANTS.UPDATE_ORDER_APPROVEMENT_REQUEST_ERROR:
            return {
                ...state,
                ...action,
            }
        default:
            return state;
    }
}
