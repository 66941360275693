import {FirestoreHelper} from "../helpers/firestoreHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import firebase from "firebase";
import {MENU_ITEM_CONSTANTS} from "../constants/menu_item.constants";
import DocumentReference = firebase.firestore.DocumentReference;

const getMenuItemIngredients = async(menuItemRef: DocumentReference) => {
    const cookingIngredients = await FirestoreHelper.cookingIngredientsForMenuItem(menuItemRef);
    return buildActionResult(
        MENU_ITEM_CONSTANTS.RETRIEVE_MENU_ITEM_COOKING_INGREDIENTS, {
            cookingIngredients
        }
    )
}

export {
    getMenuItemIngredients
}
