import {connect} from "react-redux";
import {useHistory} from "react-router";
import {Button, Typography} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {setMenu} from "../../actions/menu.actions";
import * as React from "react";
import firebase from "firebase";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import Screen from '../screen';
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import ChuzeatFooter from "../footer/ChuzeatFooter";

interface IMenuChooserProps {
    menus: DocumentSnapshot[],
    goToMenu: (menu: DocumentSnapshot) => any
}

interface IMenuChooserContainerProps {
    restaurant: DocumentSnapshot,
    menus: DocumentSnapshot[],
    linkAlias:string | null,
    setMenu: (menu: any) => any
}

const MenuChooser = ({menus, goToMenu}: IMenuChooserProps) => (
    <div>
        {
            menus.map((item:any) => (<div className="row" key={item.ref}>
                <div className="col-md-12">
                    <Button className="accent-button full-width button" onClick={() => goToMenu(item)}>
                        {LocalizerHelper.localized(item, 'name')}
                    </Button>
                </div>
            </div>))
        }
    </div>
);

const _MenuChooserContainer = ({restaurant, menus,linkAlias,setMenu}: IMenuChooserContainerProps) => {
    const history = useHistory();

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';

    const _goToMenu = (menu: any) => {
        setMenu(menu);
        const alias = linkAlias ?? lastLinkAlias;
        history.push(`/menu/${alias}/menu/${restaurant.id}/${menu.docId}`);
    }



    const _onClickBack = () => {
        const alias = linkAlias ?? lastLinkAlias;
        history.replace(`/menu/${alias}`,{});
        history.go(0);
    }


    return (<Screen className="screen menu-cohose-screeen">
        <RestaurantHeader restaurant={restaurant} className='full-width block' onClickBack={_onClickBack}/>
        <div className="content_container with_footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="user_questions">
                            {LocalizerHelper.localized('menu_chooser_header')}
                        </p>
                    </div>
                </div>
                <div className="container-buttons-action">
                    <MenuChooser menus={menus} goToMenu={_goToMenu}/>
                </div>   
            </div>
            
        </div>
        <ChuzeatFooter/>
    </Screen>);
};

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    menus: state.link?.selectedLink?.menus ?? [],
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    setMenu: (menu: any) => dispatch(setMenu(menu))
});

const MenuChooserContainer = connect(mapStateToProps, mapDispatchToProps)(_MenuChooserContainer);

export default MenuChooserContainer;
