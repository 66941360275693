import {makeStyles} from "@material-ui/core/styles";

const useAvailabilityStyles = makeStyles((theme) => ({
    colorPrimary: {
        backgroundColor: "#96e272"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#454545"
    }
}));

const useSpinnerStyles = makeStyles((theme) => ({
    spinner: {
        color: "#fe3436",
        position: "absolute",
        top: "calc(50% - 25px)",
        left: "calc(50% - 25px)",
        width: 50,
        height: 50
    }
}));

const useLinearProgressStyles = makeStyles((theme) => ({
    colorPrimary: {
        backgroundColor: "#96e272"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#454545"
    }
}));

export {
    useAvailabilityStyles,
    useSpinnerStyles,
    useLinearProgressStyles
}
