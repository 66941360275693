export type MenuActions =
    | 'RETRIEVE_MENUS'
    | 'RETRIEVE_MENU'
    | 'RETRIEVE_MENU_ITEMS'
    | 'RETRIEVE_MENU_ITEM';

export const MENU_CONSTANTS = {
    RETRIEVE_MENUS: 'RETRIEVE_MENUS',
    RETRIEVE_MENU: 'RETRIEVE_MENU',
    RETRIEVE_MENU_ITEMS: 'RETRIEVE_MENU_ITEMS',
    RETRIEVE_MENU_ITEM: 'RETRIEVE_MENU_ITEM',
    RETRIEVE_MENUS_ERROR: 'RETRIEVE_MENUS_ERROR'
}
