import firebase from "firebase";
import {validateAndRetrieveLinks,validateQuidAndRetrieveMenus} from "../helpers/apiHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {LINKS_CONSTANTS} from "../constants/link.constants";

const validateLink = async (linkAlias: string) => {
    console.log(linkAlias);
    try {
        const responseBody = await validateAndRetrieveLinks(linkAlias);
        console.log(responseBody);
        return buildActionResult(LINKS_CONSTANTS.VALIDATE_LINK, {
            ...responseBody,
            linkAlias,
            linksFetched: true
        });
    } catch (error) {
        console.log(error);
        return buildActionResult(LINKS_CONSTANTS.VALIDATE_LINK_ERROR, {
            error,
            linksFetched: true
        });
    }
}

const setLink = (selectedLink: any) => {
    
    const data =  buildActionResult(LINKS_CONSTANTS.SELECT_LINK, {
        selectedLink
    });
    console.log(data);
    return data;
}

const setQuid = (selectedLink: any) => {
    return buildActionResult(LINKS_CONSTANTS.SELECT_LINK, {
        selectedLink
    });
}

const validateQuid = async (token: string) => {
    console.log(`validateQuid token[${token}]`);
    try {
        const responseBody = await validateQuidAndRetrieveMenus(token);
        console.log(`validateQuid response[${JSON.stringify(responseBody)}]`);
        return buildActionResult(LINKS_CONSTANTS.VALIDATE_QUID, {
            ...responseBody,
            quid:token,
            quidFetched: true
        });
    } catch (error) {
        console.log(`validateQuid error[${JSON.stringify(error)}]`);
        return buildActionResult(LINKS_CONSTANTS.VALIDATE_QUID_ERROR, {
            error,
            quid:token,
            quidFetched: true
        });
    }
}

export {
    validateLink,
    setLink,
    validateQuid,
    setQuid
}
