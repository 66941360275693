import * as React from "react";
import {Button} from "@material-ui/core";
import {PropsWithChildren, useEffect} from "react";
import firebase from "firebase";
import {connect} from "react-redux";
import {listenForSessionMenuItems} from "../../actions/session.actions";
import {evaluateDitsMenuItemPrice} from "../../helpers/ditsMenuItemHelper";
import {formatPrice} from "../../helpers/formatHelper";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentReference = firebase.firestore.DocumentReference;
import {DELIVERY_MODES} from "../../constants/data";

interface IOrderRecapProps extends PropsWithChildren<any> {
    restaurant: any;
    dits: DocumentSnapshot;
    menuItems: DocumentSnapshot[];
    listenForDitsMenuItems: (ditsRef: DocumentReference) => any;
    disableLabel?: string;
    nextPath: string;
    nextLabel: string;
}

let unsubscribeFromListenForDitsMenuItems: firebase.Unsubscribe | null = null;

const OrderRecap = ({restaurant, dits, menuItems, goToNext, goToNextLabel, disableAction = false, addDeliveryCosts = false}: { restaurant: any, dits: DocumentSnapshot, menuItems: DocumentSnapshot[], goToNext: () => void, goToNextLabel: string, disableAction?: boolean, addDeliveryCosts?:boolean }) => {
    let totalPrice = menuItems?.map((item) => evaluateDitsMenuItemPrice(item)).reduce((acc, item) => {
        return acc += (item * 1.0)
    }, 0) ?? 0;
    
    if(addDeliveryCosts){
        if(dits?.data()?.delivery_mode === DELIVERY_MODES.delivery){
            const free_delivery_minimum_amount = (restaurant?.data?.free_delivery_minimum_amount ?? 0);
            //const deliveryCost = restaurant?.data?.delivery_cost ?? 0;
            const deliveryCost = dits.data()?.delivery_details?.preview_delivery_cost ?? 0;
            const mustApplyDeliveryCost = (deliveryCost > 0) ? ((free_delivery_minimum_amount > 0) ? (totalPrice < free_delivery_minimum_amount): true) : true;
            const formattedPriceDeliveryCost = " " + formatPrice(free_delivery_minimum_amount);
            if(mustApplyDeliveryCost){
                totalPrice = totalPrice + (deliveryCost * 1.0);
            }            
        }
    }

    const formattedPrice = formatPrice(totalPrice);
    const label =  totalPrice > 0 ? `${goToNextLabel} ${formattedPrice}` : goToNextLabel;
    
    return (<div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                <Button className="accent-button"
                    disabled={disableAction}
                    onClick={goToNext}>
                    {label}
                </Button>
            </div>
        </div>
    </div>)
};

const _BottomSheetOrderRecap = ({
    restaurant,
    dits,
    menuItems,
    listenForDitsMenuItems,
    disableLabel,
    disableAction,
    goToNext,
    goToNextLabel,
    addDeliveryCosts,
}: IOrderRecapProps) => {

    useEffect(() => {
        if (!!dits) {
            if (!!unsubscribeFromListenForDitsMenuItems) {
                unsubscribeFromListenForDitsMenuItems();
            }
            unsubscribeFromListenForDitsMenuItems = listenForDitsMenuItems(dits?.ref);
        }
    }, []);

    const _showDeliveryCost = () => {
        let mustApplyDeliveryCost = false;
        let labelDeliveryCost = null;
        if(restaurant != null && dits!=null && dits?.data() && dits?.data()?.delivery_mode === DELIVERY_MODES.delivery){
            const totalPrice = menuItems?.map((item) => evaluateDitsMenuItemPrice(item)).reduce((acc, item) => {
                return acc += (item * 1.0)
            }, 0) ?? 0;
            console.log(`totalPrice ==> ${totalPrice}`);
            const free_delivery_minimum_amount = (restaurant?.data?.free_delivery_minimum_amount ?? 0);
            const deliveryCost = restaurant?.data?.delivery_cost ?? 0;
            console.log(`deliveryCost ==> ${deliveryCost}`);
            mustApplyDeliveryCost = (deliveryCost > 0) ? ((free_delivery_minimum_amount > 0) ? (totalPrice < free_delivery_minimum_amount): true) : true;
            console.log(`mustApplyDeliveryCost ==> ${mustApplyDeliveryCost}`);
            //totalPrice += mustApplyDeliveryCost ? deliveryCost : 0;
            const formattedPriceDeliveryCost = " " + formatPrice(free_delivery_minimum_amount);
            if(mustApplyDeliveryCost && free_delivery_minimum_amount> 0){
                const text = (LocalizerHelper.localized('bottom_order_recap_free_delivery_minimum_amount') + formattedPriceDeliveryCost);
                return <p>{text}</p>;
            }            
        }
        return null;
    }


    return (<div className="bottom-fixed-row order-recap-fixed-row">
        <div>
            {!!disableLabel && disableLabel?.trim().length > 0 ?
                <p>{disableLabel}</p> : _showDeliveryCost()
            }
            <OrderRecap restaurant={restaurant}
                        dits={dits} menuItems={menuItems}
                        goToNext={goToNext}
                        goToNextLabel={goToNextLabel} 
                        disableAction={disableAction}
                        addDeliveryCosts = {addDeliveryCosts}
                        />
        </div>
    </div>)
};

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant,
    dits: state.session.session,
    menuItems: state.session.ditsMenuItems
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDitsMenuItems: listenForSessionMenuItems(dispatch),
});


const BottomOrderRecap = connect(mapStateToProps, mapDispatchToProps)(_BottomSheetOrderRecap);

export default BottomOrderRecap;
