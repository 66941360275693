import { Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { LocalizerHelper } from '../../helpers/localizerHelper';
import { AddOn, Option, _getOptionId } from '../../models/menu';

import './index.scss';
import OptionComponent from "./Option";

interface AddOnsComponentProps {
    addOn: AddOn;
    insertAddOn: any;
}

const AddOnsComponent: React.FC<AddOnsComponentProps> = ({addOn, insertAddOn}) => {
    const a = {...addOn, options:[], numChoice:0}
    const [addOnSelected, setAddOnSelected] = React.useState<AddOn>(a);
    const [optionsSelected, setOptionsSelected] = React.useState<Option[]>([]);

    const _checkAddOnInsert = () => {
        console.log('_checkAddOnInsert')
        console.log('addOnSelected', addOnSelected);
        console.log('optionsSelected', optionsSelected);
        if((addOn.max_size ?? 1) == 1){
            return true;
        }
        if((addOn.max_size ?? 1) == 0){
            return true;
        }
        if(!!!addOn.global){
            return optionsSelected.length < (addOn.max_size ?? 1);
        }else{
            return addOnSelected.options.length < (addOn.max_size ?? 1);
        }
    }

    const _addOption = (option:Option) => {
        console.log('_addOption');
        if((addOn.max_size ?? 1) == 1){
            const index = optionsSelected.map(function(e) { return _getOptionId(e); }).indexOf(_getOptionId(option), 0)
            if(index === -1){
                setOptionsSelected([option]);
                setAddOnSelected({...addOnSelected, options:[option], numChoice: 1})
                insertAddOn({...addOn, options:[option], numChoice: 1})
            }else{
                setOptionsSelected([option]);
                setAddOnSelected({...addOnSelected, options:[...addOnSelected.options, option], numChoice: 1})
                insertAddOn({...addOn, options:[...addOnSelected.options, option], numChoice: 1})
            }
        }else{
            const index = optionsSelected.map(function(e) { return _getOptionId(e); }).indexOf(_getOptionId(option), 0)
            let choices:number = optionsSelected.length;
            if(index == -1){
                setOptionsSelected([...optionsSelected, option]);
                choices += 1;
            }
            if(!!addOnSelected){
                setAddOnSelected({...addOnSelected, options:[...addOnSelected.options, option], numChoice: choices})
                insertAddOn({...addOnSelected, options:[...addOnSelected.options, option], numChoice: choices})
            }else{
                setAddOnSelected({...addOn, options:[option], numChoice: choices})
                insertAddOn({...addOn, options:[option], numChoice: choices})
            }
        }
    }

    const _removeOption = (option:Option) => {
        console.log('_removeOption')
        const indexOpt = optionsSelected.map(function(e) { return _getOptionId(e); }).indexOf(_getOptionId(option), 0)
        let choices:number = optionsSelected.length;
        if (indexOpt > -1) {
            if(!!addOnSelected && !!addOnSelected.options){
                const indexAddOnOption = addOnSelected.options.map(function(e) { return _getOptionId(e); }).indexOf(_getOptionId(option), 0);
                if (indexAddOnOption > -1) {
                    addOnSelected.options.splice(indexAddOnOption, 1);
                    const tmp = addOnSelected.options.map(function(e) { return _getOptionId(e); }).indexOf(_getOptionId(option), 0);
                    if(tmp == -1){
                        choices -= 1;
                        optionsSelected.splice(indexOpt, 1);
                        setOptionsSelected(optionsSelected)
                    }
                    if(addOnSelected.options.length > 0){
                        setAddOnSelected({...addOnSelected, numChoice: choices});
                        insertAddOn({...addOnSelected, numChoice: choices});
                    }else{
                        setAddOnSelected({...addOnSelected, options:[], numChoice: choices});
                        insertAddOn({...addOnSelected, options:[], numChoice: choices});
                    }
                }
            }
        }
    }

    const _getQty = (item: Option) => {
        let qty:number = 0;
        for(const opt of addOnSelected.options){
            if(_getOptionId(item) === _getOptionId(opt)){
                qty ++;
            }
        }
        return qty;
    }

    const _buildSubtitle = () => {
        const maxSize = addOn.max_size ?? 1;
        let subtitle = '';
        switch (true){
            case (maxSize < 1):
                subtitle += LocalizerHelper.localized('multiple_choice')
                break;
            case (maxSize == 1):
                subtitle += LocalizerHelper.localized('single_choice')
                break;
            case (maxSize > 1):
                subtitle += LocalizerHelper.localized('multiple_choice')
                break;
            default:
                break;
        }
        return <p>{subtitle} (<span style={{color:"#FE3436"}}>min {addOn.min_size ?? 0}</span>, max {addOn.max_size ?? 1})</p>;
    }

    return (
        <div className="add-on">
            <Typography variant="h4" gutterBottom className="add-on-header" align="left">{LocalizerHelper.localized(addOn, 'title')}</Typography>
            <Typography variant="subtitle1" gutterBottom className="add-on-sub-header" align="left">{_buildSubtitle()}</Typography>
            {addOn.options?.map((item: Option, index: number) => 
                (!!_getOptionId(item)) ? <OptionComponent
                    key={_getOptionId(item)}
                    option={item}
                    checkAddOnInsert={():boolean => {return _checkAddOnInsert()}} 
                    addOption={(option:Option) => {_addOption(option)}} 
                    removeOption={(option:Option) => {_removeOption(option)}}
                    quantity={_getQty(item)}
                /> : null
            )}
        </div>
    );
}

export default AddOnsComponent;