import * as React from 'react';
import { LoaderGif } from '../loader';
import { LocalizerHelper } from '../../helpers/localizerHelper';


const NexiPaymentScreen = ({}) => {
    console.log("NexiPaymentScreen: ", window.location.href)
    window.parent.postMessage(window.location.href, '*');

    return <div>
            <LoaderGif  logo={null} image="/assets/loading_check_payment.gif" message={LocalizerHelper.localized('loading_msg_check_payment')} />
        </div>
};

export default NexiPaymentScreen;
