import * as React from "react";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {connect} from "react-redux";
import {useEffect} from "react";
import firebase from "firebase";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {listenForOrderApprovementRequest,} from "../../actions/session.actions";
import {useHistory} from "react-router";
import {COLLECTIONS, ORDER_APPROVEMENT_REQUESTS_STATUS} from "../../constants/data";
import {listenForBillLogs, requestSessionBill} from "../../actions/bill.actions";
import {Button} from "@material-ui/core";
import Screen from '../screen';
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentReference = firebase.firestore.DocumentReference;

interface IWaitingOrderApprovementProps extends React.PropsWithChildren<any> {
    restaurant: any,
    dits: DocumentSnapshot,
    orderApprovementRequest: DocumentSnapshot,
    bill: DocumentSnapshot,
    mustPayFirst: boolean,
    linkAlias:string | null,
    requestBill: (restaurantID: string, sessionID: string) => any
    listenForDitsBillLog: (ditsRef: DocumentReference) => any,
}

const _defaultMaxWaitingTime = 90 * 1000;

let unsubscribeFromListenForOrderApprovementRequest: firebase.Unsubscribe | null = null;
let unsubscribeFromListenForBillLog: firebase.Unsubscribe | null = null;
let timeElapsed = 0;

const _WaitingOrderApprovement = ({
    match,
    restaurant,
    dits,
    bill,
    mustPayFirst,
    linkAlias,
    requestBill,
    listenForDitsBillLog,
}: IWaitingOrderApprovementProps) => {

    const maxWaitingTime = (restaurant?.data?.max_order_approvement_waiting_time ?? _defaultMaxWaitingTime) + (10*1000);
    const [loading, setLoading] = React.useState(true);
    const [waitForBill, setWaitForBill] = React.useState(false);
    const [toShowPercentage, setToShowPercentage] = React.useState(0);
    const [orderRejected, setOrderRejected] = React.useState(false);
    const history = useHistory();
    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const [countdown,setCountDown] = React.useState((maxWaitingTime)/1000);
    const [orderApprovementRequest, setOrderApprovementRequest] = React.useState<DocumentSnapshot|null>(null);
    const msgWatingForApproval = LocalizerHelper.localized('loading_msg_wait_for_approval');
    let countDownValue = (maxWaitingTime)/1000;
    let timeElapsedInterval:any = null;

    useEffect(() => {
        _load();
        timeElapsedInterval = setInterval(() => {
            timeElapsed += 1000;
            const _toShowPercentage = timeElapsed * 100 / maxWaitingTime;
            //setToShowPercentage(_toShowPercentage);
            countDownValue = countDownValue - 1;
            countDownValue = (countDownValue < 0) ? 0 : countDownValue;
            console.log(countDownValue);
            setCountDown(countDownValue);
            if(countDownValue<= 0){
                //setTimeoutReached(true);
            }
        }, 1000);
        return () => {

            if(timeElapsedInterval){
                clearInterval(timeElapsedInterval);
                timeElapsedInterval = null;
            }
            if (!!unsubscribeFromListenForBillLog) {
                unsubscribeFromListenForBillLog();
                unsubscribeFromListenForBillLog = null;
            }
        }
    }, []);

    const _load = async () => {
        if(!orderApprovementRequest){
            const rID = match?.params?.restaurant;
            const dID = match?.params?.dits;
            const reqId = match?.params?.requestId;
            const path = `${COLLECTIONS.restaurants}/${rID}/${COLLECTIONS.dineInTableSitting}/${dID}/${COLLECTIONS.orderApprovementRequest}/${reqId}`
            console.log("path: ", path);
            if(!!path){
                firebase.firestore().doc(path).onSnapshot(snapshot => {
                    setOrderApprovementRequest(snapshot);
                })
            }
        }
    }

    useEffect(() => {
        console.log("orderApprovementRequest: ", orderApprovementRequest);
        if (!!orderApprovementRequest && orderApprovementRequest.exists) {
            const isOrderApproved = orderApprovementRequest?.data()?.status === ORDER_APPROVEMENT_REQUESTS_STATUS.approved;
            const isOrderRejected = orderApprovementRequest?.data()?.status === ORDER_APPROVEMENT_REQUESTS_STATUS.rejected;
            if (isOrderApproved || isOrderRejected) {
                if (!!timeElapsedInterval) {
                    clearInterval(timeElapsedInterval);
                }
                if (isOrderApproved) {
                    if (!unsubscribeFromListenForBillLog) {
                        unsubscribeFromListenForBillLog = listenForDitsBillLog(dits?.ref);
                    }
                    setWaitForBill(true);
                    //requestBill(restaurant.id, dits.id);
                    return;
                } else if (isOrderRejected) {
                    setLoading(false);
                    setOrderRejected(isOrderRejected);
                }
            }
        }
        if (!!unsubscribeFromListenForOrderApprovementRequest) {
            unsubscribeFromListenForOrderApprovementRequest();
            unsubscribeFromListenForOrderApprovementRequest = null;
        }
    }, [orderApprovementRequest]);

    useEffect(() => {
        if (!!bill && waitForBill) {
            setLoading(false);
            setWaitForBill(false);
            if (mustPayFirst) {
                const alias = linkAlias ?? lastLinkAlias;
                history.push(`/menu/${alias}/payment`);
                //history.push('/payment');
            } else {
                const alias = linkAlias ?? lastLinkAlias;
                history.push(`/menu/${alias}/thanks`);
                //history.push('/thanks');
            }
        }
    }, [bill]);

    const isOrderApproved = orderApprovementRequest?.data()?.status === ORDER_APPROVEMENT_REQUESTS_STATUS.approved;

    const newOrder = () => {
        console.log("@@@@ newOrder");
        const alias = linkAlias ?? lastLinkAlias;
        const url = `/menu/${alias}`
        window.location.href = url
    }

    //{(timeoutReached || orderRejected) ?
    return (<Screen className="screen screen-wait-for-approvement">
       <RestaurantHeader showBack={false} className='full-width block' restaurant={restaurant}/>
        <div className="container waiting-order-approvement-main-container">
            {(orderRejected) ?
                <div className="row">
                    <div className="col-12">
                        <div className="container-loader">
                            <div className="container">
                                    <div className="container-img-loader">
                                        <div className="bkg-img"></div>
                                        <img className="loader-img"src="/assets/msg_not_order_approval.gif"/>
                                    </div>
                            </div>
                        </div>
                    </div>    
                    <div className="col-12 error_message">
                        <p className="user_questions">{LocalizerHelper.localized('order_not_approved_by_restaurant_owner')}</p>
                    </div>
                    <div className="row container-buttons-action">
                        <div className="col-md-12">
                            <Button className='accent-button full-width button button' onClick={() => newOrder()}>
                                {LocalizerHelper.localized('new_order')}
                            </Button>
                        </div>
                    </div>
                </div>
                :
              <div className="row">
                <div className="col-12">
                        <div className="container-loader">
                            <div className="container">
                                    <p className="message" dangerouslySetInnerHTML={{__html: msgWatingForApproval.replace('%@',countdown)}}/>
                                    <div className="container-img-loader">
                                        <div className="bkg-img"></div>
                                        <img className="loader-img"src="/assets/loading_wait_approval_restaurant.gif"/>
                                    </div>
                            </div>
                        </div>
                </div>
              </div>
            }
        </div>
    </Screen>);
}

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant,
    dits: state.session.session,
    bill: state.bill?.bill ?? null,
    mustPayFirst: state.session.mustPayFirst ?? false,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDitsBillLog: listenForBillLogs(dispatch),
    requestBill: async (restaurantID: string, sessionID: string) => dispatch(await requestSessionBill(restaurantID, sessionID)),
});

const WaitingOrderApprovement = connect(mapStateToProps, mapDispatchToProps)(_WaitingOrderApprovement);

export default WaitingOrderApprovement;
