export type SessionActions =
    | 'RETRIEVE_LAST_SESSION'
    | 'RETRIEVE_SESSION'
    | 'CREATE_NEW_SESSION'
    | 'UPDATE_SESSION'
    | 'ADD_ITEMS_TO_SESSION'
    | 'DELETE_ITEMS_FROM_SESSION'
    | 'CONFIRM_ORDER'
    | 'UPDATE_DITS_MENU_ITEMS'
    | 'ADD_INFO_DETAILS_TO_DITS'
    | 'ADD_TIME_SLOT_TO_DITS'
    | 'SWITCH_DITS_TO_USER'
    | 'UPDATE_ORDER_APPROVEMENT_REQUEST'
    | 'RETRIEVE_LAST_SESSION_ERROR'
    | 'CREATE_NEW_SESSION_ERROR'
    | 'UPDATE_SESSION_ERROR'
    | 'ADD_ITEM_TO_SESSION_ERROR'
    | 'CONFIRM_ORDER_ERROR'
    | 'UPDATE_DITS_MENU_ITEMS_ERROR'
    | 'ADD_INFO_DETAILS_TO_DITS_ERROR'
    | 'ADD_TIME_SLOT_TO_DITS_ERROR'
    | 'SWITCH_DITS_TO_USER_ERROR'
    | 'UPDATE_ORDER_APPROVEMENT_REQUEST_ERROR'
    | 'UPDATE_PAYMENT_METHOD'
    | 'UPDATE_PAYMENT_ERROR'
    ;

export const SESSION_CONSTANTS = {
    RETRIEVE_LAST_SESSION: 'RETRIEVE_LAST_SESSION',
    RETRIEVE_SESSION: 'RETRIEVE_SESSION',
    CREATE_NEW_SESSION: 'CREATE_NEW_SESSION',
    UPDATE_SESSION: 'UPDATE_SESSION',
    ADD_ITEMS_TO_SESSION: 'ADD_ITEM_TO_SESSION',
    DELETE_ITEMS_FROM_SESSION: 'DELETE_ITEMS_FROM_SESSION',
    CONFIRM_ORDER: 'CONFIRM_ORDER',
    UPDATE_ORDER_APPROVEMENT_REQUEST: 'UPDATE_ORDER_APPROVEMENT_REQUEST',
    UPDATE_DITS_MENU_ITEMS: 'UPDATE_DITS_MENU_ITEMS',
    ADD_INFO_DETAILS_TO_DITS: 'ADD_INFO_DETAILS_TO_DITS',
    ADD_TIME_SLOT_TO_DITS: 'ADD_TIME_SLOT_TO_DITS',
    SWITCH_DITS_TO_USER: 'SWITCH_DITS_TO_USER',
    RETRIEVE_LAST_SESSION_ERROR: 'RETRIEVE_LAST_SESSION_ERROR',
    RETRIEVE_SESSION_ERROR: 'RETRIEVE_SESSION_ERROR',
    CREATE_NEW_SESSION_ERROR: 'CREATE_NEW_SESSION_ERROR',
    UPDATE_SESSION_ERROR: 'UPDATE_SESSION_ERROR',
    ADD_ITEM_TO_SESSION_ERROR: 'ADD_ITEM_TO_SESSION_ERROR',
    CONFIRM_ORDER_ERROR: 'CONFIRM_ORDER_ERROR',
    UPDATE_DITS_MENU_ITEMS_ERROR: 'UPDATE_DITS_MENU_ITEMS_ERROR',
    ADD_INFO_DETAILS_TO_DITS_ERROR: 'ADD_INFO_DETAILS_TO_DITS_ERROR',
    ADD_TIME_SLOT_TO_DITS_ERROR: 'ADD_TIME_SLOT_TO_DITS_ERROR',
    SWITCH_DITS_TO_USER_ERROR: 'SWITCH_DITS_TO_USER_ERROR',
    UPDATE_ORDER_APPROVEMENT_REQUEST_ERROR: 'UPDATE_ORDER_APPROVEMENT_REQUEST_ERROR',
    UPDATE_ORDER_RECAP: 'UPDATE_ORDER_RECAP',
    UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
    UPDATE_PAYMENT_ERROR: 'UPDATE_PAYMENT_ERROR'
}
