export const COLLECTIONS = {
    restaurants: 'restaurants',
    menu: 'menu',
    menuItems: 'menu_items',
    users: 'users',
    dineInTableSitting: 'dine_in_table_sitting',
    takeAwayDeliverySlots: 'take_away_delivery_slots',
    cookingIngredients: 'cooking_ingredients',
    billLog: 'bill_log',
    bills: 'bills',
    usersSettings: 'users_settings',
    orderApprovementRequest: 'order_approvement_requests'
}

export const MENU_STATUS = {
    live: 'live',
    external: 'external'
}

export const DITS_STATUS = {
    started: 'started',
    ordering: 'ordering',
    ordered: 'ordered',
    eating: 'eating',
    finished: 'finished',
    paid: 'paid',
    aborted: 'aborted'
};

export const ORDER_APPROVEMENT_REQUESTS_STATUS = {
    toApprove: 'to_approve',
    approved: 'approved',
    rejected: 'rejected'
}

export const DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS = {
    chosen: 'chosen',
    ordered: 'ordered',
    ready: 'ready',
    served: 'served',
    na: 'na',
    removed: 'removed'
};


export const DELIVERY_MODES = {
    inPlace: 'in_place',
    delivery: 'delivery'
}

export const BILL_STATUS = {
    unpaid: 'unpaid',
    authorized: 'authorized',
    processing_payment: 'processing_payment',
    paid: 'paid',
    refunded: 'refunded'
};

export const PAYMENT_TYPES = {
    all: 'no-split',
    split: 'by-head',
    per_se: 'by-ordered-items'
};

export const DEFAULT_ALLOWED_PAYMENT_METHODS = ['stripe'];

export const PAYMENT_METHODS: any = {
    stripe: {
        label: 'credit_card',
        value: 'stripe',
        order: 0,
    },
    cash: {
        label: 'cash',
        value: 'cash',
        order: 1
    }
}

export const ALL_PAYMENT_METHODS = {
    ...PAYMENT_METHODS,
    default: {
        label: 'default',
        value: 'default',
        order: 0,
    }
}

export const RESPONSE_CODES = {
    success: "success",
    ok: "ok",
    aborted: "aborted",
    denied: "permission-denied"
}

export const DISH_CATEGORIES = {
    starter: "starter",
    first: "first",
    second: "second",
    side_dish: "side-dish",
    side: "side dish",
    dessert: "dessert",
    pizza: "pizza",
    drinks: "drinks"
}

export const COURSES = {
    starter: "starter",
    first: "first",
    second: "second",
    dessert: "dessert",
    asap: "asap"
}

export const DISH_CATEGORY_TO_COURSE = {
    [DISH_CATEGORIES.starter]: COURSES.starter,
    [DISH_CATEGORIES.first]: COURSES.first,
    [DISH_CATEGORIES.second]: COURSES.second,
    [DISH_CATEGORIES.dessert]: COURSES.dessert,
    [DISH_CATEGORIES.drinks]: COURSES.asap,
    [DISH_CATEGORIES.pizza]: COURSES.first,
    [DISH_CATEGORIES.side_dish]: COURSES.second,
    [DISH_CATEGORIES.side]: COURSES.second
}


export const DEFAULT_PRIVACY_URL = 'https://www.chuzeat.com/informativa-privacy/';
export const DEFAULT_TERMS_AND_CONDITION_URL = 'https://www.chuzeat.com/termini-e-condizioni/';
