import { Button } from '@material-ui/core';
import * as React from "react";
import { ElectronicCard, ElectronicPaymentMethodType } from '../../models/payments';
import Card from "react-credit-cards";
import 'react-credit-cards/es/styles-compiled.css';
import { LocalizerHelper } from '../../helpers/localizerHelper';

export interface ElectronicCardComponentProps {
    card: ElectronicCard;
    onClick: any;
}

const ElectronicCardComponent: React.FC<ElectronicCardComponentProps> = ({card, onClick}) => {

    function getExpiry(): string {
        let month: string = '';
        let year: string= '';
        switch(card.service){
            case ElectronicPaymentMethodType.STRIPE:
                const date:Date = new Date(card.expire);
                month = (date.getUTCMonth() + 1).toString();
                if(month.length == 1){
                    month = '0' + month;
                }
                year = date.getUTCFullYear().toString().slice(2);
                return month + year;
            case ElectronicPaymentMethodType.NEXI:
                const li = card.expire?.split('/');
                month = li[1];
                year = li[0].slice(2);
                return month + year;
            default:
                return card.expire;
        }
    }

    function getPan(): string {
        const pan: string = card.pan.replace(/X/gi, '*');
        switch(card.brand){
            case 'mastercard':
            case 'visa':
            case 'dankort':
            case 'dinersclub':
            case 'discover':
            case 'elo':
            case 'hipercard':
                return pan;
            case 'amex':
                if(pan[0] == '*'){
                    return pan.slice(1);
                }
                return pan;
        }
        return pan;
    }

    return (
        <div>
            <Card

                cvc={'***'}
                name={" "}
                expiry={getExpiry()}
                number={getPan()}
                preview={true}
                issuer={card.brand}
            />
            <div className="row container_buttons_action justify-content-center">
                <div className="col-md-auto">
                    <Button className="accent-button" onClick={onClick}>
                        {LocalizerHelper.localized('proceed_with_payment')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ElectronicCardComponent;