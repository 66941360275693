import * as React from "react";
import {Typography} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import {useHistory} from "react-router";
import {connect} from "react-redux";
import cartIcon from './cart.png';
import './restaurant-header.scss';
import {useEffect} from "react";

const _RestaurantHeader = ({restaurant, link, className = '', showBack = true, showCart = false, ditsMenuItems = [],menuName = null, onClickCart, onClickBack = null}: any) => {

    const [canShowCart, setCanShowCart] = React.useState(false);
    const [ditsMenuItemsCount, setDitsMenuItemsCount] = React.useState(0)

    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", restaurant);
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", restaurant?.data);
    const restaurantHasLogo = (!!restaurant?.data?.logo || !!(restaurant?.logo)) ?? false;
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", restaurantHasLogo);
    const restaurantLogo = restaurant?.data?.logo?.url ?? restaurant?.data?.logo ?? restaurant?.logo?.url ?? restaurant?.logo ?? null;
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", restaurantLogo);
    const hasLink = !!link;
    const linkName = hasLink ? LocalizerHelper.localized(link, 'name') : null;

    let logoContainerClassName = showBack || showCart ? 'col-6' : 'col-12';
    if (showCart && !showBack) {
        logoContainerClassName += ' offset-3';
    }
    const history = useHistory();

    useEffect(() => {
        if (showCart) {
            const _ditsMenuItemsCount = (ditsMenuItems ?? []).length;
            setDitsMenuItemsCount(_ditsMenuItemsCount);
            setCanShowCart(showCart && _ditsMenuItemsCount > 0);
        }
    }, [ditsMenuItems]);

    const _onClickCart = () => {
        if (!!onClickCart && typeof onClickCart === 'function') {
            onClickCart();
        }
    }


    return (
       <div className="container_header" id="container_header_id">
        <div className={`header container ${className ?? ''}`}>
            <div className='row'>
                <div className="col-3 text-left">
                    {showBack ?
                        
                            <IconButton aria-label="delete" size="medium" className="back-button" onClick={() => {
                                if(onClickBack && typeof onClickBack === 'function'){
                                    onClickBack();
                                }else{
                                    history.goBack();
                                }
                                
                            }}>
                                <BackIcon fontSize="inherit"/>
                            </IconButton>
                        : null
                    } 
                </div>
                <div className={`title ${logoContainerClassName}`}>
                    {restaurantHasLogo ?
                        <img className="logo" src={restaurantLogo}/> :
                        <Typography variant="h5"
                                    className="page-header">{LocalizerHelper.localized(restaurant?.data ?? restaurant ?? {}, 'name')}</Typography>
                    }
                </div>
                {canShowCart ?
                    <div className="col-3 cart-icon-container" onClick={_onClickCart}>
                        <div className='cart-badge'>
                            {ditsMenuItemsCount}
                        </div>
                        <img src={cartIcon} className='cart-icon'/>
                    </div> : <div className="col-3"></div>
                }
            </div>
        </div>
        {hasLink ?
            <div className="container subheader">
                <div className="row">
                    <div className="col-12 text-center">
                        <span className='delivery-mode-label'>{(menuName && menuName.trim() != "") ? `${linkName}(${menuName})`: linkName}</span>
                    </div>
                </div> 
            </div>    
            : null

        }
    </div>
    );
};

const mapStateToProps = (state: any) => ({
    link: state.link.selectedLink ?? null,
    ditsMenuItems: state.session.ditsMenuItems
});

const mapDispatchToProps = (dispatch: any) => ({});

const RestaurantHeader = connect(mapStateToProps, mapDispatchToProps)(_RestaurantHeader);

export default RestaurantHeader;
