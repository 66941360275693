export interface IPaymentMethod {
    activated?: boolean;
    canAddCards?: boolean;
    cards: any[];
    main?: boolean;
    type: string;
}

export interface NexiPaymentMethod extends IPaymentMethod{
    alias: string;
    concurrency: string;
    environment: string;
    extraData: ExtraData;
    group: string;
    secret_key: string;
    version: string;
}

export interface StripePaymentMethod extends IPaymentMethod{
    gateway_name: string;
    gateway_id: string;
    key: string;
}

export interface ElectronicCard {
    id:string,
    pan:string,
    service:string,
    expire:string,
    brand:string,
    extraData?:ExtraData
}

export interface IntentPayment {
    transactionId: string,
    amount: number,
    data: ExtraData,
    error?: string,
    code?: string
}

export interface ExtraData {
    [name: string]: any
}

export interface RequiredParametersNexi {
    alias: string,
    importo: number,
    divisa: string,
    codTrans: string,
    url: string,
    url_back: string,
    mac: CryptoJS.lib.WordArray,
    urlpost: string,
    num_contratto: string,
    tipo_servizio: string,
    tipo_richiesta: string,
    gruppo: string,
}

export const ElectronicPaymentMethodType = {
    NEXI: 'nexi',
    STRIPE: 'stripe'
}