import firebase from "firebase";
import {RESPONSE_CODES} from "../constants/data";
import { TimeSlot } from "../models";

const axios = require('axios');

const FUNCTIONS_REGION = "europe-west2";
const API_VALIDATE_LINKS = 'api-webapp-validateLink';//process.env.VALIDATE_LINK_API_PATH ?? 'api-webapp-validateLink';
const API_VALIDATE_QUID = process.env.VALIDATE_QUID_API_PATH ?? 'api-webapp-menusByQuid';
const API_OPEN_TABLE = process.env.OPEN_TABLE_API_PATH ?? 'api-openTable';
//const API_CONFIRM_ORDER = process.env.CONFIRM_ORDER_API_PATH ?? 'api-confirmOrder';
const API_CONFIRM_ORDER = process.env.CONFIRM_ORDER_API_PATH ?? 'api-confirmWebOrder';
const API_RESET_ORDER = process.env.RESET_ORDER_API_PATH ?? 'api-rejectWebOrderDev';
const API_SWITCH_DITS_TO_USER = process.env.SWITCH_DITS_TO_USER_API_PATH ?? 'api-switchDitsToUser';
const API_REQUEST_BILL = process.env.REQUEST_BILL_API_PATH ?? 'api-requestBill';
const API_PAY_STRIPE = process.env.PAY_STRIPE_API_PATH ?? 'api-payStripeDev';
const API_CREATE_PAYMENT_TRANSACTION = process.env.PAYMENT_API_CREATE_TRANSACTION ?? 'api-payments-createPaymentTransaction';
const API_ELECTRONIC_PAYMENT_METHODS = process.env.PAYMENT_API_ELECTRONIC_METHODS ?? 'api-payments-electronicPaymentsMethod';
const API_CONFIRM_PAYMENT = process.env.PAYMENT_API_CONFIRM_PAYMENT ?? 'api-payments-confirmPayment';
const API_CANCEL_PAYMENT = process.env.PAYMENT_API_CANCEL_PAYMENT ?? 'api-payments-cancelPayment';

const CHANNEL = process.env.CHANNEL ?? 'web';

const functions = firebase.app().functions(FUNCTIONS_REGION);

const _isSuccessResponse = (data: any) => (data.code === RESPONSE_CODES.success || data.code === RESPONSE_CODES.ok);

const _buildErrorFromResponse = (data: any,) => {
    if (!!data && !!data?.error && !!data?.error?.message) {
        return data.error;
    }
    const errorMessage = data.error ?? 'Generic Error';
    return {
        message: errorMessage
    }
};

const validateAndRetrieveLinks = async (linkAlias: string) => {
    const validateLinksApi = functions.httpsCallable(API_VALIDATE_LINKS);
    const data = {
        'link': linkAlias,
        channel:CHANNEL
    };
    try {
        const response = await validateLinksApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from validateLinks');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
        return null;
    } catch (error) {
        throw error;
    }
}

const validateQuidAndRetrieveMenus = async (token: string) => {
    const validateQuidApi = functions.httpsCallable(API_VALIDATE_QUID);
    const data = {
        token,
        channel:CHANNEL
    };
    try {
        const response = await validateQuidApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from validateQuidApi');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
        return null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const createDits = async (link: string) => {
    const createDitsApi = functions.httpsCallable(API_OPEN_TABLE);
    const data = {
        link,
        channel:CHANNEL
    };
    try {
        const response = await createDitsApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from createDits');
                console.log(response);
                return response.data.response.dits;
            }
            throw _buildErrorFromResponse(response.data);
        }
        return null;
    } catch (error) {
        throw error;
    }
}

const switchDitsToUser = async(restaurantID: string, sessionID: string) => {
    const switchDitsToUser = functions.httpsCallable(API_SWITCH_DITS_TO_USER);
    const data = {
        restaurantId: restaurantID,
        sessionId: sessionID,
        channel:CHANNEL
    }
    try {
        const response = await switchDitsToUser(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from switchDitsToUser');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
        return null;
    } catch (error) {
        throw error;
    }
}

const confirmSessionOrder = async(restaurantID: string, menuId:string, sessionID: string, paymentMethod: string,timeSlot:TimeSlot | undefined | null) => {
    const confirmOrderApi = functions.httpsCallable(API_CONFIRM_ORDER);
    const data = {
        restaurantId: restaurantID,
        sessionId: sessionID,
        menuId,
        paymentMethod: paymentMethod,
        timeSlot:timeSlot,
        channel:CHANNEL
    }
    try {
        const response = await confirmOrderApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from confirmOrder');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
        return null;
    } catch (error) {
        throw error;
    }

}

const resetSessionOrder = async(restaurantID: string, menuId:string, sessionID: string, paymentMethod: string): Promise<any> => {
    const resetOrderApi = functions.httpsCallable(API_RESET_ORDER);
    const data = {
        restaurantId: restaurantID,
        sessionId: sessionID,
        menuId,
        paymentMethod: paymentMethod,
        channel:CHANNEL
    }
    try {
        const response = await resetOrderApi(data);
        console.log('@@@@resetOrder response');
        console.log(response);
        if (!!response && !!response.data) {
            return response.data;
        }
        return {};
    } catch (error) {
        return error;
    }

}

const requestBill = async(restaurantId: string, sessionId: string, type: string, askBillForTable: boolean = true) => {
    const requestBillApi = functions.httpsCallable(API_REQUEST_BILL);
    const data = {
        restaurantId,
        sessionId,
        type,
        askBillForTable,
        paymentMethod: "cash",
        channel:CHANNEL
    };
    try {
        const response = await requestBillApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from requestBill');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
    } catch (error) {
        throw error;
    }
}

const payBillWithStripe = async(restaurantId: string, billId: string, paymentMethod: string) => {
    const payStripeApi = functions.httpsCallable(API_PAY_STRIPE);
    const data = {
        restaurantId,
        billsId: [billId],
        payment_method: paymentMethod,
        channel:CHANNEL
    }
    try {
        const response = await payStripeApi(data);
        if (!!response && !!response.data) {
            if (_isSuccessResponse(response.data)) {
                console.log('@@@@@@@response from payStripe');
                console.log(response);
                return response.data;
            }
            throw _buildErrorFromResponse(response.data);
        }
    } catch (error) {
        throw error;
    }
}

const createPaymentTransaction = async(restaurantID: string, billID: string, tips: number, cardId: string|null, type: string, checked: boolean) => {
    const callable = functions.httpsCallable(API_CREATE_PAYMENT_TRANSACTION);
    const data = {
        restaurantId: restaurantID,
        billsId: [billID],
        cardId: cardId,
        channel:CHANNEL, 
        tips: tips,
        type: type,
        saveCard: checked
    }
    try {
        const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from createPaymentTransaction');
            console.log(response);
            return response.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const createPaymentTransactionList = async(restaurantID: string, billIDs: string[], tips: number, cardId: string|null, type: string, checked: boolean, token: string) => {
    //const callable = functions.httpsCallable(API_CREATE_PAYMENT_TRANSACTION);
    const data = {
        restaurantId: restaurantID,
        billsId: billIDs,
        cardId: cardId,
        channel:CHANNEL, 
        tips: tips,
        type: type,
        saveCard: checked
    }
    const url: string = `https://europe-west2-chuzeat-c-dev.cloudfunctions.net/${API_CREATE_PAYMENT_TRANSACTION}?jwtToken=`;
    try {
        const response = await axios.post(
            url + token,
            data,
        );
        //const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from createPaymentTransactionList');
            console.log(response);
            console.log(response.data.data);
            if(!!response.data.data){
                console.log(response.data.data);
            }
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const electronicPaymentMethod = async(restaurantID: string) => {
    const callable = functions.httpsCallable(API_ELECTRONIC_PAYMENT_METHODS);
    const data = {
        restaurantId: restaurantID,
    }
    try {
        const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from electronicPaymentMethod');
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

const confirmPayment = async(restaurantID: string, type: string, transactionId: string, billID: string, result: any) => {
    const callable = functions.httpsCallable(API_CONFIRM_PAYMENT);
    const data = {
        restaurantId: restaurantID,
        type: type,
        transactionId: transactionId,
        billsId: [billID],
        result: result
    }
    try {
        console.log(data);
        const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from confirmPayment');
            console.log(response);
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

const confirmPaymentList = async(restaurantID: string, type: string, transactionId: string, billIDs: string[], result: any, token: string) => {
    console.log('confirmPaymentList');
    const data = {
        restaurantId: restaurantID,
        type: type,
        transactionId: transactionId,
        billsId: billIDs,
        result: result
    }
    const url: string = `https://europe-west2-chuzeat-c-dev.cloudfunctions.net/${API_CONFIRM_PAYMENT}?jwtToken=`;
    try {
        const response = await axios.post(
            url + token,
            data,
        );
        //const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from confirmPaymentList');
            console.log(response);
            console.log(response.data.data);
            if(!!response.data.data){
                console.log(response.data.data);
            }
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const cancelPayment = async(restaurantID: string, type: string, transactionId: string, billID: string, result: any) => {
    const callable = functions.httpsCallable(API_CANCEL_PAYMENT);
    const data = {
        restaurantId: restaurantID,
        type: type,
        transactionId: transactionId,
        billsId: [billID],
        result: result
    }
    try {
        const response = await callable(data);
        console.log(response)
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from cancelPayment');
            console.log(response);
            return response.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const cancelPaymentList = async(restaurantID: string, type: string, transactionId: string, billIDs: string[], result: any, token: string) => {
    console.log('cancelPaymentList');
    const data = {
        restaurantId: restaurantID,
        type: type,
        transactionId: transactionId,
        billsId: billIDs,
        result: result
    }
    const url: string = `https://europe-west2-chuzeat-c-dev.cloudfunctions.net/${API_CANCEL_PAYMENT}?jwtToken=`;
    try {
        const response = await axios.post(
            url + token,
            data,
        );
        //const response = await callable(data);
        if (!!response && !!response.data) {
            console.log('@@@@@@@response from cancelPaymentList');
            console.log(response);
            console.log(response.data.data);
            if(!!response.data.data){
                console.log(response.data.data);
            }
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export {
    createDits,
    confirmSessionOrder,
    resetSessionOrder,
    validateAndRetrieveLinks,
    switchDitsToUser,
    requestBill,
    payBillWithStripe,
    validateQuidAndRetrieveMenus,
    createPaymentTransaction,
    electronicPaymentMethod,
    confirmPayment,
    cancelPayment,
    createPaymentTransactionList,
    confirmPaymentList,
    cancelPaymentList
}
