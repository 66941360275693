export type BillActions =
    |'REQUEST_BILL'
    |'UPDATE_BILL'
    |'REQUEST_BILL_ERROR'
    |'UPDATE_BILL_ERROR'
    |'GET_BILL'
    |'GET_BILL_ERROR';

export const BILL_CONSTANTS = {
    REQUEST_BILL: 'REQUEST_BILL',
    UPDATE_BILL: 'UPDATE_BILL',
    REQUEST_BILL_ERROR: 'REQUEST_BILL_ERROR',
    UPDATE_BILL_ERROR: 'UPDATE_BILL_ERROR',
    GET_BILL: 'GET_BILL',
    GET_BILL_ERROR: 'GET_BILL_ERROR'
}
