export type LinkActions =
    | 'VALIDATE_LINK'
    | 'VALIDATE_LINK_ERROR'
    | 'SELECT_LINK';

export const LINKS_CONSTANTS = {
    VALIDATE_LINK: 'VALIDATE_LINK',
    VALIDATE_LINK_ERROR: 'VALIDATE_LINK_ERROR',
    SELECT_LINK: 'SELECT_LINK',
    VALIDATE_QUID: 'VALIDATE_LINK',
    VALIDATE_QUID_ERROR: 'VALIDATE_LINK_ERROR',
    SELECT_QUID: 'SELECT_LINK'
}
