import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from "./reducers";
import ChuzeatWebApp from './app';
import './index.scss';

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
        <ChuzeatWebApp />
  </Provider>,
  document.getElementById('root')
);

/*
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <ChuzeatWebApp />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
*/
