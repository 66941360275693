import * as React from 'react';
import logo from './logo.png';
import './index.scss';
import {LocalizerHelper} from "../../helpers/localizerHelper";

const ChuzeatFooter = () => (
    <div className='footer_container'>
        <div className="row">
            <div className="col-md-12">
                <p className="collaboration-with">{LocalizerHelper.localized('collaboration_with')}</p>
                <img className="logo" src={logo}/>
            </div>
        </div>
    </div>
)

export default ChuzeatFooter;
