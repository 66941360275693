import { Meta } from ".";
import firebase from "firebase"
import DocumentReference = firebase.firestore.DocumentReference;

export interface AddOn {
    global?: boolean,
    max_size: number,
    meta?: Meta,
    min_size: number,
    options: Option[],
    pos: number,
    title: string,
    numChoice?: number
}

export interface Option {
    id: any,
    max_size: number,
    min_size: number,
    meta: Meta,
    name: string,
    price: number,
    tax_rate: number,
    title?: string,
    ref?: any,
}

const _isString = (p: any): p is string => typeof(p) === "string";
const _isDocumentRef = (p: any): p is DocumentReference => !!p.id;

export const _getOptionId = (option: Option):string => {
    let res: string = '';
    const ref = option.ref;
    const id = option.id;
    if(!!ref){
        if(_isDocumentRef(ref)){
            //console.log("isDocumentRef", ref);
            return ref.id;
        }else if(_isString(ref)){
            //console.log("isString", ref);
            return ref;
        }
    }
    if(!!id){
        if(_isDocumentRef(id)){
            //console.log("isDocumentRef", id);
            return id.id;
        }else if(_isString(id)){
            //console.log("isString", id);
            return id;
        }
    }
    return res;
}