import * as React from "react";
import {PropsWithChildren, useEffect} from "react";
import {CreatePaymentMethodCardData, PaymentIntentResult} from "@stripe/stripe-js"
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import {connect} from "react-redux";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import firebase from "firebase";
import Screen from '../screen';
import './index.scss';
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {getSession, listenForSession} from "../../actions/session.actions";
import {checkIfNotExists} from "../../helpers/validatorHelper";
import {COLLECTIONS, DITS_STATUS} from "../../constants/data";
import {LoaderGif, LoaderSpinner} from "../loader";
import {useHistory} from "react-router";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {formatPrice} from "../../helpers/formatHelper";
import ErrorDialog from "../dialogs/ErrorDialog";
import Carousel from 'react-material-ui-carousel'
import { ElectronicCard, ElectronicPaymentMethodType, IntentPayment, NexiPaymentMethod, RequiredParametersNexi, StripePaymentMethod } from "../../models/payments";
import ElectronicCardComponent from "./ElectronicCard";
import { cancelPayment, confirmPayment, createPaymentTransaction, electronicPaymentMethod } from "../../helpers/apiHelper";
import { SHA1 } from "crypto-js";
import { getRestaurant } from "../../actions/restaurant.actions";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

let unsubscribeFromListenForDits: firebase.Unsubscribe | null = null;

interface IPaymentProps extends PropsWithChildren<any> {
    restaurant: any,
    dits: DocumentSnapshot,
    linkAlias:string | null,
    listenForDits: (dispatch: any) => any,
    retrieveSession: (restaurantID: string, ditsID: string) => any,
    retrieveRestaurant: (restaurantID: string) => any,
}

const _Payment = ({match, restaurant, dits, linkAlias, listenForDits, retrieveRestaurant, retrieveSession}: IPaymentProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const history = useHistory();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingCards, setLoadingCards] = React.useState<boolean>(true);
    const [isInError, setIsInError] = React.useState(false);
    const [error, setError] = React.useState<any>(null);

    const [bill, setBill] = React.useState<DocumentSnapshot|null>(null);

    const [checked, setChecked] = React.useState(true);

    const [currentPaymentMethodId, setCurrentPaymentMethodId] = React.useState<string|null>(null);
    const [currentService, setCurrentService] = React.useState<string|null>(null);

    const [savedCards, setSavedCards] = React.useState<ElectronicCard[]|undefined|null>([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = React.useState<NexiPaymentMethod|StripePaymentMethod|undefined|null>(null);
    const [nexiPaymentMethod, setNexiPaymentMethod] = React.useState<NexiPaymentMethod|undefined|null>(null);
    const [stripePaymentMethod, setStripePaymentMethod] = React.useState<StripePaymentMethod|undefined|null>(null);
    const [intentPay, setIntentPay] = React.useState<IntentPayment|undefined|null>(null);

    const [addCard, setAddCard] = React.useState<boolean>(false);

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';

    useEffect(() => {
        _load();
        return () => {
            if (!!unsubscribeFromListenForDits) {
                unsubscribeFromListenForDits();
            }
        }
    }, []);

    const _load = () => {
        const rID = match?.params?.restaurant;
        const dID = match?.params?.dits;
        const bID = match?.params?.bill;
        if(!!bID){
            const billPath = `${COLLECTIONS.restaurants}/${rID}/${COLLECTIONS.dineInTableSitting}/${dID}/${COLLECTIONS.billLog}/${bID}`
            firebase.firestore().doc(billPath).onSnapshot(snapshot => {
                setBill(snapshot);
            })
        }
        const query = window.location.search;
        const searchParams: URLSearchParams = new URLSearchParams(query);
        if (!!window.performance) {
            console.log("!!window.performance", !!window.performance);
            console.log("window.performance.navigation.type", window.performance.navigation.type);
            if(window.performance.navigation?.type != null && window.performance.navigation?.type != undefined){
                switch(window.performance.navigation.type){
                    case window.performance.navigation.TYPE_BACK_FORWARD:
                        _cancelPayment(ElectronicPaymentMethodType.NEXI, query ?? '', match?.params?.restaurant, searchParams.get("codTrans") ?? "", match?.params?.bill);
                        _reload();
                        break;
                    case window.performance.navigation.TYPE_RELOAD:
                        _checkEsito(searchParams, query, rID, dID);
                        _reload();
                        break;
                    default:
                        _checkEsito(searchParams, query, rID, dID);
                        _reload();
                        break;
                }
            }else{
                const entries: any = performance.getEntriesByType('navigation');
                console.log('entries', entries);
                for (const e of entries){
                    console.log(e);
                    switch(e.type){
                        case 'back_forward':
                            _cancelPayment(ElectronicPaymentMethodType.NEXI, query ?? '', match?.params?.restaurant, searchParams.get("codTrans") ?? "", match?.params?.bill);
                            _reload();
                            break;
                        case 'reload':
                            _checkEsito(searchParams, query, rID, dID);
                            _reload();
                            break;
                        default:
                            _checkEsito(searchParams, query, rID, dID);
                            _reload();
                            break;
                    }
                }
            }

          }
    }

    const _checkEsito = (searchParams: URLSearchParams, query: string, rID:string, dID:string) => {
        if(searchParams.has("esito")){
            switch(searchParams.get("esito")){
                case "OK":
                    setLoading(true);
                    _confirmPayment(ElectronicPaymentMethodType.NEXI, query, match?.params?.restaurant, searchParams.get("codTrans") ?? "", match?.params?.bill);
                    retrieveSession(rID, dID);
                    break;
                case "ANNULLO":
                    _cancelPayment(ElectronicPaymentMethodType.NEXI, query, match?.params?.restaurant, searchParams.get("codTrans") ?? "", match?.params?.bill);
                    _reload();
                    break;
                default:
                    _showErrorMessage(LocalizerHelper.localized('error') + " " + searchParams.get("codiceEsito"));
                    _cancelPayment(ElectronicPaymentMethodType.NEXI, query, match?.params?.restaurant, searchParams.get("codTrans") ?? "", match?.params?.bill);
                    _reload();
                    break;
            }
        }
    }

    const _reload = async (rID = match?.params?.restaurant, dID = match?.params?.dits, bID = match?.params?.bill) => {
        console.log("_reload");
        if (!restaurant || !dits) {
            retrieveRestaurant(rID);
            retrieveSession(rID, dID);
        }
    }

    useEffect(() => {
        console.log("useEffect_dits");
        if (!!dits) {
            console.log("useEffect_dits exists");
            if (!checkIfNotExists(unsubscribeFromListenForDits)) {
                unsubscribeFromListenForDits = listenForDits(dits?.ref);
            }
            console.log("dits.exists: ", dits.exists);
            console.log("dits?.data()?.status: ", dits?.data()?.status);
            if (dits.exists && dits?.data()?.status === DITS_STATUS.paid) {
                setLoading(false);
                setLoadingCards(false);
                console.log("@@@@@@@@ Payment useEffect[dits]");
                const alias = linkAlias ?? lastLinkAlias;
                console.log("call payment effect")
                history.push(`/menu/${alias}/thanks`);
            }
        }
    }, [dits]);

    useEffect(() => {
    }, [savedCards]);

    useEffect(() => {
        console.log("useEffect bill: ", bill);
    }, [bill])

    useEffect(() => {
        _getPaymentMethods();
    }, [restaurant])

    useEffect(() => {
        if (!!intentPay) {
            switch(currentService){
                case ElectronicPaymentMethodType.NEXI:
                    _confirmPaymentIntentNexi();
                    break;
                case ElectronicPaymentMethodType.STRIPE:
                    _confirmPaymentIntentStripe();
                    break;
            }
        }
    }, [intentPay]);

    const _showErrorMessage = (error: any) => {
        setLoading(false);
        setIsInError(true);
        setError(error);
    }

    const _closeErrorDialog = () => {
        setIsInError(false);
        setError(null);
    }

    const _getPaymentMethods = async () => {
        if(!!restaurant && !!restaurant.id){
            try{
                const result:any[] = await electronicPaymentMethod(restaurant.id);
                console.log(result);
                let cards: ElectronicCard[] = new Array<ElectronicCard>();
                for(const paymentMethod of result){
                    switch (paymentMethod['type']){
                        case ElectronicPaymentMethodType.NEXI:
                            const nexiMethod: NexiPaymentMethod = paymentMethod;
                            setNexiPaymentMethod(nexiMethod);
                            for (const card of nexiMethod.cards){
                                cards.push(card);
                            }
                            break;
                        case ElectronicPaymentMethodType.STRIPE:
                            const stripeMethod: StripePaymentMethod = paymentMethod;
                            setStripePaymentMethod(stripeMethod);
                            for (const card of stripeMethod.cards){
                                cards.push(card);
                            }
                            break;
                    }
                }
                setLoadingCards(false);
                setSavedCards(cards);
                if(!!result && result.length>0){
                    for(const method of result){
                        if(!!method && method['main']){
                        //if(!!method && !method['main']){
                            setDefaultPaymentMethod(method);
                            return;
                        }
                    }
                    setDefaultPaymentMethod(result[0]);
                    return;
                }
                setDefaultPaymentMethod(null);
                return;
            }catch (error){
                _showErrorMessage(error);
                // TODO what to do with error in get payments method
                _getPaymentMethods();
            }
        }
    }

    const _handleSubmitStripe = async (event: React.FormEvent<HTMLFormElement>) => {
        //setLoading(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        try {
            const cardNumberElement = elements.getElement(CardNumberElement);
            if (!!cardNumberElement) {
                const paymentMethodData: CreatePaymentMethodCardData = {
                    type: 'card',
                    card: cardNumberElement,
                };
                const {error, paymentMethod} = await stripe.createPaymentMethod(paymentMethodData);
                if (error) {
                    console.log('[error]', error);
                    _showErrorMessage(error);
                } else {
                    if(!!paymentMethod){
                        _createPaymentTransaction(paymentMethod.id, ElectronicPaymentMethodType.STRIPE);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            _showErrorMessage(error);
        }
    };

    const _handleSubmitNexi = async () => {
        _createPaymentTransaction(null, ElectronicPaymentMethodType.NEXI);
    }

    const _createPaymentTransaction = async (paymentMethodId: string|null, service:string) =>{
        setLoading(true);
        setCurrentPaymentMethodId(paymentMethodId);
        setCurrentService(service);
        try{
            if (!!restaurant && !!bill && !!service) {
                const result: IntentPayment|null = await createPaymentTransaction(restaurant.id, bill.id, 0.0, paymentMethodId, service, checked);
                if(!!result?.error){
                    _showErrorMessage(result.error);
                }else{
                    setIntentPay(result);
                }
            }else{
                _showErrorMessage(LocalizerHelper.localized('error'));
            }
        }catch (error){
            console.log(error);
            _showErrorMessage(error);
        }

    }

    const _confirmPaymentIntentStripe = async () => {
        if (!!intentPay && !!currentPaymentMethodId) {
            try {
                if(!!stripe){
                    const response: PaymentIntentResult= await stripe.confirmCardPayment(intentPay.data?.clientSecret, {
                        payment_method: currentPaymentMethodId
                    });
                    const retrievedPaymentIntent = response.paymentIntent;
                    const stripeError = response.error;
                    console.log('@@@@@@paymentIntentResult');
                    console.log(retrievedPaymentIntent);
                    console.log(stripeError);
                    if (!!retrievedPaymentIntent) {
                        if (retrievedPaymentIntent?.status !== "succeeded") {
                            _showErrorMessage(retrievedPaymentIntent?.last_payment_error);
                            _cancelPayment(ElectronicPaymentMethodType.STRIPE, response);
                            return;
                        }
                        _confirmPayment(ElectronicPaymentMethodType.STRIPE, response);
                        return;
                    } else {
                        if (!!stripeError) {
                            _showErrorMessage(stripeError);
                        }
                        _cancelPayment(ElectronicPaymentMethodType.STRIPE, response);
                    }
                }else{
                    _cancelPayment(ElectronicPaymentMethodType.STRIPE, null);
                }
            } catch (error) {
                _showErrorMessage(error);
                _cancelPayment(ElectronicPaymentMethodType.STRIPE, error);
            }
        }
        else{
            _showErrorMessage(LocalizerHelper.localized('error'));
        }
        setLoading(false);
    }

    const _confirmPaymentIntentNexi = async () => {
        if (!!intentPay && !!nexiPaymentMethod) {
            try {
                const url = "https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet";
                const mac_str: string = encodeURI('codTrans=' + intentPay.transactionId + 'divisa=' + nexiPaymentMethod.concurrency + 'importo=' + intentPay.amount + nexiPaymentMethod.secret_key)
                const mac = SHA1(mac_str);
                const param: RequiredParametersNexi = {
                    alias: nexiPaymentMethod.alias,
                    importo: intentPay.amount,
                    divisa: intentPay.data.concurrency,
                    codTrans: intentPay.transactionId,
                    url: window.location.href.split('?')[0],
                    url_back: window.location.href.split('?')[0],
                    urlpost: intentPay.data.urlpost,
                    mac: mac,
                    num_contratto: intentPay.data.num_contratto,
                    tipo_richiesta: intentPay.data.tipo_richiesta,
                    tipo_servizio: intentPay.data.tipo_servizio,
                    gruppo: intentPay.data.group,
                }
                const query: string = _transformToQuery(param);
                window.location.href = url + query;
                //setLoading(false);
            } catch (error) {
                _showErrorMessage(error);
            }
        }
    }

    const _transformToQuery = (params: RequiredParametersNexi): string => {
        let str: string = '?';
        for(const [key, value] of Object.entries(params)){
            str = str + key + '=' + encodeURIComponent(value) + '&'
        }
        return str;
    }

    const _confirmPayment = async (type: string,  result: any, restaurantID: string = restaurant.id, transactionID: string|undefined = intentPay?.transactionId, billID:string = bill?.id ?? "") => {
        await confirmPayment(restaurantID, type, transactionID ?? "", billID, result)
    }

    const _cancelPayment = async (type: string,  result: any, restaurantID: string = restaurant.id, transactionID: string|undefined = intentPay?.transactionId, billID:string = bill?.id ?? "") => {
        await cancelPayment(restaurantID, type, transactionID ?? "", billID, result)
    }

    const handleChange = () => {
        setChecked(!checked);
    };

    const noAmount = (bill?.data()?.amount ?? 0) === 0;

    return (
    <Screen className="screen payment_screen">
        {loading ?
            <LoaderGif  logo={null} image="/assets/loading_check_payment.gif" message={LocalizerHelper.localized('loading_msg_check_payment')} /> : 
            <div>
                <RestaurantHeader className="block" showBack={false} restaurant={restaurant}/>
                <div className="container label_total_payment">
                    <div className="row">
                        <div className="col-6 text-left">
                            <span className="total-order-price-label">{LocalizerHelper.localized('total_order_price_label')}</span>
                        </div>
                        <div className="col-6 text-right">
                            <span className="total-order-price">{formatPrice(bill?.data()?.amount)}</span>
                        </div>
                    </div>
                </div>

                {(loadingCards) ? LoaderSpinner() : 
                    (!!savedCards && savedCards.length > 0 && !addCard) ?
                        <div>
                            <Carousel autoPlay={false} animation={'slide'} swipe={true} navButtonsAlwaysVisible={false}>
                                {savedCards.map((item: ElectronicCard, index: number) => <ElectronicCardComponent key={index} card={item} onClick={() => {_createPaymentTransaction(item.id, item.service)}}/>)}
                            </Carousel>
                        </div>
                        :null
                }

                {(!!defaultPaymentMethod && (defaultPaymentMethod.type === ElectronicPaymentMethodType.STRIPE )) ?
                    <div className="container">
                        {(!!savedCards && (savedCards.length === 0 || addCard)) ?
                            <form onSubmit={_handleSubmitStripe}>
                                <div className="row form-row">
                                    <div className="col-12 text-left label_form_payment">
                                        <label>{LocalizerHelper.localized('card_number')}</label>
                                    </div>
                                    <div className="col-12">
                                        <CardNumberElement className="card-number-element"/>
                                    </div>
                                </div>
                                <div className="row form-row">
                                    <div className="col-7 text-left label_form_payment">
                                        <label>{LocalizerHelper.localized('card_expire')}</label>
                                        <CardExpiryElement className="card-expiry-element"/>
                                    </div>
                                    <div className="col-5 text-left label_form_payment">
                                        <label>{LocalizerHelper.localized('card_cvc')}</label>
                                        <CardCvcElement className="card-cvc-element"/>
                                    </div>
                                </div>
                                <div className="row form-row justify-content-center">
                                    <div className="col-12 text-left label_form_payment">
                                        <FormControlLabel control={<Checkbox style={{color:"#fe3436"}}  onChange={handleChange} checked={checked} size="medium"/>} label={LocalizerHelper.localized('save_card')} />
                                    </div>
                                </div>
                                <div className="row container_buttons_action justify-content-center">
                                    { (!!savedCards && savedCards.length > 0) ?
                                        <div className="col-md-5">
                                            <Button className="accent-button full-width" onClick={() => {setAddCard(false)}} ref={(node) => {
                                                    if (node) {
                                                        node.style.setProperty("background", "#282c34", "important");
                                                    }}}>
                                                {LocalizerHelper.localized('cancel')}
                                            </Button>
                                        </div> : null
                                    }
                                    <div className="col-md-5">
                                        <Button className="accent-button full-width" type="submit" disabled={!stripe || loading || noAmount}>
                                            {LocalizerHelper.localized('pay')}
                                        </Button>
                                    </div>
                                </div>
                            </form> : 
                            <div className="row container_buttons_action justify-content-center">
                                <div className="col-md-10">
                                    <Button className="accent-button full-width" onClick={() => {setAddCard(true)}}>
                                        {LocalizerHelper.localized('add_card')}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                    : null
                }
                {(!!defaultPaymentMethod && (defaultPaymentMethod.type === ElectronicPaymentMethodType.NEXI )) ?
                    <div>
                        <div className="row form-row justify-content-center">
                            <div className="col-10 text-left label_form_payment">
                                <FormControlLabel control={<Checkbox style={{color:"#fe3436"}}  onChange={handleChange} checked={checked} size="medium"/>} label={LocalizerHelper.localized('save_card')} />
                            </div>
                        </div>
                        <div className="row container_buttons_action justify-content-center">
                            <div className="col-10">
                                <Button className="accent-button full-width" onClick={_handleSubmitNexi}>
                                    {LocalizerHelper.localized('proceed_with_new_payment')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        }
        <ErrorDialog open={isInError} error={error} handleClose={_closeErrorDialog}/>
        <script>
        (function () {
            window.onpageshow = function(event: any) {
                if (event.persisted) {
                    const query = window.location.search;
                    const searchParams: URLSearchParams = new URLSearchParams(query);
                    const codTrans = searchParams.get('codTrans');
                    window.location.href = window.location.href.split('?')[0] + '?esito=ANNULLO&codTrans=' + codTrans;
                }
            }
        })();)
        </script>
    </Screen>
    )
}

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant,
    dits: state.session.session,
    paymentError: state.payment.error,
    ditsMenuItems: state.session?.ditsMenuItems,
    paymentIntent: state.payment.paymentIntent ?? null,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDits: listenForSession(dispatch),
    retrieveSession: async (restaurantID: string, ditsID: string) => dispatch(await getSession(restaurantID, ditsID)),
    retrieveRestaurant: async (restaurantID: string) => dispatch(await getRestaurant(restaurantID)),
});

const Payment = connect(mapStateToProps, mapDispatchToProps)(_Payment);

export default Payment;
