import * as React from "react";
import firebase from "firebase";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {Button, Typography, TextField} from "@material-ui/core";
import {Remove as IconRemove} from "@material-ui/icons"
import {Add as IconAdd} from "@material-ui/icons";
import {Close as IconClose} from "@material-ui/icons";

import Carousel from "react-material-ui-carousel";
import {useEffect} from "react";
import {getMenuItemIngredients} from "../../actions/menu_item.actions";
import {connect} from "react-redux";
import {DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS} from "../../constants/data";
import {formatMenuItemPrice} from "../../helpers/menuItemHelper";
import './index.scss';
import { AddOn } from "../../models/menu";
import MenuItemSupplements from "./MenuItemSupplements";

import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import DocumentReference = firebase.firestore.DocumentReference;

interface IMenuItemDetailProps {
    menuItem: DocumentSnapshot | null,
    canAdd: boolean,
    addItem: (menuItem: DocumentSnapshot | null, qty: number, addOns: any[], minus: any[], notes: string) => void,
    cookingIngredients: DocumentSnapshot[],
    retrieveMenuItemCookingIngredients: (menuItem: DocumentReference) => any
    qty?: number,
    readonly?:boolean,
    quid?:string,
    canBeOrdered?:boolean,
}

const MenuItemMinusIngredients = ({menuItem, isSelectedMinus, toggleMinus}: {
    menuItem: DocumentSnapshot | null,
    isSelectedMinus: (item: any) => boolean,
    toggleMinus: (item: any) => any
}) => {
    const minus = menuItem?.data()?.minus ?? [];


    const _buildSingleMinus = (ingredient: any) => {
        const isMinusSelected = isSelectedMinus(ingredient);
        return (<div key={`minus-${ingredient.id?.path}`} className={`menu-item-minus-item ${isMinusSelected ? 'selected' : ''}`}
                     onClick={() => toggleMinus(ingredient)}>
                <span>
                    {LocalizerHelper.localized(ingredient, 'title') ?? LocalizerHelper.localized(ingredient, 'name')}
                </span>
            {isMinusSelected ?
                <IconAdd/>
                : <IconClose/>
            }
        </div>)
    };
    const minusItems = minus.map((item: any) => _buildSingleMinus(item));
    return (<div className="row menu-item-minus-row">
        <div className="col-12">
            {minusItems}
        </div>
    </div>);
}

const _MenuItemDetail = ({
    menuItem,
    addItem,
    qty,
    canAdd,
    cookingIngredients,
    retrieveMenuItemCookingIngredients,
    readonly = false,
    quid,
    canBeOrdered,
}: IMenuItemDetailProps) => {

    const isNotAvailable = menuItem?.data()?.status === DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS.na;

    const headerClassName = isNotAvailable ? 'menu-item-header not-available' : 'menu-item-header';

    const hasImages = (menuItem?.data()?.media ?? []).length > 0;

    const hasAllergens = (menuItem?.data()?.allergens ?? []).length > 0;

    const [quantity, setQuantity] = React.useState(qty ?? 1);

    const [addOns, setAddOns] = React.useState<AddOn[]>([]);

    const [minus, setMinus] = React.useState<any[]>([]);

    const [notes, setNotes] = React.useState('');

    const [allergens, setAllergens] = React.useState<any[]>([]);

    const [hasAddOns, setHasAddOns] = React.useState(false);
    const [hasMinus, setHasMinus] = React.useState(false);

    useEffect(() => {
        if (!!menuItem) {
            const allAllergens = JSON.parse(firebase.remoteConfig().getString('allergens_v2'));
            setAllergens(allAllergens);
            setHasAddOns((menuItem?.data()?.add_ons ?? []).length > 0);
            setHasMinus((menuItem?.data()?.minus ?? []).length > 0);
            retrieveMenuItemCookingIngredients(menuItem?.ref);
        }
    }, [menuItem]);

    const _isEqualMinus = (minus1: any, minus2: any) => {
        return minus1?.name === minus2?.name && JSON.stringify(minus1?.meta ?? {}) === JSON.stringify(minus2?.meta ?? {}) && minus1?.id?.path === minus2?.id?.path;
    }

    const isSelectedMinus = (ingredient: any) => {
        if (minus === null || minus === undefined || minus.length === 0) {
            return false;
        }
        const minusIndex = minus?.findIndex((item) => _isEqualMinus(ingredient, item));
        return minusIndex > -1;
    }

    const toggleMinus = (ingredient: any) => {
        if (minus === null || minus === undefined || minus.length === 0) {
            setMinus([ingredient]);
            return;
        }
        const minusIndex = minus?.findIndex((item) => _isEqualMinus(ingredient, item));
        const isSelectedMinus = minusIndex > -1;
        if (isSelectedMinus) {
            const newMinus = [...minus];
            newMinus.splice(minusIndex, 1);
            setMinus(newMinus);
        } else {
            setMinus([...minus, ingredient]);
        }
    }

    const _addItem = () => {
        if (!!addItem) {
            if(_checkAddOns()){
                let list: AddOn[] = [];
                for(const item of addOns){
                    if(item.options.length > 0){
                        list.push(item);
                    }
                }
                addItem(menuItem, quantity ?? 1, list, minus, notes);
            }   
        }
    }

    const _checkAddOns = ():boolean => {
        if (!!addOns) {
            for (const addOn of addOns){
                if(!!!addOn.global){
                    if(addOn.numChoice != undefined && ((addOn.min_size ?? 0) > addOn.numChoice || ((addOn.max_size ?? 1) < addOn.numChoice && (addOn.max_size ?? 1) != 0))){
                        alert(LocalizerHelper.localized("error_options_selected"))
                        return false;
                    }
                }
                else{
                    if((addOn.min_size ?? 0) > addOn.options.length || ((addOn.max_size ?? 1) < addOn.options.length && (addOn.max_size ?? 1) != 0)) {
                        alert(LocalizerHelper.localized("error_options_selected"))
                        return false;
                    }
                }
                /*for(const option of addOn?.options){
                    if(option.min_size > option.quantity || addOn.max_size < options.length){

                    }
                }*/
            }
        }
        console.log('_checkAddOns true')
        return true
    }

    const _allergenLabel = (allergenID: string) => {
        if (allergens === null || allergens === undefined || allergens.length === 0) return allergenID;
        const selectedAllergen = allergens.find((item: any) => item.id === allergenID);
        if (!!selectedAllergen) {
            return LocalizerHelper.localizeObject(selectedAllergen);
        }
        return allergenID;
    }

    const filterIngredients = () => {
        const minus = (menuItem?.data()?.minus ?? []).map((item: any) => item.id?.path);
        const filteredIngredients = minus === null || minus === undefined || minus.length === 0 ? [...cookingIngredients] : (cookingIngredients ?? []).filter((item) => !minus.includes(item?.data()?.cooking_ingredient?.path));
        return filteredIngredients;
    }

    const filteredIngredients = filterIngredients();
    const extraIngredients = (menuItem && menuItem?.data() && menuItem?.data()?.ingredients_description) ? (', ' + (menuItem?.data()?.ingredients_description ?? "").toLowerCase()) : '';

    return (<div style={{height: '100%'}}>
        <div className="menu-item-detail-container">
            <Carousel>
                        {menuItem?.data()?.media?.map((item: any, index: number) => (
                            <img className='carousel-image' src={item.url} key={`carousel-image-${index}`}/>
                        ))}
            </Carousel>
            <div className="container">
            <div className="row" style={{marginTop: !hasImages ? 60 : 20}}>
                <div className="col-8">
                    <Typography color="textSecondary" gutterBottom align="left" className={headerClassName}>
                        {LocalizerHelper.localized(menuItem?.data(), 'name')}
                    </Typography>
                </div>
                <div className="col-4 text-right">
                    <Typography color="textSecondary" gutterBottom align="right" className={headerClassName}>
                        {formatMenuItemPrice(menuItem)}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-left" style={{whiteSpace:"pre-line"}}>
                    {LocalizerHelper.localized(menuItem?.data(), 'description')}
                </div>
            </div>
            <div className="row" style={{marginTop: 15}}>
                <div className="col-12">
                    <Typography variant="h5" gutterBottom className="menu-item-sub-header"
                                align="left">{LocalizerHelper.localized('ingredients')}</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {filteredIngredients?.map((item) => LocalizerHelper.localized(item?.data(), 'name'))?.join(', ')}
                    {extraIngredients}
                </div>
            </div>
            {hasAddOns ?
                <div>
                    <div className="row" style={{marginTop: 15}}>
                        <div className="col-9">
                            <Typography variant="h5" gutterBottom className="menu-item-sub-header"
                                        align="left">{LocalizerHelper.localized('supplements')}</Typography>
                        </div>
                    </div>
                    <MenuItemSupplements menuItem={menuItem} insertAddOns={(addOns: AddOn[]) => {setAddOns(addOns)}}/>
                </div> : null
            }
            {!readonly && hasMinus ?
                <div>
                    <div className="row" style={{marginTop: 15}}>
                        <div className="col-12">
                            <Typography variant="h5" gutterBottom className="menu-item-sub-header"
                                        align="left">{LocalizerHelper.localized('minus_ingredients')}</Typography>
                        </div>
                    </div>
                    <MenuItemMinusIngredients menuItem={menuItem} isSelectedMinus={isSelectedMinus}
                                              toggleMinus={toggleMinus}/>
                </div> : null
            }
            { !readonly ?
            <div className="row" style={{marginTop: 15}}>
                <div className="col-12">
                    <TextField
                        variant="outlined"
                        name="notes"
                        rows={4}
                        value={notes}
                        fullWidth={true}
                        multiline={true}
                        label={LocalizerHelper.localized('notes')}
                        onChange={(event) => setNotes(event.target.value)}>

                    </TextField>
                </div>
            </div> : null
            }
            {hasAllergens ?
                <div>
                    <div className="row" style={{marginTop: 15}}>
                        <div className="col-12">
                            <Typography variant="h5" gutterBottom className="menu-item-sub-header"
                                        align="left">{LocalizerHelper.localized('allergens')}</Typography>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 15}}>
                        <div className="col-12 text-left">
                            {menuItem?.data()?.allergens?.map((item: string) => <div key={`detail-allergen-${item}`} className="allergen-container"><img
                                    src={`/assets/icons/allergens/icon_allergen_${item.toLowerCase()}.png`}
                                    className={'allergen-icon'} key={`allergen-icon-${item}`}/>
                                    <span>{_allergenLabel(item)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div> : null
            }
        </div>
        {!isNotAvailable && canAdd ?
            <div className="bottom-fixed-row menu-item-qty-row">
                <div className="row qty-container">
                    <div className="col-6 qty-action-quantity">
                        <Button className={`qty-action-button ${quantity === 1 ? 'disabled' : ''}`}
                                disabled={quantity === 0}
                                onClick={() => setQuantity((quantity) => quantity - 1)}>
                            <IconRemove className="qty-action-button-icon" htmlColor={"#ffffff"}/>
                        </Button>
                        <span className="qty-label">
                                {quantity}
                        </span>
                        <Button className='qty-action-button'
                                onClick={() => setQuantity((quantity) => quantity + 1)}>
                            <IconAdd className="qty-action-button-icon" htmlColor={"#ffffff"}/>
                        </Button>
                    </div>
                    <div className="col-6">    
                        <Button className="accent-button qty-add-action-button"
                                onClick={() => _addItem()}>
                            {LocalizerHelper.localized('add')}
                        </Button>
                    </div>
                </div>
            </div> : null
        }
        {canBeOrdered ? null
                         :
                    <div className="sticky-footer row">
                        <p className='col-12'>{LocalizerHelper.localized('generic_actions')}</p>
                        <div className="btn btn-continue-web col-5 center">
                            <a target="_blank" href={`https://app.chuzeat.com?quid=${quid}`}>
                                {LocalizerHelper.localized('continue_web')}
                            </a>
                        </div>
                        <div className="btn btn-download-app col-6 center">
                            <a target="_blank" href={`https://chuze.at/t/${quid}?redirectToStore=true`}>
                                <img  src="/assets/icon_download.svg" alt="download"/>
                                {LocalizerHelper.localized('download_app')}
                            </a>
                        </div>
                    </div>  }
        </div>
    </div>);
}

const mapStateToProps = (state: any) => ({
    cookingIngredients: state.menuItem?.cookingIngredients ?? []
})

const mapDispatchToProps = (dispatch: any) => ({
    retrieveMenuItemCookingIngredients: async (menuItem: DocumentReference) => dispatch(await getMenuItemIngredients(menuItem))
});

const MenuItemDetail = connect(mapStateToProps, mapDispatchToProps)(_MenuItemDetail);

export default MenuItemDetail;
