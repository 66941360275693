import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as it from '../locales/it.json';
import * as en from '../locales/en.json';
import * as de from '../locales/de.json';
import * as es from '../locales/es.json';
import * as fr from '../locales/fr.json';
import * as ru from '../locales/ru.json';
import * as zh from '../locales/zh.json';

let language = ((navigator.languages ? navigator.languages[0] : navigator.language) ?? 'en');
if (!!language) {
    language = language?.split('-')[0];
}

i18n
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                translation: en,
            },
            it: {
                translation: it,
            },
            de: {
                translation: de,
            },
            es: {
                translation: es
            },
            fr: {
                translation: fr
            },
            ru: {
                translation: ru
            },
            zh: {
                translation: zh
            }
        },
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
    });

const localizeFirestoreItem = (item: any, key?: string, lang: string = language) => {
    const defaultLanguage = LocalizationData.getInstance().defaultLanguage;
    if (!key) return null;
    if (!item) return null;
    if(!!item.meta){
        if(!!item.meta[lang] && !!item.meta[lang][key]){
            return item.meta[lang][key];
        }
        if(!!item.meta[defaultLanguage] && !!item.meta[defaultLanguage][key]){
            return item.meta[defaultLanguage][key];
        }
        return item[key] ?? null;
    }
    
    return item[key] ?? null;
}

const localizeObject = (item: any, lang: string = language) => {
    if (!item) return null;
    if (!item[lang]) return null;
    return item[lang] ?? item[LocalizationData.getInstance().defaultLanguage] ?? '';
}

const localizeString = (item: string) => {
    return i18n.t(item);
}

const localizeMethods: any = {
    'string': localizeString,
    'default': localizeFirestoreItem
}

class LocalizationData{
    private static instance: LocalizationData;
    private constructor() {}

    public defaultLanguage = language;

    public static getInstance(): LocalizationData {
        if (!LocalizationData.instance) {
            LocalizationData.instance = new LocalizationData()
        }        
        return LocalizationData.instance;
    }


}

class LocalizerHelper {

    static localized(item: any, key?: string, lang: string = LocalizationData.getInstance().defaultLanguage) {
        let itemType: string = typeof item;
        let localizeMethod: any = localizeMethods[itemType];
        if (!!localizeMethod) {
            return localizeMethod(item, key, lang);
        }
        const translate = localizeMethods['default'](item, key, language) ?? null;
        if(!!translate && translate !== ''){
            return translate;
        }
        return localizeMethods['default'](item, key, lang);
    }

    static localizeObject(item: any, lang: string = language) {
        return localizeObject(item, lang);
    }

    static getLanguage() {
        return language;
    }
}

export {LocalizerHelper,LocalizationData}
