import {FirestoreHelper} from "../helpers/firestoreHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {SESSION_CONSTANTS} from "../constants/session.constants";
import {
    ALL_PAYMENT_METHODS,
    COURSES, DEFAULT_ALLOWED_PAYMENT_METHODS,
    DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS,
    DISH_CATEGORIES,
    DISH_CATEGORY_TO_COURSE, PAYMENT_METHODS
} from "../constants/data";
import firebase from "firebase";
import {confirmSessionOrder, createDits, switchDitsToUser} from "../helpers/apiHelper";
import {checkIfNotEmpty} from "../helpers/validatorHelper";
import {LocalizerHelper} from "../helpers/localizerHelper";
import { TimeSlot } from "../models";
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Timestamp = firebase.firestore.Timestamp;
import DocumentData = firebase.firestore.DocumentData;

const getLastSession = async (userID: string) => {
    try {
        const session = await FirestoreHelper.lastActiveSessionByUser(userID);
        return buildActionResult(SESSION_CONSTANTS.RETRIEVE_LAST_SESSION, {
            session
        });
    } catch (error) {
        throw error;
    }
}

const getSession = async (restaurantID: string, ditsID: string) => {
    try {
        const session = await FirestoreHelper.sessionByID(restaurantID, ditsID);
        return buildActionResult(SESSION_CONSTANTS.RETRIEVE_SESSION, {
            session,
        });
    } catch (error) {
        return buildActionResult(SESSION_CONSTANTS.RETRIEVE_SESSION_ERROR, {
            error
        });
    }
}

const deleteSession = async(sessionId:string) => {

}

const createNewSession = async (restaurantId: string, link: string) => {
    try {
        console.log("@@@@ createNewSession");
        const sessionID: any = await createDits(link);
        console.log(sessionID);
        if (!!sessionID) {
            const session = await FirestoreHelper.sessionByID(restaurantId, sessionID);
            console.log(session);
            return buildActionResult(SESSION_CONSTANTS.CREATE_NEW_SESSION, {
                session,
            });
        }
    } catch (error) {
        console.log(error);
        return buildActionResult(SESSION_CONSTANTS.CREATE_NEW_SESSION_ERROR, {
            error
        });
    }
}


const deleteItemsFromSession = async (items: DocumentSnapshot[]) => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        try {
            await FirestoreHelper.deleteItemFromSession(item?.ref);
        } catch (error) {
            console.log(error);
        }
    }
    return buildActionResult(SESSION_CONSTANTS.ADD_ITEMS_TO_SESSION, {})
}

const addItemsToSession = (dispatch: any) => async (ditsRef: DocumentReference, chosenBy: DocumentReference, menuItem: DocumentSnapshot, addOns: any[] = [], minus: any[] = [], notes: string = '', qty: number = 1) => {
    console.log("addItemsToSession");
    dispatch(buildActionResult(SESSION_CONSTANTS.ADD_ITEMS_TO_SESSION, {
        loading: true
    }));
    const ditsMenuItem: DocumentData = {
        ...menuItem?.data(),
        name: LocalizerHelper.localized(menuItem?.data(), 'name'),
        add_ons: addOns ?? [],
        minus: minus ?? [],
        menu_item: menuItem?.ref,
        course: DISH_CATEGORY_TO_COURSE[menuItem?.data()?.category] ?? COURSES.asap,
        status: DINE_IN_TABLE_SITTINGS_MENU_ITEMS_STATUS.chosen,
        note: notes,
        sale_comment: "",
        ordered_at: null
    }
    ditsMenuItem.chosen_at = Timestamp.now();
    ditsMenuItem.chosen_by = chosenBy;
    delete ditsMenuItem.category;
    delete ditsMenuItem.diets;
    delete ditsMenuItem.ingredients;
    try{
        if(!!ditsMenuItem.tax_rate && ditsMenuItem.tax_rate instanceof DocumentReference){
            const taxRateSnap: DocumentSnapshot = await ditsMenuItem.tax_rate.get();
            if(!!taxRateSnap && taxRateSnap.exists){
                const tax_rate = taxRateSnap.data()?.value;
                console.log(`tax_rate: ${tax_rate}`);
                ditsMenuItem.tax_rate = tax_rate;
            }
        }
    }catch(e){
        console.log("error in get tax_rate");
        console.log(e);
    }
    try {
        await FirestoreHelper.addItemsToSession(ditsRef, ditsMenuItem, qty);
    } catch (error) {
        console.error(error);
    }
    dispatch(buildActionResult(SESSION_CONSTANTS.ADD_ITEMS_TO_SESSION, {
        loading: false
    }));
}

const listenForSessionMenuItems = (dispatch: any) => (ditsRef: DocumentReference) => {
    try {
        return FirestoreHelper.ditsMenuItemSnapshots(ditsRef)
            .onSnapshot(snapshot => {
                if (!!snapshot) {
                    const ditsMenuItems = snapshot.docs;
                    dispatch(buildActionResult(SESSION_CONSTANTS.UPDATE_DITS_MENU_ITEMS, {
                        ditsMenuItems
                    }))
                }
            });
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.UPDATE_DITS_MENU_ITEMS_ERROR, {
            error
        });
    }
}

const listenForSession = (dispatch: any) => (ditsRef: DocumentReference) => {
    try {
        return ditsRef?.onSnapshot(snapshot => {
            if (!!snapshot && snapshot.exists) {
                const session = snapshot;
                dispatch(buildActionResult(SESSION_CONSTANTS.UPDATE_SESSION, {
                    session
                }))
            }
        });
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.UPDATE_SESSION_ERROR, {
            error
        });
    }
}

const addTimeSlotToSession = async (ditsRef: DocumentReference,slotSelected:TimeSlot | undefined | null,saved:Boolean = false) => {
    try {
        if(saved){
            console.log("@@@ save addTimeSlotToSession");
            await FirestoreHelper.addTimeSlotToDits(ditsRef,slotSelected);
        }
        console.log("@@@@ addPaymentMethodToSession");
        console.log(slotSelected);
        console.log(saved);
        return buildActionResult(SESSION_CONSTANTS.ADD_TIME_SLOT_TO_DITS, {
            slotSelected
        });
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.ADD_TIME_SLOT_TO_DITS_ERROR, {
            error
        });
    }
}

const addPaymentMethodToSession = async (ditsRef: DocumentReference,paymethod: string | null,saved:Boolean) => {
    try {
        if(saved){
            console.log("@@@ save addPaymentMethodToSession");
            await FirestoreHelper.addPaymentMethodToSession(ditsRef, paymethod);
        }
        console.log("@@@@ addPaymentMethodToSession");
        console.log(paymethod);
        console.log(saved);
        return buildActionResult(SESSION_CONSTANTS.UPDATE_PAYMENT_METHOD, {
            paymethod
        });
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.UPDATE_PAYMENT_ERROR, {
            error
        });
    }
}

const addInfoDetailsToSession = async (ditsRef: DocumentReference, deliveryDetails: any) => {
    try {
        await FirestoreHelper.addDeliveryDetailsToDits(ditsRef, deliveryDetails);
        return buildActionResult(SESSION_CONSTANTS.ADD_INFO_DETAILS_TO_DITS, {deliveryDetails:{...deliveryDetails ?? null}});
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.ADD_INFO_DETAILS_TO_DITS_ERROR, {
            error
        });
    }
}

const switchSessionToUser = async (restaurantID: string, ditsID: string) => {
    try {
        const response = await switchDitsToUser(restaurantID, ditsID);
        return buildActionResult(SESSION_CONSTANTS.SWITCH_DITS_TO_USER, {});
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.SWITCH_DITS_TO_USER_ERROR, {
            error
        });
    }
}

const confirmOrder = async (restaurantID: string, menuId:string, sessionID: string, paymentMethod: string = ALL_PAYMENT_METHODS.default.value,timeSlot:TimeSlot | undefined | null) => {
    try {
        const response = await confirmSessionOrder(restaurantID,menuId, sessionID, paymentMethod,timeSlot);
        const orderNeedApproval = response.orderNeedApproval ?? false;
        let orderApprovementRequest: DocumentSnapshot|null = null;
        console.log('@@@@confirm order response');
        console.log(response);
        if (orderNeedApproval) {
            const orderApprovementRequestPath = response.orderApprovementRequest ?? null;
            if (checkIfNotEmpty(orderApprovementRequestPath)) {
                orderApprovementRequest = await FirestoreHelper.getSnapshot(orderApprovementRequestPath);
            }
        }
        return buildActionResult(SESSION_CONSTANTS.CONFIRM_ORDER, {
            mustPayFirst: response.mustPayFirst,
            orderNeedApproval: orderNeedApproval,
            orderApprovementRequest: orderApprovementRequest,
            confirmed: true
        });
    } catch (error) {
        console.error(error);
        return buildActionResult(SESSION_CONSTANTS.CONFIRM_ORDER_ERROR, {
            confirmed: false,
            error
        });
    }
}

const listenForOrderApprovementRequest = (dispatch: any) => (orderApprovementRequestRef: DocumentReference) => {
    try {
        return orderApprovementRequestRef?.onSnapshot(snapshot => {
           if (!!snapshot && snapshot.exists) {
               const orderApprovementRequest = snapshot;
               return dispatch(buildActionResult(SESSION_CONSTANTS.UPDATE_ORDER_APPROVEMENT_REQUEST, {
                   orderApprovementRequest
               }));
           }
        });
    } catch (error) {
        console.error(error);
        return dispatch(buildActionResult(SESSION_CONSTANTS.UPDATE_ORDER_APPROVEMENT_REQUEST_ERROR, {
            error
        }));
    }
}


export {
    getLastSession,
    getSession,
    createNewSession,
    deleteSession,
    addItemsToSession,
    deleteItemsFromSession,
    listenForSession,
    listenForSessionMenuItems,
    addInfoDetailsToSession,
    addTimeSlotToSession,
    switchSessionToUser,
    confirmOrder,
    addPaymentMethodToSession,
    listenForOrderApprovementRequest
}
