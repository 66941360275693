import {IAvailability, TimeSlot} from "../models";
import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export const isTimeSlotSelected = (slot:TimeSlot | undefined | null, availability: IAvailability) => {
    if (!!slot) {
        const start: Timestamp  = slot.start;
        const end: Timestamp  = slot.end;
        const selected = start?.toDate().getTime() === availability?.timeStart?.getTime() && end?.toDate().getTime() === availability?.timeEnd?.getTime();
        return selected;
    }
    return false;
}

export const getSlot = (time_slot:TimeSlot): TimeSlot|null => {
    if(!!time_slot){
        return {
            start:time_slot.start,
            end:time_slot.end,
            offset:time_slot.offset
        }
    }
    return null;
}