import {LinkActions, LINKS_CONSTANTS} from "../constants/link.constants";

export function link(state = {}, action: {
    type: LinkActions,
    param: object[]
}) {
    switch (action.type) {
        case LINKS_CONSTANTS.VALIDATE_LINK:
        case LINKS_CONSTANTS.SELECT_LINK:
            return {
                ...state,
                ...action,
                error: null
            };
        case LINKS_CONSTANTS.VALIDATE_LINK_ERROR:
            return {
                ...state,
                ...action
            }
        default:
            return state;
    }
}
