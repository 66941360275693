import {FirestoreHelper} from "../helpers/firestoreHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {RESTAURANT_CONSTANTS} from "../constants/restaurant.constants";

const getRestaurant = async (id: string) => {
    try {
        const restaurant = await FirestoreHelper.restaurant(id);
        const restaurantJSON = {
            id: restaurant.id,
            data: restaurant.data()
        };

        delete restaurantJSON?.data?.owner;
        console.log(restaurant);
        return buildActionResult(RESTAURANT_CONSTANTS.RETRIEVE_RESTAURANT, {
            restaurant: restaurantJSON
        });
    } catch (error) {
        console.log('@@@@@error retrieving restaurant');
        console.log(error);
    }
}

export {
    getRestaurant
}
