import * as React from "react";
import {Check as IconAllow} from "@material-ui/icons";
import {Close as IconDeny} from "@material-ui/icons";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {IconButton} from "@material-ui/core";

import './index.scss';



const AskDeleteItems = ({allowCallback, denyCallback}: {allowCallback: () => any, denyCallback: () => any}) => {
    return (<div className="bottom-fixed-row ask-delete-item-row">
        <div>
            <div className="row">
                <div className="col-6">
                    <p className="ask-delete-item-label">
                        {LocalizerHelper.localized('ask_delete')}
                    </p>
                </div>
                <div className="col-6 text-right">
                    <IconButton onClick={allowCallback} className='allow-button'>
                        <IconAllow htmlColor="#fff"/>
                    </IconButton>
                    <IconButton onClick={denyCallback} className='deny-button'>
                        <IconDeny htmlColor="#fff"/>
                    </IconButton>
                </div>
            </div>
        </div>
    </div>)
};


export default AskDeleteItems;
