import {AUTH_CONSTANTS, AuthActions, SHOW_LOGIN} from "../constants/auth.constants";

export function auth(state = {}, action : {
    type: AuthActions,
    params: object[]
}) {
    switch (action.type) {
        case AUTH_CONSTANTS.RETRIEVE_USER_PROFILE:
        case AUTH_CONSTANTS.SET_LANGUAGE_TO_USER:
            return {
                ...state,
                ...action,
                error: null
            };
        case AUTH_CONSTANTS.RETRIEVE_USER_PROFILE_ERROR:
        case AUTH_CONSTANTS.SET_LANGUAGE_TO_USER_ERROR:
            return {
                ...state,
                ...action
            }

        case SHOW_LOGIN.SHOW_LOGIN:
                return {
                    ...state,
                    ...action
                };
        default:
            return state;
    }
}
