import firebase from "firebase";
import {FirestoreHelper} from "../helpers/firestoreHelper";
import {buildActionResult} from "../helpers/actionResultBuilder";
import {AUTH_CONSTANTS,SHOW_LOGIN} from "../constants/auth.constants";
import DocumentReference = firebase.firestore.DocumentReference;

const getUserProfile = async (user: firebase.User) => {
    if (!!user) {
        try {
            console.log('@@@@@@retrieving user profile');
            console.log(user);
            console.log(user.uid);
            const firestoreUser = await FirestoreHelper.userProfile(user?.uid);
            console.log("@@@@ firestoreUser");
            console.log(firestoreUser);
            return buildActionResult(AUTH_CONSTANTS.RETRIEVE_USER_PROFILE, {
                user,
                firestoreUser
            });
        } catch (error) {
            console.log('@@@@@error retrieving user profile');
            console.log(error);
            return buildActionResult(AUTH_CONSTANTS.RETRIEVE_USER_PROFILE, {
                user,
                firestoreUser: null
            });
        }
    }
    return buildActionResult(AUTH_CONSTANTS.RETRIEVE_USER_PROFILE, {
        user,
        firestoreUser: null
    });
}

const setLanguageToUser = async (user: firebase.User, lang: string = 'en') => {
    if (!!user) {
        await FirestoreHelper.setLanguageToUser(user?.uid, lang);
        return buildActionResult(AUTH_CONSTANTS.RETRIEVE_USER_PROFILE, {});
    }
}

const setUserData = async (user: firebase.User, data: any) => {
    if (!!user) {
        await FirestoreHelper.setUserData(user?.uid, data);
        return buildActionResult(AUTH_CONSTANTS.RETRIEVE_USER_PROFILE, {});
    }
}


const showLogin = (show: boolean, restaurantID?: string|null, ditsID?: string|null) => {
    console.log("@@ showLogin => "+ show);
    const result = buildActionResult(SHOW_LOGIN.SHOW_LOGIN, {
        showLogin: show,
        restaurantID:restaurantID,
        ditsID: ditsID
    });
    console.log(result);
    return result;
}

export {
    getUserProfile,
    setLanguageToUser,
    setUserData,
    showLogin
}
