import * as React from "react";
import {connect} from "react-redux";
import firebase from "firebase";
import {Typography,Button} from "@material-ui/core";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import RestaurantHeader from "../restaurant/RestaurantHeader";
import Screen from '../screen';
import './index.scss';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import User = firebase.User;
import {listenForSession} from "../../actions/session.actions";
import {useEffect} from "react";
import DocumentReference = firebase.firestore.DocumentReference;



interface IThanksPageProps {
    restaurant: any,
    dits: DocumentSnapshot,
    user: User,
    linkAlias:string | null,
    listenForDits: (ditsRef: DocumentReference) => any
}

let unsubscribeFromListenForDits: firebase.Unsubscribe | null = null;

const obfuscateMail = (mail:string | null) => {

    if(mail == null || mail.trim() == '') return '';

    mail = mail.trim();
    let avg, splitted, part1,part2, firtChar, endChar;
    splitted = mail.split("@");
    part1 = splitted[0];
    firtChar = part1.substring(0, 1);
    endChar = part1.substring(part1.length - 1);
    part1 = part1.substring(1, (part1.length - 2));
    part1 = part1.replace(/./g,'*');
    part2 = splitted[1];
    return `${firtChar}${part1}${endChar}@${part2}`;
}

const _ThanksPage = ({restaurant, dits, user, linkAlias, listenForDits}: IThanksPageProps) => {

    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';

    useEffect(() => {
        if (!!dits) {
            unsubscribeFromListenForDits = listenForDits(dits?.ref);
        }
        return () => {
            if (!!unsubscribeFromListenForDits) {
                unsubscribeFromListenForDits();
            }
        }
    }, []);


    useEffect(()=>{
        console.log("@@@@@@@@@@@");
        console.log("@@@@@@@@@@@");
        console.log("CHANGE DITS");
        console.log("@@@@@@@@@@@");
        console.log("@@@@@@@@@@@");
        console.log(dits);
    },[dits])

    const newOrder = () => {
        console.log("@@@@ newOrder");
        const alias = linkAlias ?? lastLinkAlias;
        const url = `/menu/${alias}`
        window.location.href = url
    }

    return (
        <Screen className="screen thanks_screen">
            <RestaurantHeader restaurant={restaurant} className={'block'} showBack={false} showCart={false}/>
            <div className="container content_container content_container_thanks_order">
                <div className="container_code_order">
                    <div className="title">{LocalizerHelper.localized('thanks_header')}</div>
                    <div className="subtitle">{LocalizerHelper.localized('thanks_label_order_delivery_takeaway')}</div>
                    <div className="code">{dits?.data()?.code}</div>
                </div>

                <div className="row container_description_order">
                    <div className="col-md-12 text-left label_text">
                        <p>{LocalizerHelper.localized('thanks_page_success_payment_message')}</p>
                    </div>
                </div>
                <div className="row container_mail_order">
                    <div className="col-md-12 text-left label_text">
                        <p className="email-address-label">{obfuscateMail(user?.email ?? "")}</p>
                    </div>
                </div>
                <div className="row container-buttons-action">
                    <div className="col-md-12">
                        <Button className='accent-button full-width button button' onClick={() => newOrder()}>
                            {LocalizerHelper.localized('new_order')}
                        </Button>
                    </div>
                </div>

            </div>
            

        </Screen>);
};


const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    dits: state.session.session,
    user: state.auth.user,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDits: listenForSession(dispatch)
});

const ThanksPage = connect(mapStateToProps, mapDispatchToProps)(_ThanksPage);

export default ThanksPage;
