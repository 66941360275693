import * as React from "react";
import {connect} from "react-redux";
import firebase from "firebase";
import {Button, TextField, Typography} from "@material-ui/core";
import {addInfoDetailsToSession, listenForSession} from "../../actions/session.actions";
import {PropsWithChildren, useEffect} from "react";
import {DELIVERY_MODES} from "../../constants/data";
import {useHistory} from "react-router";
import {LocalizerHelper} from "../../helpers/localizerHelper";
import {checkIfNotEmpty, checkIfNotExists} from "../../helpers/validatorHelper";
import Screen from '../screen';
import './index.scss';
import RestaurantHeader from "../restaurant/RestaurantHeader";
import {showLogin} from "../../actions/auth.actions";

import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import User = firebase.User;

interface IInfoDetailsProps extends PropsWithChildren<any>{
    restaurant: any,
    dits: DocumentSnapshot,
    menu: DocumentSnapshot,
    session:any,
    user: User,
    linkAlias:string | null,
    listenForDits: (ditsRef: DocumentReference) => any,
    showLogin: (show: boolean, restaurantID: string, ditsID: string) => any,
    addInfoDetailsToDits: (ditsRef: DocumentReference, deliveryDetails: any) => any
}

let unsubscribeListenForDits: firebase.Unsubscribe | null = null;

const _InfoDetails = ({match, restaurant, menu, dits, session, user,linkAlias, showLogin, listenForDits, addInfoDetailsToDits}: IInfoDetailsProps) => {

    const [address, setAddress] = React.useState(session.deliveryDetails?.address ?? '');
    const [doorbellName, setDoorbellName] = React.useState(session.deliveryDetails?.doorbell_name ?? '');
    const [phoneNumber, setPhoneNumber] = React.useState(session.deliveryDetails?.phone_number ?? '');
    const [notes, setNotes] = React.useState(session.deliveryDetails?.notes ?? '');
    const [disableAction, setDisableAction] = React.useState(true);
    const [error, setError] = React.useState({
        address: !checkIfNotEmpty(address),
        doorbellName: !checkIfNotEmpty(doorbellName),
        phoneNumber: !checkIfNotEmpty(phoneNumber),
    });

    const history = useHistory();
    const lastLinkAlias = (window.localStorage) ? (window.localStorage.getItem('chuzeat_last_alias_link') ?? '' ) : '';
    const mode:string | null = (match?.params?.mode ?? null);
    

    useEffect(() => {
        if (!!dits) {
            if (!checkIfNotExists(unsubscribeListenForDits)) {
                unsubscribeListenForDits = listenForDits(dits?.ref);
            }
            const deliveryMode = dits.data()?.delivery_mode;
            if (!!deliveryMode) {
                const _deliveryAddress = dits?.data()?.delivery_details?.address ?? '';
                const _doorbellName = dits?.data()?.delivery_details?.doorbell_name ?? '';
                const _phoneNumber = dits?.data()?.delivery_details?.phone_number ?? '';
                const _notes = dits?.data()?.delivery_details?.notes ?? '';
                _setFieldValue('address', _deliveryAddress);
                _setFieldValue('doorbellName', _doorbellName);
                _setFieldValue('phoneNumber', _phoneNumber);
                _setFieldValue('notes', _notes);
                _validateData({
                    currentAddress: _deliveryAddress,
                    currentDoorbellName: _doorbellName,
                    currentPhoneNumber:_phoneNumber
                });
                return;
            }
        }
    }, [dits]);

    const _validateData = ({
        currentAddress,
        currentDoorbellName,
        currentPhoneNumber,
    }: {
        currentAddress?: string,
        currentDoorbellName?: string,
        currentPhoneNumber?:string
    }) => {
        if(mode == null) return;

        const _error = {...error};
        _error.address = !checkIfNotEmpty(currentAddress ?? address);
        _error.doorbellName = !checkIfNotEmpty(currentDoorbellName ?? doorbellName);
        _error.phoneNumber = !checkIfNotEmpty(currentPhoneNumber ?? phoneNumber);
        if (mode == "delivery") {
            setDisableAction(_error.address || _error.doorbellName);
            setError(_error);
        }

        if (mode == "takeaway") {
            setDisableAction(_error.phoneNumber);
            setError(_error);
        }
    }

    const _setFieldValue = (key: string, value: string) => {
        switch (key) {
            case "address":
                setAddress(value);
                _validateData({
                    currentAddress: value
                });
                break;
            case "doorbellName":
                setDoorbellName(value);
                _validateData({
                    currentDoorbellName: value
                });
                break;
            case "phoneNumber":
                setPhoneNumber(value);
                _validateData({
                    currentPhoneNumber: value
                });
                break;
            case "notes":
                setNotes(value);
                break;
        }
    }

    const _proceedWithOrder = () => {
        if (!!dits) {
            addInfoDetailsToDits(dits?.ref, {
                address: address ?? '',
                doorbell_name : doorbellName ?? '',
                phone_number: phoneNumber ?? '',
                notes: notes ?? ''
            });
            if (user.isAnonymous) {
                showLogin(true,restaurant.id,dits.id);
            } else {
                //const confirmOrderPath = `/confirm-order/${restaurant.id}/${menu.id}/${dits.id}`;
                //history.push(confirmOrderPath);
                const alias = linkAlias ?? lastLinkAlias;
                const path = `/menu/${alias}/confirm-order/${restaurant.id}/${menu.id}/${dits.id}`;
                history.push(path);
            }
        }
    }

    const _buildInfoDelivery = () => {
        return (<div className="container">
        <div className="row">
            <div className="col-md-12 user_questions">
                {LocalizerHelper.localized('delivery_details')}
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    inputProps={{
                        readOnly: true
                    }}
                    name="address"
                    error={error.address}
                    fullWidth={true}
                    helperText={LocalizerHelper.localized('field_required')}
                    value={address}
                    label={LocalizerHelper.localized('address')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>

                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    name="doorbellName"
                    error={error.doorbellName}
                    helperText={LocalizerHelper.localized('field_required')}
                    fullWidth={true}
                    value={doorbellName}
                    label={LocalizerHelper.localized('doorbell_name')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>

                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    name="phoneNumber"
                    value={phoneNumber}
                    fullWidth={true}
                    label={LocalizerHelper.localized('phone_number')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    name="notes"
                    rows={4}
                    value={notes}
                    fullWidth={true}
                    multiline={true}
                    label={LocalizerHelper.localized('notes')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <Button className='accent-button full-width' disabled={disableAction}
                        onClick={() => _proceedWithOrder()}>
                    {LocalizerHelper.localized('confirm')}
                </Button>
            </div>
        </div>
    </div> ); 
    }

    const _buildInfoTakeAway = () => {
        return (<div className="container">
        <div className="row">
            <div className="col-md-12 user_questions">
                {LocalizerHelper.localized('delivery_details')}
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    name="phoneNumber"
                    value={phoneNumber}
                    fullWidth={true}
                    label={LocalizerHelper.localized('phone_number')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <TextField
                    variant="outlined"
                    name="notes"
                    rows={4}
                    value={notes}
                    fullWidth={true}
                    multiline={true}
                    label={LocalizerHelper.localized('notes')}
                    onChange={(event) => _setFieldValue(event.target.name, event.target.value)}>
                </TextField>
            </div>
        </div>
        <div className="row form-row">
            <div className="col-md-12">
                <Button className='accent-button full-width' disabled={disableAction}
                        onClick={() => _proceedWithOrder()}>
                    {LocalizerHelper.localized('confirm')}
                </Button>
            </div>
        </div>
    </div> ); 
    }


    return (<Screen className="screen scroll delivery-details-screen">
         <RestaurantHeader restaurant={restaurant} className='full-width block' />
         <div className="content_container">
            {mode === "delivery" ? _buildInfoDelivery() : ((mode === "takeaway") ? _buildInfoTakeAway() : null) }
        </div>
    </Screen>);

};

const mapStateToProps = (state: any) => ({
    restaurant: state.link.restaurant ?? state.restaurant.restaurant ?? null,
    dits: state.session.session,
    session:state.session,
    menu: state.menu.menu,
    user: state.auth.user,
    linkAlias:state.link.linkAlias ?? null,
});

const mapDispatchToProps = (dispatch: any) => ({
    listenForDits: listenForSession(dispatch),
    showLogin:(show:boolean,restaurantID:string,ditsID: string) => dispatch(showLogin(show,restaurantID,ditsID)),
    addInfoDetailsToDits: async (ditsRef: DocumentReference, deliveryDetails: any) => dispatch(await addInfoDetailsToSession(ditsRef, deliveryDetails))
});

const InfoDetails = connect(mapStateToProps, mapDispatchToProps)(_InfoDetails);

export default InfoDetails;
