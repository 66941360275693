import {DateTime} from "luxon";

class DateTimeHelper {
    static isEqual(date1: Date, date2: Date) {
        return date1.getTime() === date2.getTime();
    }

    static isGreater(date1: Date, date2: Date) {
        return date1.getTime() - date2.getTime() > 0;
    }

    static isLess(date1: Date, date2: Date) {
        return date1.getTime() - date2.getTime() < 0;
    }

    static isGreaterOrEqual(date1: Date, date2: Date) {
        return this.isEqual(date1, date2) || this.isGreater(date1, date2);
    }

    static isLessOrEqual(date1: Date, date2: Date) {
        return this.isEqual(date1, date2) || this.isLess(date1, date2);
    }

    static getDateTimeDiff(date1: Date, date2: Date) {
        return date1.getMilliseconds() - date2.getMilliseconds();
    }

    static formatTime(date: Date) {
        return DateTime.fromJSDate(date).toFormat("HH:mm")
    }

    static formatToRelativeCalendar(date: Date) {
        return DateTime.fromJSDate(date).toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' });
    }
}

export default DateTimeHelper;
