import * as React from 'react';
import { LoaderGif } from '../loader';
import { LocalizerHelper } from '../../helpers/localizerHelper';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { ElectronicPaymentMethodType, IntentPayment } from '../../models/payments';
import { CreatePaymentMethodCardData, PaymentIntentResult } from '@stripe/stripe-js';
import ErrorDialog from '../dialogs/ErrorDialog';
import { useEffect } from 'react';
import { cancelPaymentList, confirmPaymentList, createPaymentTransactionList } from '../../helpers/apiHelper';
import './index.scss';

const StripePaymentScreen = () => {
    const stripe = useStripe();
    const elements = useElements();
    //const [elements, setElements] = React.useState(useElements());
    const [checked, setChecked] = React.useState(true);
    const [isInError, setIsInError] = React.useState(false);
    const [error, setError] = React.useState<any>(null);

    const [currentPaymentMethodId, setCurrentPaymentMethodId] = React.useState<string|null>(null);

    const [intentPay, setIntentPay] = React.useState<IntentPayment|undefined|null>(null);

    const [addCard, setAddCard] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [restaurant, setRestaurant] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [tip, setTip] = React.useState<number>(0.0);
    const [bills, setBills] = React.useState<string[]>([]);

    useEffect(() => {
        console.log("useEffect stripe");
        if(!!!stripe){
            return;
        }
        const query = window.location.search;
        //console.log('query: ', query);
        const searchParams: URLSearchParams = new URLSearchParams(query);
        /*console.log('searchParams: ', searchParams);
        console.log('searchParams.has(intent): ', searchParams.has('intent'));
        console.log('searchParams.has(bIDs): ', searchParams.has('bIDs'));
        console.log('searchParams.get(bIDs): ', searchParams.get('bIDs'));
        console.log('searchParams.has(rID): ', searchParams.has('rID'));
        console.log('searchParams.get(rID): ', searchParams.get('rID'));
        console.log('searchParams.has(jwtToken): ', searchParams.has('jwtToken'));
        console.log('searchParams.get(jwtToken): ', searchParams.get('jwtToken'));
        console.log('searchParams.has(cardID): ', searchParams.has('cardID'));
        console.log('searchParams.get(cardID): ', searchParams.get('cardID'));*/
        if(searchParams.has('jwtToken')){
            const jwt: string = searchParams.get('jwtToken') ?? '';
            setToken(jwt);
        }
        if(searchParams.has('bIDs')){
            const billsString: string = searchParams.get('bIDs') ?? '';
            //console.log('billsString: ', billsString);
            const bills: string[] = JSON.parse(billsString);
            //console.log('bills: ', bills);
            setBills(bills);
        }
        if(searchParams.has('rID')){
            const rID: string = searchParams.get('rID') ?? '';
            setRestaurant(rID);
        }
        if(searchParams.has('tip')){
            const tip:number = parseInt(searchParams.get('tip') ?? '0.0') ?? 0.0;
            setTip(tip);
        }
        if(searchParams.has('intent')){
            const intentString: string = searchParams.get('intent') ?? '';
            //console.log('intentString: ', intentString);
            const intent: IntentPayment = JSON.parse(intentString);
            //console.log('intent: ', intent);
            setIntentPay(intent);
        }
        if(searchParams.has('cardID')){
            const cardID: string = searchParams.get('cardID') ?? '';
            setCurrentPaymentMethodId(cardID);
        }

        if(searchParams.has('intent')){
            setAddCard(false);
        }else{
            setAddCard(true);
        }
        return () => {
        }
    }, [stripe]);

    useEffect(() => {
        //console.log('intentPay', intentPay);
        if (!!intentPay) {
            _confirmPaymentIntentStripe();
        }
    }, [intentPay]);

    const _showErrorMessage = (error: any) => {
        setLoading(false);
        setIsInError(true);
        setError(error);
    }

    const _closeErrorDialog = () => {
        setIsInError(false);
        setError(null);
    }

    const _handleSubmitStripe = async (event: React.FormEvent<HTMLFormElement>) => {
        console.log("_handleSubmitStripe");
        //setLoading(true);
        event.preventDefault();
        console.log(!elements);
        if (!stripe || !elements) {
            return;
        }
        try {
            const cardNumberElement = elements.getElement(CardNumberElement);
            if (!!cardNumberElement) {
                const paymentMethodData: CreatePaymentMethodCardData = {
                    type: 'card',
                    card: cardNumberElement,
                };
                const {error, paymentMethod} = await stripe.createPaymentMethod(paymentMethodData);
                if (error) {
                    console.log('[error]', error);
                    _showErrorMessage(error);
                } else {
                    if(!!paymentMethod){
                        _createPaymentTransaction(paymentMethod.id, ElectronicPaymentMethodType.STRIPE);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            _showErrorMessage(error);
        }
    };

    const _createPaymentTransaction = async (paymentMethodId: string|null, service:string) =>{
        setLoading(true);
        console.log("_createPaymentTransaction");
        setCurrentPaymentMethodId(paymentMethodId);
        try{
            if (!!restaurant && !!bills && !!service) {
                const result: IntentPayment|null = await createPaymentTransactionList(restaurant, bills, tip, paymentMethodId, service, checked, token);
                if(!!result?.error){
                    _showErrorMessage(result.error);
                }else{
                    setIntentPay(result);
                }
            }else{
                _showErrorMessage(LocalizerHelper.localized('error'));
            }
        }catch (error){
            console.log(error);
            _showErrorMessage(error);
        }

    }

    const _confirmPaymentIntentStripe = async () => {
        console.log("_confirmPaymentIntentStripe");
        console.log(intentPay);
        console.log(currentPaymentMethodId);
        console.log(stripe);
        if (!!intentPay && !!currentPaymentMethodId) {
            try {
                if(!!stripe){
                    console.log('intentPay', intentPay);
                    console.log('intentPay.data', intentPay.data);
                    console.log('intentPay.data?.clientSecret', intentPay.data?.clientSecret);
                    const response: PaymentIntentResult= await stripe.confirmCardPayment(intentPay.data?.clientSecret, {
                        payment_method: currentPaymentMethodId
                    });
                    const retrievedPaymentIntent = response.paymentIntent;
                    const stripeError = response.error;
                    console.log('@@@@@@paymentIntentResult');
                    console.log(retrievedPaymentIntent);
                    console.log(stripeError);
                    if (!!retrievedPaymentIntent) {
                        if (retrievedPaymentIntent?.status !== "succeeded") {
                            _showErrorMessage(retrievedPaymentIntent?.last_payment_error);
                            await _cancelPayment(ElectronicPaymentMethodType.STRIPE, response);
                            return;
                        }
                        await _confirmPayment(ElectronicPaymentMethodType.STRIPE, response);
                        window.parent.postMessage('OK', '*');
                        return;
                    } else {
                        if (!!stripeError) {
                            _showErrorMessage(stripeError);
                        }
                        await _cancelPayment(ElectronicPaymentMethodType.STRIPE, response);
                    }
                }else{
                    _showErrorMessage(LocalizerHelper.localized('error'));
                    await _cancelPayment(ElectronicPaymentMethodType.STRIPE, null);
                }
            } catch (error) {
                _showErrorMessage(error);
                await _cancelPayment(ElectronicPaymentMethodType.STRIPE, error);
            }
        }
        else{
            _showErrorMessage(LocalizerHelper.localized('error'));
        }
        if(!addCard){
            window.parent.postMessage('KO', '*');
        }
        setLoading(false);
    }

    const handleChange = () => {
        setChecked(!checked);
    };

    const _confirmPayment = async (type: string,  result: any, restaurantID: string = restaurant, transactionID: string|undefined = intentPay?.transactionId, billID:string[] = bills) => {
        await confirmPaymentList(restaurantID, type, transactionID ?? "", billID, result, token)
    }

    const _cancelPayment = async (type: string,  result: any, restaurantID: string = restaurant, transactionID: string|undefined = intentPay?.transactionId, billID:string[] = bills) => {
        await cancelPaymentList(restaurantID, type, transactionID ?? "", billID, result, token)
    }
    
    return <div style={{padding:'20px'}}>
            <div className="container">
                {(addCard) ?
                    (loading) ? <LoaderGif  logo={null} image="/assets/loading_check_payment.gif" message={LocalizerHelper.localized('loading_msg_check_payment')} /> :
                        <form onSubmit={_handleSubmitStripe}>
                            <div className="row form-row">
                                <div className="col-12 text-left label_form_payment">
                                    <label>{LocalizerHelper.localized('card_number')}</label>
                                </div>
                                <div className="col-12">
                                    <CardNumberElement className="card-number-element"/>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-7 text-left label_form_payment">
                                    <label>{LocalizerHelper.localized('card_expire')}</label>
                                    <CardExpiryElement className="card-expiry-element"/>
                                </div>
                                <div className="col-5 text-left label_form_payment">
                                    <label>{LocalizerHelper.localized('card_cvc')}</label>
                                    <CardCvcElement className="card-cvc-element"/>
                                </div>
                            </div>
                            <div className="row form-row justify-content-center">
                                <div className="col-12 text-left label_form_payment">
                                    <FormControlLabel control={<Checkbox style={{color:"#fe3436"}}  onChange={handleChange} checked={checked} size="medium"/>} label={LocalizerHelper.localized('save_card')} />
                                </div>
                            </div>
                            <div className="row container_buttons_action justify-content-center">
                                <div className="col-md-5">
                                    <Button className="accent-button full-width" type="submit" disabled={!stripe}>
                                        {LocalizerHelper.localized('pay')}
                                    </Button>
                                </div>
                            </div>
                        </form> 
                        : <LoaderGif  logo={null} image="/assets/loading_check_payment.gif" message={LocalizerHelper.localized('loading_msg_check_payment')} />
        }
        </div>
<ErrorDialog open={isInError} error={error} handleClose={_closeErrorDialog}/>
</div>
};

export default StripePaymentScreen;