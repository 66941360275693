import firebase from "firebase";
import * as React from "react";
import { AddOn, _getOptionId } from '../../models/menu';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

import './index.scss';
import { useEffect } from "react";
import AddOnsComponent from "./AddOn";

interface MenuItemSupplementsProps {
    menuItem: DocumentSnapshot|null;
    insertAddOns: any;
}

const MenuItemSupplements: React.FC<MenuItemSupplementsProps> = ({menuItem, insertAddOns}) => {
    const addOnsData:AddOn[] = menuItem?.data()?.add_ons ?? [];
    console.log('addOnsData', addOnsData)
    addOnsData.sort( compare );
    console.log('addOnsData sorted', addOnsData)

    let initList: AddOn[] = [];
    for (const addOn of addOnsData){
        const tmp:AddOn = {...addOn, options:[], numChoice:0}
        initList.push(tmp);
        for(const o of addOn.options){
            console.log(_getOptionId(o));
        }
    }
    const [addOns, setAddOns] = React.useState<AddOn[]>(initList);

    useEffect(() => {
        setAddOns(addOns);
        insertAddOns(addOns);
    }, []);

    function compare(a:AddOn, b:AddOn) {
        if ( a.pos < b.pos ){
          return -1;
        }
        if ( a.pos > b.pos ){
          return 1;
        }
        return 0;
      }

    const _insertAddOn = (addOn: AddOn) => {
        console.log('_insertAddOn', addOn)
        console.log(addOns);
        if(addOns.length == 0){
            setAddOns([addOn]);
            insertAddOns([addOn]);
        }else{
            const index = addOns.map(function(e) { return e.title; }).indexOf(addOn.title);
            if (index > -1) {
                addOns.splice(index, 1);
                console.log(addOns)
                setAddOns([...addOns, addOn]);
                insertAddOns([...addOns, addOn]);
            }else{
                setAddOns([...addOns, addOn]);
                insertAddOns([...addOns, addOn]);
            }
        }
    }
    
    return (<div className="menu-item-supplements-container">
        {addOnsData.map((item: AddOn) => {
            if(item.options.length > 0){
                return <AddOnsComponent key={`addon-${item.title}`} addOn={item} insertAddOn={(addOn:AddOn) => {_insertAddOn(addOn)}} />
            }else{
                return null;
            }
        })}
    </div>);
}

export default MenuItemSupplements;