import * as React from "react";
import {Button, Drawer} from "@material-ui/core";
import "./index.scss";
import {Close as IconClose} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

interface IBottomSheetProps extends React.PropsWithChildren<any> {
    children: JSX.Element,
    open: boolean,
    backdrop?: boolean,
    showClose?: boolean,
    onOpen?: () => void;
    onClose?: () => void;
    radius?: number;
}

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const _defaultRadius = 0;

const BottomSheet = ({
    children,
    open,
    backdrop,
    showClose,
    showExternalClose,
    onOpen,
    onClose,
    radius = 30,
    className = "",
}: IBottomSheetProps) => {
    //const containerRef = React.createRef<HTMLDivElement>();
    const [containerHeight, setContainerHeight] = React.useState(0);
    const [opacity, setOpacity] = React.useState(0);
    const [translate, setTranslate] = React.useState(0);
    const [translationInProgress, setTranslationInProgress] = React.useState(false);
    const [startYOffset, setStartYOffset] = React.useState(0);

    const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            height: '100%',
            maxWidth: 576,
            left: '50%',
            transform: 'translate(-50%, 0)',
            background: 'transparent',
            boxShadow: 'none',
            borderTopLeftRadius: radius ?? _defaultRadius,
            borderTopRightRadius: radius ?? _defaultRadius
        }
    }));

    const classes = useStyles();

    const close = () => {
        if (!!onClose && typeof onClose === "function") {
            onClose();
            return;
        }
    }

    /*
    React.useEffect(() => {
        if (containerRef.current === null || containerRef.current === undefined) return;
        setContainerHeight(containerRef.current.clientHeight);
    }, [containerRef.current]);
    */
    return (<Drawer
        style={{pointerEvents: "none", maxWidth: '640px'}}
        classes={classes}
        open={open}
        onClose={close}
        anchor="bottom">
        <div className={ className + ' bottom-sheet-container'  + (open ? ' open' : '' ) }>
            <div className="bottom-sheet-main-container">
                {showClose ?
                    <div className="header-row text-right">
                        <Button onClick={() => close()} className="close-button">
                            <IconClose htmlColor="#fff"/>
                        </Button>
                    </div>
                    : null
                }
                {children}
            </div>
        </div>
    </Drawer>);
};

export {BottomSheet};
