import firebase from "firebase";
import {formatPrice} from "./formatHelper";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

const evaluateItemPrice = (ditsMenuItem: DocumentSnapshot, qty: number = 1) => {
    const defaultPrice = ditsMenuItem?.data()?.price;
    return (defaultPrice) * qty;
}

const formatMenuItemPrice = (ditsMenuItem: DocumentSnapshot|null, qty: number = 1) => {
    if (!!ditsMenuItem) {
        return formatPrice(evaluateItemPrice(ditsMenuItem, qty));
    }
    return formatPrice(0);
}

export {
    evaluateItemPrice,
    formatMenuItemPrice
}
